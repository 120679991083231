import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _Any: any;
  _FieldSet: any;
};

export type AddGhostPlayerInput = {
  teamId: Scalars["ID"];
  userRoundId: Scalars["ID"];
};

export type AddPivotPlayerInput = {
  scoringGroupId: Scalars["ID"];
};

export type AddPlayersByEmailInput = {
  emails: Array<Scalars["String"]>;
  roundId: Scalars["ID"];
};

export type AddRandomGhostPlayerInput = {
  teamId: Scalars["ID"];
};

export type AddScoringGroupPlayerInput = {
  courseHandicap: Scalars["Int"];
  courseTeeId: Scalars["Int"];
  handicapIndex?: InputMaybe<Scalars["Float"]>;
  isOfficial?: InputMaybe<Scalars["Boolean"]>;
  playingHandicap?: InputMaybe<Scalars["Int"]>;
  scoringGroupId: Scalars["Int"];
  teamId?: InputMaybe<Scalars["Int"]>;
  userId: Scalars["Int"];
};

export type AddSpecificPivotPlayerInput = {
  teamId: Scalars["ID"];
  userRoundId: Scalars["ID"];
};

export type AppNotification = {
  __typename?: "AppNotification";
  active: Scalars["Boolean"];
  dateCreated: Scalars["String"];
  details: Scalars["String"];
  id: Scalars["ID"];
  title: Scalars["String"];
  type: AppNotificationType;
};

export enum AppNotificationType {
  Info = "INFO",
  Warning = "WARNING",
}

export type BestPointsLeaderboardResult = {
  __typename?: "BestPointsLeaderboardResult";
  id: Scalars["ID"];
  points: Scalars["Int"];
  rank: Scalars["Int"];
  user: User;
};

export type BestRound = {
  __typename?: "BestRound";
  roundId: Scalars["ID"];
  score: Scalars["Int"];
};

export type BestStat = {
  __typename?: "BestStat";
  roundId: Scalars["ID"];
  userRoundId: Scalars["ID"];
  value: Scalars["Int"];
};

export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type CaptureScoreInput = {
  courseHandicap: Scalars["Int"];
  score: Scalars["Int"];
  userRoundId: Scalars["Int"];
};

export type CaptureScoringGroupScoresInput = {
  holeId: Scalars["ID"];
  scores: Array<HoleScoreInput>;
};

export type ChangeLog = {
  __typename?: "ChangeLog";
  appVersion: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type Club = {
  __typename?: "Club";
  /** @deprecated No longer supported */
  active: Scalars["Boolean"];
  advert?: Maybe<SponsorAdvert>;
  bannerUrl?: Maybe<Scalars["String"]>;
  completedClubRounds: Array<Round>;
  courses: Array<Course>;
  currentClubRounds: Array<Round>;
  currentClubTournaments: Array<Tournament>;
  dashboard: ClubDashboard;
  dateCreated: Scalars["String"];
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  logoUrl?: Maybe<Scalars["String"]>;
  meta?: Maybe<ClubMeta>;
  name: Scalars["String"];
  settings?: Maybe<ClubSettings>;
  /** @deprecated No longer supported */
  sponsor?: Maybe<ClubSponsorLegacy>;
  upcomingClubRounds: Array<Round>;
};

export type ClubAdvertArgs = {
  slot?: InputMaybe<SponsorSlot>;
};

export type ClubAdminApplication = {
  __typename?: "ClubAdminApplication";
  club?: Maybe<Club>;
  clubBannerUrl?: Maybe<Scalars["String"]>;
  clubLogoUrl?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  firebaseUid?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  submitted?: Maybe<Scalars["Boolean"]>;
};

export type ClubCourse = {
  __typename?: "ClubCourse";
  bannerUrl?: Maybe<Scalars["String"]>;
  courses: Array<Course>;
  id: Scalars["ID"];
  logoUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type ClubDashboard = {
  __typename?: "ClubDashboard";
  leaderboard: Array<UserWithPoints>;
  monthlyRoundsSummary: MonthlyRoundsSummary;
  numberOfCompetitions: DashboardStatistic;
  numberOfMonthlyPlayers: DashboardStatistic;
  numberOfMonthlyRounds: DashboardStatistic;
  numberOfTournaments: DashboardStatistic;
  totalNumberOfPlayers: DashboardStatistic;
  totalNumberOfRounds: DashboardStatistic;
};

export type ClubMeta = {
  __typename?: "ClubMeta";
  city: Scalars["String"];
  country: Scalars["String"];
  id: Scalars["ID"];
  latitude: Scalars["String"];
  longitude: Scalars["String"];
  phoneNumber: Scalars["String"];
  province: Scalars["String"];
  street: Scalars["String"];
  suburb: Scalars["String"];
};

export type ClubRoundConnection = {
  __typename?: "ClubRoundConnection";
  cursor?: Maybe<Scalars["String"]>;
  rounds: Array<Round>;
};

export type ClubRounds = {
  __typename?: "ClubRounds";
  count: Scalars["Int"];
  rounds: Array<Round>;
};

export type ClubRoundsFilter = {
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type ClubSearchFilterInput = {
  isUnmanaged?: InputMaybe<Scalars["Boolean"]>;
};

export type ClubSearchResult = {
  __typename?: "ClubSearchResult";
  bannerUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type ClubSettings = {
  __typename?: "ClubSettings";
  amCutOffTime?: Maybe<Scalars["String"]>;
  club: Club;
  clubId: Scalars["ID"];
  countOutMethod: Scalars["String"];
  id: Scalars["ID"];
  pmCutOffTime?: Maybe<Scalars["String"]>;
};

export type ClubSettingsInput = {
  amCutOffTime?: InputMaybe<Scalars["String"]>;
  countOutMethod: Scalars["String"];
  pmCutOffTime?: InputMaybe<Scalars["String"]>;
};

export type ClubSponsorLegacy = {
  __typename?: "ClubSponsorLegacy";
  id: Scalars["ID"];
  mobileClubPageUrl?: Maybe<Scalars["String"]>;
  webLeaderboardUrl?: Maybe<Scalars["String"]>;
};

export type ClubTournamentConnection = {
  __typename?: "ClubTournamentConnection";
  cursor?: Maybe<Scalars["String"]>;
  tournaments: Array<Tournament>;
};

export type Colour = {
  __typename?: "Colour";
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type Comment = {
  __typename?: "Comment";
  author: User;
  content: Scalars["String"];
  dateCreated: Scalars["String"];
  dateModified?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type CompetitionWinsLeaderboardResult = {
  __typename?: "CompetitionWinsLeaderboardResult";
  competitionsPlayed: Scalars["Int"];
  competitionsWon: Scalars["Int"];
  id: Scalars["ID"];
  rank: Scalars["Int"];
  user: User;
};

export type Course = {
  __typename?: "Course";
  /** @deprecated No longer supported */
  active: Scalars["Boolean"];
  activeScoreCard: CourseScoreCard;
  club: Club;
  courseScoreCards: Array<CourseScoreCard>;
  courseTees: Array<CourseTee>;
  dateCreated: Scalars["String"];
  details: CourseDetails;
  id: Scalars["ID"];
  ipsStandings: Array<UserWithPoints>;
  isActive: Scalars["Boolean"];
  localRules: Array<LocalRule>;
  monthlyStats: MonthlyCourseStats;
  name: Scalars["String"];
  numberOfHoles: Scalars["Int"];
  roundInProgress?: Maybe<Round>;
};

export type CourseCourseTeesArgs = {
  filters?: InputMaybe<CourseTeeFilters>;
};

export type CourseDetails = {
  __typename?: "CourseDetails";
  femalePar: Scalars["Int"];
  malePar: Scalars["Int"];
};

export type CourseEclecticScoreCardItem = {
  __typename?: "CourseEclecticScoreCardItem";
  averageScore: Scalars["Float"];
  bestScore: Scalars["Int"];
  holeNumber: Scalars["Int"];
  par: Scalars["Int"];
  stroke: Scalars["Int"];
};

export type CoursePerformance = {
  __typename?: "CoursePerformance";
  holePerformance: Array<HolePerformance>;
  id: Scalars["ID"];
  rounds: Array<Round>;
};

export type CoursePerformanceInput = {
  courseId: Scalars["ID"];
  courseTeeIds?: InputMaybe<Array<Scalars["ID"]>>;
  fromDate: Scalars["String"];
  toDate?: InputMaybe<Scalars["String"]>;
};

export type CourseScoreCard = {
  __typename?: "CourseScoreCard";
  activeFrom: Scalars["String"];
  activeTo?: Maybe<Scalars["String"]>;
  course: Course;
  holes: Array<Hole>;
  id: Scalars["ID"];
  versionNumber: Scalars["Int"];
};

export type CourseTee = {
  __typename?: "CourseTee";
  colour: Colour;
  course: Course;
  enabled: Scalars["Boolean"];
  gender: Gender;
  hexCode?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isDefault: Scalars["Boolean"];
  name: Scalars["String"];
  rating: Scalars["Float"];
  slope: Scalars["Float"];
};

export type CourseTeeFilters = {
  enabled?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateClubAdminApplicationInput = {
  email: Scalars["String"];
};

export type CreateClubRoundsInput = {
  courseIds: Array<Scalars["ID"]>;
  divisions?: InputMaybe<Array<InputMaybe<DivisionCreateInput>>>;
  fields?: InputMaybe<Array<FieldInput>>;
  gameTypeId?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  numberOfHoles?: InputMaybe<Scalars["Int"]>;
  roundSettings?: InputMaybe<RoundSettingsInput>;
  roundTypeId: Scalars["ID"];
  startTime: Scalars["String"];
};

export type CreateCourseScoreCardInput = {
  activeFrom: Scalars["String"];
  courseId: Scalars["ID"];
  holes: Array<HoleInput>;
};

export type CreateEventInput = {
  description?: InputMaybe<Scalars["String"]>;
  handicapLockDate?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  registrationClose: Scalars["String"];
  registrationOpen: Scalars["String"];
};

export type CreateFourballInput = {
  startingHole: Scalars["Int"];
  userRoundIds: Array<Scalars["ID"]>;
};

export type CreateOrUpdateCourseTeeInput = {
  enabled: Scalars["Boolean"];
  genderId: Scalars["ID"];
  hexCode: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  rating: Scalars["Float"];
  slope: Scalars["Float"];
};

export type CreateOrUpdateCourseTeesInput = {
  courseId: Scalars["ID"];
  courseTees: Array<CreateOrUpdateCourseTeeInput>;
};

export type CreateOrUpdateGroupRoundInput = {
  courseId: Scalars["ID"];
  gameTypeId: Scalars["ID"];
  groupId: Scalars["ID"];
  handicapAllowance?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["ID"]>;
  leaderboardHoleLimit?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfHoles?: InputMaybe<Scalars["Int"]>;
  startTime: Scalars["String"];
};

export type CreateOrUpdateSideGameTeamsInput = {
  scoringGroupId: Scalars["ID"];
  sideGameId: Scalars["ID"];
  teams: Array<SideGameTeamInput>;
};

export type CreateOrUpdateTeeTimeInput = {
  availableSlots: Scalars["Int"];
  locked: Scalars["Boolean"];
  playerIds: Array<Scalars["ID"]>;
  roundId: Scalars["ID"];
  startingHole: Scalars["Int"];
  teeTimeId?: InputMaybe<Scalars["ID"]>;
  time: Scalars["String"];
};

export type CreateOrUpdateTournamentInput = {
  divisions?: InputMaybe<Array<InputMaybe<DivisionUpdateInput>>>;
  gameTypeId?: InputMaybe<Scalars["ID"]>;
  genderSeparatedDivisions?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  roundTypeId?: InputMaybe<Scalars["ID"]>;
  tournamentId?: InputMaybe<Scalars["ID"]>;
};

export type CreateOrUpdateTournamentRoundInput = {
  courseId: Scalars["ID"];
  date: Scalars["String"];
  roundId?: InputMaybe<Scalars["ID"]>;
  tournamentId: Scalars["ID"];
};

export type CreateOrUpdateUserScoreCardInput = {
  holeId: Scalars["ID"];
  points: Scalars["Int"];
  score: Scalars["Int"];
};

export type CreateSideGameInput = {
  gameTypeId: Scalars["ID"];
  generateJoinCode: Scalars["Boolean"];
  isCarryOver?: InputMaybe<Scalars["Boolean"]>;
  isJoinable: Scalars["Boolean"];
  isMatchplay?: InputMaybe<Scalars["Boolean"]>;
  roundId: Scalars["ID"];
};

export type CreateTournamentSettingsInput = {
  determineWinnerBy?: InputMaybe<Scalars["String"]>;
  genderId?: InputMaybe<Scalars["ID"]>;
  genderSeparatedDivisions?: InputMaybe<Scalars["Boolean"]>;
  handicapIndexHigh?: InputMaybe<Scalars["Float"]>;
  handicapIndexLow?: InputMaybe<Scalars["Float"]>;
  tournamentId: Scalars["ID"];
};

export type CreateUserInput = {
  avatarUrl?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  genderId?: InputMaybe<Scalars["ID"]>;
  lastName: Scalars["String"];
};

export type CurrentUserRoundMeta = {
  __typename?: "CurrentUserRoundMeta";
  genderId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  isParticipant: Scalars["Boolean"];
  isRegistered: Scalars["Boolean"];
  registeredHandicapIndex?: Maybe<Scalars["Float"]>;
};

export type CurrentUserTournamentMeta = {
  __typename?: "CurrentUserTournamentMeta";
  id: Scalars["ID"];
  isRegistered: Scalars["Boolean"];
};

export type CustomLeaderboardInput = {
  points: Scalars["Int"];
  position: Scalars["Int"];
};

export type DashboardStatistic = {
  __typename?: "DashboardStatistic";
  currentMonth: Scalars["Int"];
  previousMonth: Scalars["Int"];
};

export type DeleteSideGameTeamsInput = {
  sideGameId: Scalars["ID"];
};

export type Division = {
  __typename?: "Division";
  description?: Maybe<Scalars["String"]>;
  determineWinnerBy?: Maybe<Scalars["String"]>;
  gameType: GameType;
  gameTypeId: Scalars["ID"];
  gender?: Maybe<Gender>;
  genderId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  maxHandicapIndex?: Maybe<Scalars["Float"]>;
  minHandicapIndex?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
};

export type DivisionCreateInput = {
  description?: InputMaybe<Scalars["String"]>;
  determineWinnerBy?: InputMaybe<Scalars["String"]>;
  gameTypeId: Scalars["ID"];
  genderId?: InputMaybe<Scalars["ID"]>;
  maxHandicapIndex?: InputMaybe<Scalars["Float"]>;
  minHandicapIndex?: InputMaybe<Scalars["Float"]>;
  name: Scalars["String"];
};

export type DivisionUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  determineWinnerBy?: InputMaybe<Scalars["String"]>;
  gameTypeId: Scalars["ID"];
  genderId?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
  maxHandicapIndex?: InputMaybe<Scalars["Float"]>;
  minHandicapIndex?: InputMaybe<Scalars["Float"]>;
  name: Scalars["String"];
};

export type EditPlayingHandicapInput = {
  playingHandicap: Scalars["Int"];
  userRoundId: Scalars["ID"];
};

export type EditScoringGroupPlayerInput = {
  courseHandicap: Scalars["Int"];
  courseTeeId: Scalars["ID"];
  handicapIndex?: InputMaybe<Scalars["Float"]>;
  isOfficial?: InputMaybe<Scalars["Boolean"]>;
  playingHandicap?: InputMaybe<Scalars["Int"]>;
  roundId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type Employee = {
  __typename?: "Employee";
  id: Scalars["ID"];
  sponsorOverride: Scalars["Boolean"];
  userId: Scalars["ID"];
  userRequestNotifications: Scalars["Boolean"];
};

export type EmployeeSponsorOverride = {
  __typename?: "EmployeeSponsorOverride";
  active: Scalars["Boolean"];
  employee: Employee;
  id: Scalars["ID"];
  sponsorAdvert: SponsorAdvert;
  sponsorSlot: SponsorSlot;
};

export type Event = {
  __typename?: "Event";
  dateCreated: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  handicapLockDate?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isSeedComplete: Scalars["Boolean"];
  meta: EventMeta;
  name: Scalars["String"];
  registrationClose: Scalars["String"];
  registrationOpen: Scalars["String"];
  registrations: Array<UserRegistration>;
  status: EventStatus;
  tournaments: Array<Tournament>;
};

export type EventCurrentUserMeta = {
  __typename?: "EventCurrentUserMeta";
  id: Scalars["ID"];
  isBlacklisted: Scalars["Boolean"];
  isRegistered: Scalars["Boolean"];
};

export type EventFilter = {
  clubId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<EventStatus>;
};

export type EventMeta = {
  __typename?: "EventMeta";
  currentUser: EventCurrentUserMeta;
  id: Scalars["ID"];
  isRegistrationOpen: Scalars["Boolean"];
};

export enum EventStatus {
  Draft = "DRAFT",
  Published = "PUBLISHED",
}

export type FieldInput = {
  cutOffTime?: InputMaybe<Scalars["String"]>;
  field: Scalars["String"];
};

export type File = {
  __typename?: "File";
  encoding: Scalars["String"];
  filename: Scalars["String"];
  mimetype: Scalars["String"];
};

export type GameFormat = {
  __typename?: "GameFormat";
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type GameType = {
  __typename?: "GameType";
  description: Scalars["String"];
  details?: Maybe<Scalars["String"]>;
  gameFormat: GameFormat;
  id: Scalars["ID"];
  isPremium: Scalars["Boolean"];
  matchplayAllowed: Scalars["Boolean"];
  scoringMethod: ScoringMethod;
  teamSize: Scalars["Int"];
};

export type Gender = {
  __typename?: "Gender";
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type Group = {
  __typename?: "Group";
  /** @deprecated No longer supported */
  active: Scalars["Boolean"];
  bannerUrl: Scalars["String"];
  captains: Array<User>;
  completedRounds: Array<Round>;
  currentSeason: GroupSeason;
  dateCreated: Scalars["String"];
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  isCaptain: Scalars["Boolean"];
  isMember: Scalars["Boolean"];
  latestRound?: Maybe<Round>;
  logoUrl: Scalars["String"];
  members: Array<User>;
  name: Scalars["String"];
  nextRound?: Maybe<Round>;
  pastSeasons: Array<GroupSeason>;
  referenceCode: Scalars["String"];
  rounds: Array<Round>;
  roundsInProgress: Array<Round>;
  settings: GroupSettings;
  slogan?: Maybe<Scalars["String"]>;
  stats: GroupStats;
  upcomingRounds: Array<Round>;
};

export type GroupEdit = {
  bannerUrl?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  logoUrl?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  slogan?: InputMaybe<Scalars["String"]>;
};

export enum GroupImageType {
  Banner = "banner",
  Logo = "logo",
}

export type GroupInput = {
  banner?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<Scalars["String"]>;
  logoUrl?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  slogan?: InputMaybe<Scalars["String"]>;
};

export type GroupLeaderboardEntry = {
  __typename?: "GroupLeaderboardEntry";
  averagePoints?: Maybe<Scalars["Float"]>;
  gamesPlayed?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  position: Scalars["Int"];
  totalPoints?: Maybe<Scalars["Int"]>;
  user: User;
};

export type GroupRoundEdit = {
  courseId: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
  numberOfHoles?: InputMaybe<Scalars["Int"]>;
  roundId: Scalars["ID"];
  startTime: Scalars["String"];
};

export type GroupRoundInput = {
  courseId: Scalars["ID"];
  gameTypeId?: InputMaybe<Scalars["ID"]>;
  groupId: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
  numberOfHoles?: InputMaybe<Scalars["Int"]>;
  startTime: Scalars["String"];
};

export type GroupRoundLeaderboardEntry = {
  __typename?: "GroupRoundLeaderboardEntry";
  isSubmitted: Scalars["Boolean"];
  position: Scalars["Int"];
  totalPoints: Scalars["Int"];
  user: User;
  userRoundId: Scalars["ID"];
};

export type GroupRoundStats = {
  __typename?: "GroupRoundStats";
  albatrosses?: Maybe<Array<Maybe<StrokeLeaderboardEntry>>>;
  birdies?: Maybe<Array<Maybe<StrokeLeaderboardEntry>>>;
  blackOuts?: Maybe<Array<Maybe<StrokeLeaderboardEntry>>>;
  bogeys?: Maybe<Array<Maybe<StrokeLeaderboardEntry>>>;
  doubleBogeys?: Maybe<Array<Maybe<StrokeLeaderboardEntry>>>;
  eagles?: Maybe<Array<Maybe<StrokeLeaderboardEntry>>>;
  holeInOnes?: Maybe<Array<Maybe<StrokeLeaderboardEntry>>>;
  leaderboard?: Maybe<Array<Maybe<GroupRoundLeaderboardEntry>>>;
  mostAlbatrosses?: Maybe<UserStrokeOccurrence>;
  mostBirdies?: Maybe<UserStrokeOccurrence>;
  mostBlackOuts?: Maybe<UserStrokeOccurrence>;
  mostBogeys?: Maybe<UserStrokeOccurrence>;
  mostDoubleBogeys?: Maybe<UserStrokeOccurrence>;
  mostEagles?: Maybe<UserStrokeOccurrence>;
  mostHoleInOnes?: Maybe<UserStrokeOccurrence>;
  mostPars?: Maybe<UserStrokeOccurrence>;
  mostTripleBogeys?: Maybe<UserStrokeOccurrence>;
  pars?: Maybe<Array<Maybe<StrokeLeaderboardEntry>>>;
  roundLoser?: Maybe<User>;
  roundWinner?: Maybe<User>;
  tripleBogeys?: Maybe<Array<Maybe<StrokeLeaderboardEntry>>>;
};

export type GroupSeason = {
  __typename?: "GroupSeason";
  completedRounds: Array<Round>;
  customLeaderboard: Array<GroupSeasonLeaderboard>;
  end?: Maybe<Scalars["String"]>;
  group: Group;
  id: Scalars["ID"];
  isCurrent: Scalars["Boolean"];
  latestRound?: Maybe<Round>;
  leaderboard: Array<GroupLeaderboardEntry>;
  leaderboardType: LeaderboardType;
  orderOfMeritLeaderboard?: Maybe<OrderOfMeritLeaderboard>;
  roundsInProgress: Array<Round>;
  seasonNumber: Scalars["Int"];
  start: Scalars["String"];
  stats?: Maybe<GroupSeasonStats>;
  upcomingRounds: Array<Round>;
};

export type GroupSeasonLeaderboard = {
  __typename?: "GroupSeasonLeaderboard";
  groupSeasonId: Scalars["Int"];
  id: Scalars["ID"];
  points: Scalars["Int"];
  position: Scalars["Int"];
};

export type GroupSeasonStats = {
  __typename?: "GroupSeasonStats";
  id: Scalars["ID"];
  memberStats: RoundStats;
  numMembers: Scalars["Int"];
  numberOfRounds: Scalars["Int"];
};

export type GroupSettings = {
  __typename?: "GroupSettings";
  groupId: Scalars["ID"];
  id: Scalars["ID"];
  showBanner: Scalars["Boolean"];
};

export type GroupSettingsFilter = {
  groupId?: InputMaybe<Scalars["ID"]>;
  groupSettingsId?: InputMaybe<Scalars["ID"]>;
};

export type GroupSettingsInput = {
  id: Scalars["ID"];
  showBanner: Scalars["Boolean"];
};

export type GroupStats = {
  __typename?: "GroupStats";
  id: Scalars["ID"];
  numberOfMembers: Scalars["Int"];
  numberOfSeasons: Scalars["Int"];
};

export type GuestUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  genderId: Scalars["ID"];
  lastName: Scalars["String"];
  saPlayerId?: InputMaybe<Scalars["String"]>;
};

export type HandicapIndex = {
  __typename?: "HandicapIndex";
  isNotApplicable?: Maybe<Scalars["Boolean"]>;
  isOfficial: Scalars["Boolean"];
  userId: Scalars["ID"];
  value?: Maybe<Scalars["Float"]>;
};

export type HandicapsSaUser = {
  __typename?: "HandicapsSAUser";
  firstName: Scalars["String"];
  gender: Scalars["String"];
  handicapIndex: Scalars["String"];
  lastName: Scalars["String"];
  uid: Scalars["ID"];
};

export type HeadToHead = {
  __typename?: "HeadToHead";
  draws: Scalars["Int"];
  gamesPlayed: Scalars["Int"];
  id: Scalars["ID"];
  losses: Scalars["Int"];
  wins: Scalars["Int"];
};

export type Hole = {
  __typename?: "Hole";
  courseScoreCard: CourseScoreCard;
  dateCreated: Scalars["String"];
  femalePar: Scalars["Int"];
  femalePlusStroke?: Maybe<Scalars["Int"]>;
  femaleStroke: Scalars["Int"];
  holeNumber: Scalars["Int"];
  id: Scalars["ID"];
  malePar: Scalars["Int"];
  malePlusStroke?: Maybe<Scalars["Int"]>;
  maleStroke: Scalars["Int"];
  par: Scalars["Int"];
};

export type HoleInput = {
  femalePar: Scalars["Int"];
  femalePlusStroke: Scalars["Int"];
  femaleStroke: Scalars["Int"];
  holeNumber: Scalars["Int"];
  malePar: Scalars["Int"];
  malePlusStroke: Scalars["Int"];
  maleStroke: Scalars["Int"];
};

export type HolePerformance = {
  __typename?: "HolePerformance";
  averageScore: Scalars["Float"];
  birdies: Scalars["Int"];
  bogeys: Scalars["Int"];
  doubleBogeys: Scalars["Int"];
  doublePlus: Scalars["Int"];
  eagles: Scalars["Int"];
  hole: Hole;
  id: Scalars["ID"];
  maxScore: Scalars["Int"];
  pars: Scalars["Int"];
  rank: Scalars["Int"];
  stroke: Scalars["Int"];
};

export type HoleScoreInput = {
  courseHandicap: Scalars["Int"];
  score?: InputMaybe<Scalars["Int"]>;
  userRoundId: Scalars["ID"];
};

export type Image = {
  __typename?: "Image";
  comments: Array<Comment>;
  dateCreated: Scalars["String"];
  id: Scalars["ID"];
  previewUrl: Scalars["String"];
  thumbnailUrl: Scalars["String"];
  uploader: User;
  url: Scalars["String"];
};

export type IndividualLeaderboardEntry = {
  __typename?: "IndividualLeaderboardEntry";
  id: Scalars["ID"];
  isCountOut: Scalars["Boolean"];
  points: Scalars["Int"];
  rank: Scalars["Int"];
  score: Scalars["Int"];
  thru: Scalars["Int"];
  totals: LeaderboardEntryTotals;
  userRound: UserRound;
};

export type LeaderboardEntry = {
  __typename?: "LeaderboardEntry";
  gameType: GameType;
  id: Scalars["ID"];
  position: Scalars["Int"];
  roundTeam: Team;
  /** @deprecated No longer supported */
  roundType: RoundType;
  /** @deprecated No longer supported */
  team: Array<LeaderboardUser>;
  thru: Scalars["Int"];
  /** @deprecated No longer supported */
  totalPoints?: Maybe<Scalars["Int"]>;
  /** @deprecated No longer supported */
  totalScore?: Maybe<Scalars["Int"]>;
  /** @deprecated No longer supported */
  userRoundId: Scalars["ID"];
  /** @deprecated No longer supported */
  users: Array<User>;
  values: Array<LeaderboardValue>;
};

export type LeaderboardEntryTotals = {
  __typename?: "LeaderboardEntryTotals";
  gross: Scalars["Int"];
  nettToHandicap: Scalars["Int"];
  nettToPar: Scalars["Int"];
};

export type LeaderboardType = {
  __typename?: "LeaderboardType";
  description: Scalars["String"];
  id: Scalars["ID"];
  title: Scalars["String"];
};

export type LeaderboardUser = {
  __typename?: "LeaderboardUser";
  id: Scalars["ID"];
  user: User;
  userRound: UserRound;
};

export type LeaderboardValue = {
  __typename?: "LeaderboardValue";
  id: Scalars["ID"];
  label: Scalars["String"];
  value: Scalars["String"];
};

export type LinkRoundToGroupInput = {
  groupId: Scalars["ID"];
  roundId: Scalars["ID"];
};

export type LocalRule = {
  __typename?: "LocalRule";
  description: Scalars["String"];
  id: Scalars["ID"];
  title: Scalars["String"];
};

export type MonthlyCourseStats = {
  __typename?: "MonthlyCourseStats";
  lastMonthsWinner?: Maybe<UserWithPoints>;
  rareScores: Array<UserScoreCard>;
  roundsPlayed: Scalars["Int"];
  strokeOccurrences: StrokeOccurrences;
  uniquePlayers: Scalars["Int"];
};

export type MonthlyRoundsSummary = {
  __typename?: "MonthlyRoundsSummary";
  currentMonth: Array<Round>;
  previousMonth: Array<Round>;
};

export type Mutation = {
  __typename?: "Mutation";
  addComment: Comment;
  addGhostPlayer: Scalars["Boolean"];
  addGroup: Group;
  addGroupMembers: Group;
  addGroupRound: Round;
  addGuestUser: User;
  addPivotPlayer: ScoringGroup;
  addPlayers: Round;
  addPlayersByEmail: Round;
  addRandomGhostPlayer: Scalars["Boolean"];
  addRoundImage: Round;
  addScoringGroupPlayer: ScoringGroup;
  addSpecificPivotPlayer: ScoringGroup;
  addUserRoundsToScoringGroup: ScoringGroup;
  /** @deprecated Use captureScoringGroupScores */
  captureHoleScores: Array<UserScoreCard>;
  captureScores: ScoringGroup;
  captureScoringGroupScores: ScoringGroup;
  checkHandicaps: Event;
  clearUserRoundScoringGroup: UserRound;
  clubAdminSignIn: User;
  createClubRounds: Array<Round>;
  createCourseScoreCardVersion: CourseScoreCard;
  createEvent: Event;
  createEventTournament: Event;
  createFourball: Array<UserRound>;
  createGroupSeasonLeaderboardEntry: GroupSeasonLeaderboard;
  createOrUpdateAttendance: RoundAttendance;
  createOrUpdateClubSettings: ClubSettings;
  createOrUpdateCourseTees: Array<CourseTee>;
  createOrUpdateGroupRound: Round;
  createOrUpdateMultipleUserRoundScores: Array<UserRound>;
  createOrUpdateSideGameTeams: SideGame;
  createOrUpdateTeeTime: TeeTime;
  createOrUpdateTournament: Tournament;
  createOrUpdateTournamentRound: Round;
  createOrUpdateTournamentView: TournamentView;
  createOrUpdateUserRequest: UserRequest;
  createOrUpdateUserRequestComment: UserRequestComment;
  createOrUpdateUserRoundScores: UserRound;
  createPrivateRound: Round;
  createScoringGroup: ScoringGroup;
  createScoringGroupWithTeams: ScoringGroup;
  createSideGame: SideGame;
  createTournamentSettings: TournamentSettings;
  createUser: User;
  createUserRegistration: UserRegistration;
  createUserRound: UserRound;
  createUserScoreCard: UserScoreCard;
  deleteComment: Comment;
  deleteEvent: Event;
  deleteEventTournament: Event;
  deleteGroupSeasonLeaderboardEntry: GroupSeasonLeaderboard;
  deleteImage: Scalars["ID"];
  deleteRound: Round;
  deleteSideGame: SideGame;
  deleteSideGameTeams: SideGame;
  deleteTeeTime: TeeTime;
  deleteTournament: Tournament;
  deleteTournamentRound: Round;
  deleteTournamentSettings: TournamentSettings;
  deleteUser?: Maybe<User>;
  deleteUserRegistration: UserRegistration;
  deleteUserRequest: UserRequest;
  deleteUserRound: UserRound;
  demoteCaptain: Group;
  editPlayingHandicap: UserRound;
  editScoringGroup: ScoringGroup;
  editScoringGroupPlayer: ScoringGroup;
  editScoringGroupWithTeams: ScoringGroup;
  endCurrentSeason: GroupSeason;
  followUser: User;
  joinGroup: Group;
  joinGroupRound: Round;
  joinRound: Round;
  joinTeeTime: TeeTime;
  leaveGroup: Group;
  linkRoundToGroup: Round;
  promoteMemberToCaptain: Group;
  recalculatePoints: Round;
  registerUser: User;
  removeGroupMember: Group;
  removePivotPlayer: ScoringGroup;
  reportImage: RoundImage;
  requestUpcomingRoundResponses: Round;
  scheduleOperation: Scalars["String"];
  seedEvent: Event;
  sendPasswordResetEmail: Scalars["Boolean"];
  signIn: User;
  submitUserRounds: Array<UserRound>;
  toggleUserRequestVote: UserRequest;
  toggleUserRequestWatch: UserRequest;
  unfollowUser: User;
  unlinkRoundFromGroup: Round;
  updateClubAdminApplication: ClubAdminApplication;
  updateClubRound: Round;
  updateComment: Comment;
  updateEventStatus: Event;
  updateGender: User;
  updateGroup: Group;
  updateGroupImage: Group;
  /** @deprecated No longer supported */
  updateGroupLogo: Group;
  updateGroupRound: Round;
  updateGroupSeasonLeaderboardType: GroupSeason;
  updateGroupSettings: GroupSettings;
  updatePlayingHandicaps: Array<UserRound>;
  updatePushNotificationToken: User;
  updateRound: Round;
  updateRoundAttendance: Round;
  updateRoundSettings: RoundSettings;
  updateTournamentSettings: TournamentSettings;
  updateUser: User;
  updateUserAvatar: User;
  updateUserRegistrationBlacklistStatus: UserRegistration;
  updateUserRequestStatus: UserRequest;
  updateUserRound: UserRound;
  updateUserScoreCard: UserScoreCard;
  verifyUserRoundScoreCard: UserRoundVerificationResponse;
};

export type MutationAddCommentArgs = {
  content: Scalars["String"];
  imageId: Scalars["ID"];
};

export type MutationAddGhostPlayerArgs = {
  input: AddGhostPlayerInput;
};

export type MutationAddGroupArgs = {
  group: GroupInput;
};

export type MutationAddGroupMembersArgs = {
  groupId: Scalars["ID"];
  userIds: Array<Scalars["ID"]>;
};

export type MutationAddGroupRoundArgs = {
  groupRound: GroupRoundInput;
};

export type MutationAddGuestUserArgs = {
  input: GuestUserInput;
};

export type MutationAddPivotPlayerArgs = {
  input: AddPivotPlayerInput;
};

export type MutationAddPlayersArgs = {
  roundId: Scalars["ID"];
  userIds: Array<Scalars["ID"]>;
};

export type MutationAddPlayersByEmailArgs = {
  input: AddPlayersByEmailInput;
};

export type MutationAddRandomGhostPlayerArgs = {
  input: AddRandomGhostPlayerInput;
};

export type MutationAddRoundImageArgs = {
  publicId: Scalars["String"];
  roundId?: InputMaybe<Scalars["ID"]>;
  url: Scalars["String"];
};

export type MutationAddScoringGroupPlayerArgs = {
  input: AddScoringGroupPlayerInput;
};

export type MutationAddSpecificPivotPlayerArgs = {
  input: AddSpecificPivotPlayerInput;
};

export type MutationAddUserRoundsToScoringGroupArgs = {
  scoringGroupId: Scalars["ID"];
  scoringGroups: Array<ScoringGroupInput>;
};

export type MutationCaptureHoleScoresArgs = {
  holeId: Scalars["Int"];
  inputs: Array<CaptureScoreInput>;
};

export type MutationCaptureScoresArgs = {
  scores: Array<ScoreInput>;
  scoringGroupId: Scalars["ID"];
};

export type MutationCaptureScoringGroupScoresArgs = {
  input: CaptureScoringGroupScoresInput;
};

export type MutationCheckHandicapsArgs = {
  eventId: Scalars["ID"];
};

export type MutationClearUserRoundScoringGroupArgs = {
  userRoundId: Scalars["Int"];
};

export type MutationCreateClubRoundsArgs = {
  input: CreateClubRoundsInput;
};

export type MutationCreateCourseScoreCardVersionArgs = {
  input: CreateCourseScoreCardInput;
};

export type MutationCreateEventArgs = {
  input: CreateEventInput;
};

export type MutationCreateEventTournamentArgs = {
  eventId: Scalars["ID"];
  tournamentId: Scalars["ID"];
};

export type MutationCreateFourballArgs = {
  input: CreateFourballInput;
};

export type MutationCreateGroupSeasonLeaderboardEntryArgs = {
  groupSeasonId: Scalars["Int"];
  points: Scalars["Int"];
  position: Scalars["Int"];
};

export type MutationCreateOrUpdateAttendanceArgs = {
  isAttending: Scalars["Boolean"];
  roundId: Scalars["ID"];
};

export type MutationCreateOrUpdateClubSettingsArgs = {
  input: ClubSettingsInput;
};

export type MutationCreateOrUpdateCourseTeesArgs = {
  input: CreateOrUpdateCourseTeesInput;
};

export type MutationCreateOrUpdateGroupRoundArgs = {
  input: CreateOrUpdateGroupRoundInput;
};

export type MutationCreateOrUpdateMultipleUserRoundScoresArgs = {
  inputs: Array<UpdateUserRoundScoresInput>;
};

export type MutationCreateOrUpdateSideGameTeamsArgs = {
  input: CreateOrUpdateSideGameTeamsInput;
};

export type MutationCreateOrUpdateTeeTimeArgs = {
  input: CreateOrUpdateTeeTimeInput;
};

export type MutationCreateOrUpdateTournamentArgs = {
  createOrUpdateTournamentInput: CreateOrUpdateTournamentInput;
};

export type MutationCreateOrUpdateTournamentRoundArgs = {
  createOrUpdateTournamentRoundInput: CreateOrUpdateTournamentRoundInput;
};

export type MutationCreateOrUpdateTournamentViewArgs = {
  input: TournamentViewInput;
};

export type MutationCreateOrUpdateUserRequestArgs = {
  input: UserRequestInput;
};

export type MutationCreateOrUpdateUserRequestCommentArgs = {
  input: UserRequestCommentInput;
};

export type MutationCreateOrUpdateUserRoundScoresArgs = {
  scores: Array<CreateOrUpdateUserScoreCardInput>;
  userRoundId: Scalars["ID"];
};

export type MutationCreatePrivateRoundArgs = {
  privateRound: PrivateRoundInput;
};

export type MutationCreateScoringGroupArgs = {
  scoringGroups: Array<ScoringGroupInput>;
  startingHole: Scalars["Int"];
};

export type MutationCreateScoringGroupWithTeamsArgs = {
  gameFormatId: Scalars["ID"];
  startingHole: Scalars["Int"];
  teams: Array<Array<ScoringGroupInput>>;
};

export type MutationCreateSideGameArgs = {
  input: CreateSideGameInput;
};

export type MutationCreateTournamentSettingsArgs = {
  input: CreateTournamentSettingsInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserRegistrationArgs = {
  eventId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationCreateUserRoundArgs = {
  courseTeeId: Scalars["ID"];
  handicap: Scalars["Int"];
  handicapIndex?: InputMaybe<Scalars["Float"]>;
  isOfficial?: InputMaybe<Scalars["Boolean"]>;
  playingHandicap?: InputMaybe<Scalars["Int"]>;
  roundId: Scalars["ID"];
  startingHole?: InputMaybe<Scalars["Int"]>;
};

export type MutationCreateUserScoreCardArgs = {
  userScoreCardInput: ScoreInput;
};

export type MutationDeleteCommentArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteEventArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteEventTournamentArgs = {
  eventId: Scalars["ID"];
  tournamentId: Scalars["ID"];
};

export type MutationDeleteGroupSeasonLeaderboardEntryArgs = {
  groupSeasonId: Scalars["Int"];
  position: Scalars["Int"];
};

export type MutationDeleteImageArgs = {
  imageId: Scalars["ID"];
};

export type MutationDeleteRoundArgs = {
  roundId: Scalars["ID"];
};

export type MutationDeleteSideGameArgs = {
  sideGameId: Scalars["ID"];
};

export type MutationDeleteSideGameTeamsArgs = {
  input: DeleteSideGameTeamsInput;
};

export type MutationDeleteTeeTimeArgs = {
  teeTimeId: Scalars["ID"];
};

export type MutationDeleteTournamentArgs = {
  tournamentId: Scalars["ID"];
};

export type MutationDeleteTournamentRoundArgs = {
  roundId: Scalars["ID"];
};

export type MutationDeleteTournamentSettingsArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteUserRegistrationArgs = {
  userRegistrationId: Scalars["ID"];
};

export type MutationDeleteUserRequestArgs = {
  userRequestId: Scalars["ID"];
};

export type MutationDeleteUserRoundArgs = {
  userRoundId: Scalars["ID"];
};

export type MutationDemoteCaptainArgs = {
  groupId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationEditPlayingHandicapArgs = {
  input: EditPlayingHandicapInput;
};

export type MutationEditScoringGroupArgs = {
  scoringGroupId: Scalars["ID"];
  scoringGroups: Array<ScoringGroupInput>;
};

export type MutationEditScoringGroupPlayerArgs = {
  input: EditScoringGroupPlayerInput;
};

export type MutationEditScoringGroupWithTeamsArgs = {
  gameFormatId: Scalars["ID"];
  scoringGroupId: Scalars["ID"];
  teams: Array<Array<ScoringGroupInput>>;
};

export type MutationEndCurrentSeasonArgs = {
  groupId: Scalars["ID"];
};

export type MutationFollowUserArgs = {
  userId: Scalars["ID"];
};

export type MutationJoinGroupArgs = {
  referenceCode: Scalars["String"];
};

export type MutationJoinGroupRoundArgs = {
  roundId: Scalars["ID"];
};

export type MutationJoinRoundArgs = {
  inviteCode: Scalars["String"];
};

export type MutationJoinTeeTimeArgs = {
  teeTimeId: Scalars["ID"];
};

export type MutationLeaveGroupArgs = {
  groupId: Scalars["ID"];
};

export type MutationLinkRoundToGroupArgs = {
  input: LinkRoundToGroupInput;
};

export type MutationPromoteMemberToCaptainArgs = {
  groupId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationRecalculatePointsArgs = {
  roundId: Scalars["ID"];
};

export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};

export type MutationRemoveGroupMemberArgs = {
  groupId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationRemovePivotPlayerArgs = {
  input: RemovePivotPlayerInput;
};

export type MutationReportImageArgs = {
  imageId: Scalars["ID"];
};

export type MutationRequestUpcomingRoundResponsesArgs = {
  roundId: Scalars["ID"];
};

export type MutationScheduleOperationArgs = {
  name: Scalars["String"];
};

export type MutationSeedEventArgs = {
  eventId: Scalars["ID"];
};

export type MutationSendPasswordResetEmailArgs = {
  email: Scalars["String"];
};

export type MutationSubmitUserRoundsArgs = {
  userRoundIds: Array<Scalars["ID"]>;
};

export type MutationToggleUserRequestVoteArgs = {
  userRequestId: Scalars["ID"];
};

export type MutationToggleUserRequestWatchArgs = {
  userRequestId: Scalars["ID"];
};

export type MutationUnfollowUserArgs = {
  userId: Scalars["ID"];
};

export type MutationUnlinkRoundFromGroupArgs = {
  input: LinkRoundToGroupInput;
};

export type MutationUpdateClubAdminApplicationArgs = {
  input: UpdateClubAdminApplicationInput;
};

export type MutationUpdateClubRoundArgs = {
  input: UpdateClubRoundInput;
};

export type MutationUpdateCommentArgs = {
  content: Scalars["String"];
  id: Scalars["ID"];
};

export type MutationUpdateEventStatusArgs = {
  eventId: Scalars["ID"];
  status: EventStatus;
};

export type MutationUpdateGenderArgs = {
  genderId: Scalars["ID"];
};

export type MutationUpdateGroupArgs = {
  updatedGroup: GroupEdit;
};

export type MutationUpdateGroupImageArgs = {
  groupId: Scalars["ID"];
  type: GroupImageType;
  url: Scalars["String"];
};

export type MutationUpdateGroupLogoArgs = {
  groupId: Scalars["ID"];
  url: Scalars["String"];
};

export type MutationUpdateGroupRoundArgs = {
  groupRound: GroupRoundEdit;
};

export type MutationUpdateGroupSeasonLeaderboardTypeArgs = {
  customLeaderboardInputs?: InputMaybe<Array<CustomLeaderboardInput>>;
  groupSeasonId: Scalars["ID"];
  leaderboardTypeId: Scalars["ID"];
  orderOfMeritInput?: InputMaybe<OrderOfMeritLeaderboardInput>;
};

export type MutationUpdateGroupSettingsArgs = {
  input: GroupSettingsInput;
};

export type MutationUpdatePlayingHandicapsArgs = {
  inputs: Array<UpdatePlayingHandicapInput>;
};

export type MutationUpdatePushNotificationTokenArgs = {
  token: Scalars["String"];
};

export type MutationUpdateRoundArgs = {
  input: UpdateRoundInput;
};

export type MutationUpdateRoundAttendanceArgs = {
  groupRoundId: Scalars["ID"];
  isAttending: Scalars["Boolean"];
};

export type MutationUpdateRoundSettingsArgs = {
  input: UpdateRoundSettingsInput;
};

export type MutationUpdateTournamentSettingsArgs = {
  input: UpdateTournamentSettingsInput;
};

export type MutationUpdateUserArgs = {
  updatedUser: UserEdit;
};

export type MutationUpdateUserAvatarArgs = {
  url: Scalars["String"];
};

export type MutationUpdateUserRegistrationBlacklistStatusArgs = {
  isBlackListed: Scalars["Boolean"];
  userRegistrationId: Scalars["ID"];
};

export type MutationUpdateUserRequestStatusArgs = {
  input: UpdateUserRequestStatusInput;
};

export type MutationUpdateUserRoundArgs = {
  updatedUserRound: UserRoundEdit;
};

export type MutationUpdateUserScoreCardArgs = {
  userScoreCardEdit: UserScoreCardEdit;
};

export type MutationVerifyUserRoundScoreCardArgs = {
  isCorrect: Scalars["Boolean"];
  userRoundId: Scalars["ID"];
};

export type Operation = {
  __typename?: "Operation";
  endDate: Scalars["String"];
  name: Scalars["String"];
};

export type OrderOfMeritLeaderboard = {
  __typename?: "OrderOfMeritLeaderboard";
  groupSeason: GroupSeason;
  id: Scalars["ID"];
  numberOfRounds: Scalars["Int"];
};

export type OrderOfMeritLeaderboardInput = {
  numberOfRounds: Scalars["Int"];
};

export type PrivateRoundInput = {
  courseId: Scalars["ID"];
  gameTypeId?: InputMaybe<Scalars["ID"]>;
  handicapAllowance?: InputMaybe<Scalars["Float"]>;
  leaderboardHoleLimit?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  numberOfHoles?: InputMaybe<Scalars["Int"]>;
  startTime?: InputMaybe<Scalars["String"]>;
};

export type PublicLeaderboard = {
  __typename?: "PublicLeaderboard";
  id: Scalars["ID"];
  leaderboard: Array<TeamLeaderboardEntry>;
  round: Round;
};

export type Query = {
  __typename?: "Query";
  _service: _Service;
  activeAppNotification?: Maybe<AppNotification>;
  advert?: Maybe<SponsorAdvert>;
  allGameTypes: Array<GameType>;
  appSponsors: Array<Sponsor>;
  availableTournaments: Array<Tournament>;
  bestPointsLeaderboard: Array<BestPointsLeaderboardResult>;
  /** @deprecated No longer supported */
  bestUsersIPSUserRound: RoundUserRoundConnection;
  changeLog?: Maybe<ChangeLog>;
  club: Club;
  clubAdminApplicationByEmail: ClubAdminApplication;
  clubRounds: ClubRounds;
  clubSearch: Array<ClubSearchResult>;
  clubSponsors: Array<Sponsor>;
  clubTournaments: Array<Tournament>;
  clubs: Array<Club>;
  clubsCoursesSearch: Array<ClubCourse>;
  comment: Comment;
  competitionWinsLeaderboard: Array<CompetitionWinsLeaderboardResult>;
  completedClubRounds: ClubRoundConnection;
  completedClubTournaments: ClubTournamentConnection;
  completedUserRounds: UserRoundConnection;
  course?: Maybe<Course>;
  coursePerformance: CoursePerformance;
  courseRoundInProgress?: Maybe<Round>;
  currentClub: Club;
  currentUser: User;
  event: Event;
  events: Array<Event>;
  fetchUserTypeByEmail?: Maybe<UserType>;
  findHandicapsSAUser?: Maybe<HandicapsSaUser>;
  gameTypes: Array<GameType>;
  generateGuestUserToken: Scalars["String"];
  getUserByEmail: User;
  getUserBySAPlayerId: UserBySaPlayerIdResult;
  group?: Maybe<Group>;
  groupLeaderboardUserRounds: Array<UserRound>;
  groupSeason: GroupSeason;
  groupSeasonLeaderboard: Array<GroupSeasonLeaderboard>;
  groupSeasonStatsByStrokeType: Array<StrokeLeaderboardEntry>;
  groupSeasons: Array<GroupSeason>;
  groupSettings: GroupSettings;
  groups: Array<Group>;
  headToHeadStats: HeadToHead;
  image: Image;
  individualLeaderboard: Array<IndividualLeaderboardEntry>;
  leaderboardTypes: Array<LeaderboardType>;
  matchingGroups: Array<Group>;
  me?: Maybe<User>;
  myScoringGroup?: Maybe<ScoringGroup>;
  possibleGroupMemberSearch: Array<User>;
  possiblePlayersSearch: Array<User>;
  premiumClubs: Array<Club>;
  publicRoundLeaderboard: PublicLeaderboard;
  registeredPlayersSearch: Array<RoundRegistration>;
  round?: Maybe<Round>;
  roundAttendance: Array<RoundAttendance>;
  roundImages: Array<RoundImage>;
  /** @deprecated No longer supported */
  roundLeaderboard: Array<LeaderboardEntry>;
  roundLookup?: Maybe<Round>;
  roundPerformance: RoundPerformance;
  roundSettings: RoundSettings;
  roundUserRounds: RoundUserRoundConnection;
  scoringGroup?: Maybe<ScoringGroup>;
  sideGame: SideGame;
  sideGameLeaderboard: Array<TeamLeaderboardEntry>;
  sideGames: Array<GameType>;
  sideGamesByRoundId: Array<SideGame>;
  sponsors: Array<Sponsor>;
  suggestedPlayers: Array<User>;
  team: Team;
  teamLeaderboard: Array<TeamLeaderboardEntry>;
  teeTimes: Array<TeeTime>;
  tickerItemsOfPreviousRound: Array<RoundTickerItem>;
  tournament: Tournament;
  tournamentView: TournamentView;
  tournamentViewByUser?: Maybe<TournamentView>;
  tournaments: Array<Tournament>;
  upcomingClubRounds: ClubRoundConnection;
  upcomingClubTournaments: ClubTournamentConnection;
  upcomingGroupRounds: Array<Round>;
  user: User;
  userCourseStats: UserCourseStats;
  userCourses: Array<UserCourse>;
  userRequest?: Maybe<UserRequest>;
  userRequestCategories: Array<UserRequestCategory>;
  userRequestComments: UserRequestCommentsConnection;
  userRequestStatuses: Array<UserRequestStatus>;
  userRequests: UserRequestsConnection;
  userRound?: Maybe<UserRound>;
  userRounds: UserRoundsConnection;
  userRoundsByCourseId: Array<UserRound>;
  userSearch: Array<User>;
  userStats: UserStats;
  users: Array<User>;
};

export type QueryAdvertArgs = {
  clubId?: InputMaybe<Scalars["ID"]>;
  slot: SponsorSlot;
};

export type QueryAppSponsorsArgs = {
  userId?: InputMaybe<Scalars["ID"]>;
};

export type QueryAvailableTournamentsArgs = {
  eventId: Scalars["ID"];
};

export type QueryBestPointsLeaderboardArgs = {
  clubId: Scalars["ID"];
};

export type QueryClubArgs = {
  clubId: Scalars["ID"];
};

export type QueryClubAdminApplicationByEmailArgs = {
  email: Scalars["String"];
};

export type QueryClubRoundsArgs = {
  filter?: InputMaybe<ClubRoundsFilter>;
};

export type QueryClubSearchArgs = {
  clubNameSearch: Scalars["String"];
  filter?: InputMaybe<ClubSearchFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
};

export type QueryClubSponsorsArgs = {
  clubId: Scalars["ID"];
  userId?: InputMaybe<Scalars["ID"]>;
};

export type QueryClubTournamentsArgs = {
  clubId?: InputMaybe<Scalars["ID"]>;
};

export type QueryClubsArgs = {
  clubNameSearch: Scalars["String"];
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
};

export type QueryClubsCoursesSearchArgs = {
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  searchTerm: Scalars["String"];
};

export type QueryCompetitionWinsLeaderboardArgs = {
  clubId: Scalars["ID"];
};

export type QueryCompletedClubRoundsArgs = {
  clubId: Scalars["ID"];
  cursor?: InputMaybe<Scalars["String"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QueryCompletedClubTournamentsArgs = {
  clubId: Scalars["ID"];
  cursor?: InputMaybe<Scalars["String"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QueryCompletedUserRoundsArgs = {
  cursor?: InputMaybe<Scalars["ID"]>;
  first: Scalars["Int"];
  userId: Scalars["ID"];
};

export type QueryCourseArgs = {
  id: Scalars["ID"];
};

export type QueryCoursePerformanceArgs = {
  input: CoursePerformanceInput;
};

export type QueryCourseRoundInProgressArgs = {
  courseId: Scalars["ID"];
};

export type QueryEventArgs = {
  id: Scalars["ID"];
};

export type QueryEventsArgs = {
  filter?: InputMaybe<EventFilter>;
};

export type QueryFetchUserTypeByEmailArgs = {
  email: Scalars["String"];
};

export type QueryFindHandicapsSaUserArgs = {
  saPlayerId: Scalars["String"];
};

export type QueryGenerateGuestUserTokenArgs = {
  email: Scalars["String"];
};

export type QueryGetUserByEmailArgs = {
  email: Scalars["String"];
};

export type QueryGetUserBySaPlayerIdArgs = {
  saPlayerId: Scalars["String"];
};

export type QueryGroupArgs = {
  id: Scalars["ID"];
};

export type QueryGroupLeaderboardUserRoundsArgs = {
  groupSeasonId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type QueryGroupSeasonArgs = {
  groupId?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryGroupSeasonLeaderboardArgs = {
  groupSeasonId: Scalars["Int"];
};

export type QueryGroupSeasonStatsByStrokeTypeArgs = {
  groupSeasonId: Scalars["ID"];
  strokeType: StrokeType;
};

export type QueryGroupSeasonsArgs = {
  groupId: Scalars["ID"];
};

export type QueryGroupSettingsArgs = {
  filter: GroupSettingsFilter;
};

export type QueryHeadToHeadStatsArgs = {
  user1Id: Scalars["ID"];
  user2Id: Scalars["ID"];
};

export type QueryImageArgs = {
  id: Scalars["ID"];
};

export type QueryIndividualLeaderboardArgs = {
  roundId: Scalars["ID"];
};

export type QueryMatchingGroupsArgs = {
  groupNameSearch: Scalars["String"];
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
};

export type QueryMyScoringGroupArgs = {
  roundId: Scalars["ID"];
};

export type QueryPossibleGroupMemberSearchArgs = {
  groupId: Scalars["ID"];
  searchTerm: Scalars["String"];
};

export type QueryPossiblePlayersSearchArgs = {
  roundId: Scalars["ID"];
  searchTerm: Scalars["String"];
};

export type QueryPublicRoundLeaderboardArgs = {
  id: Scalars["ID"];
};

export type QueryRegisteredPlayersSearchArgs = {
  roundId: Scalars["ID"];
  searchTerm: Scalars["String"];
};

export type QueryRoundArgs = {
  id: Scalars["ID"];
};

export type QueryRoundAttendanceArgs = {
  roundId: Scalars["ID"];
};

export type QueryRoundImagesArgs = {
  id: Scalars["ID"];
};

export type QueryRoundLeaderboardArgs = {
  roundId: Scalars["ID"];
};

export type QueryRoundLookupArgs = {
  inviteCode: Scalars["String"];
};

export type QueryRoundPerformanceArgs = {
  roundId: Scalars["ID"];
};

export type QueryRoundSettingsArgs = {
  roundId: Scalars["ID"];
};

export type QueryRoundUserRoundsArgs = {
  roundId: Scalars["ID"];
};

export type QueryScoringGroupArgs = {
  userRoundId: Scalars["ID"];
};

export type QuerySideGameArgs = {
  sideGameId: Scalars["ID"];
};

export type QuerySideGameLeaderboardArgs = {
  sideGameId: Scalars["ID"];
};

export type QuerySideGamesByRoundIdArgs = {
  roundId: Scalars["ID"];
};

export type QuerySponsorsArgs = {
  clubId?: InputMaybe<Scalars["ID"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type QuerySuggestedPlayersArgs = {
  roundId: Scalars["ID"];
};

export type QueryTeamArgs = {
  teamId: Scalars["ID"];
};

export type QueryTeamLeaderboardArgs = {
  roundId: Scalars["ID"];
};

export type QueryTeeTimesArgs = {
  roundId: Scalars["ID"];
};

export type QueryTournamentArgs = {
  tournamentId: Scalars["ID"];
};

export type QueryTournamentViewArgs = {
  id: Scalars["ID"];
};

export type QueryTournamentsArgs = {
  tournamentIds: Array<Scalars["ID"]>;
};

export type QueryUpcomingClubRoundsArgs = {
  clubId: Scalars["ID"];
  cursor?: InputMaybe<Scalars["String"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QueryUpcomingClubTournamentsArgs = {
  clubId: Scalars["ID"];
  cursor?: InputMaybe<Scalars["String"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type QueryUpcomingGroupRoundsArgs = {
  groupId: Scalars["ID"];
};

export type QueryUserArgs = {
  id: Scalars["ID"];
};

export type QueryUserCourseStatsArgs = {
  courseId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type QueryUserCoursesArgs = {
  userId: Scalars["ID"];
};

export type QueryUserRequestArgs = {
  userRequestId: Scalars["ID"];
};

export type QueryUserRequestCommentsArgs = {
  filter?: InputMaybe<UserRequestCommentsFilterInput>;
  orderBy?: InputMaybe<UserRequestCommentOrderByInput>;
  pagination?: InputMaybe<UserRequestCommentsPaginationInput>;
};

export type QueryUserRequestsArgs = {
  filter?: InputMaybe<UserRequestsFilterInput>;
  orderBy?: InputMaybe<UserRequestOrderByInput>;
  pagination?: InputMaybe<UserRequestsPaginationInput>;
};

export type QueryUserRoundArgs = {
  id: Scalars["ID"];
};

export type QueryUserRoundsArgs = {
  filter?: InputMaybe<UserRoundsFilterInput>;
  orderBy?: InputMaybe<UserRoundOrderByInput>;
  pagination?: InputMaybe<UserRoundsPaginationInput>;
};

export type QueryUserRoundsByCourseIdArgs = {
  courseId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type QueryUserSearchArgs = {
  searchTerm: Scalars["String"];
};

export type QueryUserStatsArgs = {
  input: UserStatsInput;
};

export type RegisterGuestUserInput = {
  courseId: Scalars["ID"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  genderId: Scalars["ID"];
  lastName: Scalars["String"];
};

export type RegisterUserInput = {
  avatarUrl?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  genderId: Scalars["ID"];
  homeClubId?: InputMaybe<Scalars["ID"]>;
  lastName: Scalars["String"];
  password?: InputMaybe<Scalars["String"]>;
  saPlayerId?: InputMaybe<Scalars["String"]>;
};

export type RemovePivotPlayerInput = {
  scoringGroupId: Scalars["ID"];
};

export type Round = {
  __typename?: "Round";
  course: Course;
  courseScoreCard: CourseScoreCard;
  creator?: Maybe<User>;
  dateCreated: Scalars["String"];
  divisions?: Maybe<Array<Maybe<Division>>>;
  gameType: GameType;
  group?: Maybe<Group>;
  groupSeasonId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  inviteCode?: Maybe<Scalars["String"]>;
  isComplete: Scalars["Boolean"];
  isInProgress: Scalars["Boolean"];
  /** @deprecated No longer supported */
  isParticipant: Scalars["Boolean"];
  lastNotified: Scalars["String"];
  latestScores: Array<UserScoreCard>;
  /** @deprecated No longer supported */
  leaderboard: Array<LeaderboardEntry>;
  linkedGroupIds: Array<Scalars["ID"]>;
  meta: RoundMeta;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  numberOfHoles: Scalars["Int"];
  possiblePlayers: Array<User>;
  rareScores: Array<UserScoreCard>;
  registrations: Array<RoundRegistration>;
  roundAttendance: Array<RoundAttendance>;
  roundImages: Array<RoundImage>;
  roundStats: RoundStats;
  roundType: RoundType;
  settings?: Maybe<RoundSettings>;
  startTime: Scalars["String"];
  teams: Array<Team>;
  teeTimes: Array<TeeTime>;
  tickerItems: Array<RoundTickerItem>;
  totals: RoundTotals;
  tournament?: Maybe<Tournament>;
  userCanEdit: Scalars["Boolean"];
  userRoundForRound?: Maybe<UserRound>;
  userRounds: Array<UserRound>;
};

export type RoundAttendance = {
  __typename?: "RoundAttendance";
  dateCreated: Scalars["String"];
  dateModified: Scalars["String"];
  id: Scalars["ID"];
  isAttending?: Maybe<Scalars["Boolean"]>;
  round: Round;
  user: User;
};

export type RoundImage = {
  __typename?: "RoundImage";
  id: Scalars["ID"];
  image: Image;
  /** @deprecated No longer supported */
  imageUrl: Scalars["String"];
  /** @deprecated No longer supported */
  roundId: Scalars["ID"];
};

export type RoundMeta = {
  __typename?: "RoundMeta";
  currentUser: CurrentUserRoundMeta;
  id: Scalars["ID"];
  isRegistrationRequired: Scalars["Boolean"];
};

export type RoundOrderByInput = {
  startTime?: InputMaybe<Sort>;
};

export type RoundPerformance = {
  __typename?: "RoundPerformance";
  holePerformance: Array<HolePerformance>;
  id: Scalars["ID"];
  round: Round;
};

export type RoundRegistration = {
  __typename?: "RoundRegistration";
  dateCreated: Scalars["String"];
  handicapIndex?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  round: Round;
  user: User;
};

export type RoundSettings = {
  __typename?: "RoundSettings";
  captureLimitInSeconds?: Maybe<Scalars["Int"]>;
  countOutMethod: Scalars["String"];
  cutOffDate?: Maybe<Scalars["String"]>;
  determineWinnerBy?: Maybe<Scalars["String"]>;
  field?: Maybe<Scalars["String"]>;
  genderSeparatedDivisions: Scalars["Boolean"];
  handicapAllowance: Scalars["Float"];
  handicapEditAllowed: Scalars["Boolean"];
  id: Scalars["ID"];
  inviteCodeRequired: Scalars["Boolean"];
  leaderboardHoleLimit: Scalars["Int"];
  limitTvLeaderboardHoles: Scalars["Boolean"];
  roundId: Scalars["ID"];
};

export type RoundSettingsInput = {
  countOutMethod: Scalars["String"];
  cutOffTime?: InputMaybe<Scalars["String"]>;
  determineWinnerBy?: InputMaybe<Scalars["String"]>;
  field?: InputMaybe<Scalars["String"]>;
  genderSeparatedDivisions?: InputMaybe<Scalars["Boolean"]>;
  handicapAllowance: Scalars["Float"];
  handicapEditAllowed: Scalars["Boolean"];
  id?: InputMaybe<Scalars["ID"]>;
  inviteCodeRequired: Scalars["Boolean"];
  leaderboardHoleLimit: Scalars["Int"];
  limitTvLeaderboardHoles: Scalars["Boolean"];
};

export type RoundStats = {
  __typename?: "RoundStats";
  albatrosses: Array<StrokeLeaderboardEntry>;
  birdies: Array<StrokeLeaderboardEntry>;
  blackouts: Array<StrokeLeaderboardEntry>;
  bogeys: Array<StrokeLeaderboardEntry>;
  doubleBogeys: Array<StrokeLeaderboardEntry>;
  eagles: Array<StrokeLeaderboardEntry>;
  holeInOnes: Array<StrokeLeaderboardEntry>;
  pars: Array<StrokeLeaderboardEntry>;
  tripleBogeys: Array<StrokeLeaderboardEntry>;
};

export type RoundTickerItem = {
  __typename?: "RoundTickerItem";
  description: Scalars["String"];
  id: Scalars["ID"];
  round: Round;
};

export type RoundTickerItemInput = {
  description: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
};

export type RoundTotals = {
  __typename?: "RoundTotals";
  id: Scalars["ID"];
  numberOfPlayers: Scalars["Int"];
};

export type RoundType = {
  __typename?: "RoundType";
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type RoundUserRoundConnection = {
  __typename?: "RoundUserRoundConnection";
  totalCount: Scalars["Int"];
  userRounds: Array<UserRound>;
};

export type ScoreInput = {
  adjustedScore: Scalars["Int"];
  holeId: Scalars["ID"];
  points: Scalars["Int"];
  score: Scalars["Int"];
  userRoundId: Scalars["ID"];
  userScoreCardId?: InputMaybe<Scalars["ID"]>;
};

export type ScoringGroup = {
  __typename?: "ScoringGroup";
  id: Scalars["ID"];
  startingHole: Scalars["Int"];
  teams: Array<Team>;
  userRoundVerificationResponses: Array<UserRoundVerificationResponse>;
  userRounds: Array<UserRound>;
};

export type ScoringGroupInput = {
  courseTeeId: Scalars["Int"];
  handicap: Scalars["Int"];
  userRoundId: Scalars["ID"];
};

export type ScoringMethod = {
  __typename?: "ScoringMethod";
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type SideGame = {
  __typename?: "SideGame";
  createdBy: User;
  gameType: GameType;
  id: Scalars["ID"];
  isCarryOver: Scalars["Boolean"];
  isJoinable: Scalars["Boolean"];
  isMatchplay: Scalars["Boolean"];
  joinCode?: Maybe<Scalars["String"]>;
  mySideGameUserRounds: Array<SideGameUserRound>;
  round: Round;
  userIsPlaying: Scalars["Boolean"];
};

export type SideGameTeamInput = {
  positions: Array<Scalars["Int"]>;
  teamId?: InputMaybe<Scalars["ID"]>;
  userRoundIds: Array<Scalars["ID"]>;
};

export type SideGameUserRound = {
  __typename?: "SideGameUserRound";
  dateCreated: Scalars["String"];
  id: Scalars["ID"];
  position: Scalars["Int"];
  scoringGroupId: Scalars["ID"];
  sideGameId: Scalars["ID"];
  teamId: Scalars["ID"];
  userRound: UserRound;
  userRoundId: Scalars["ID"];
};

export enum Sort {
  Asc = "asc",
  Desc = "desc",
}

export type Sponsor = {
  __typename?: "Sponsor";
  id: Scalars["ID"];
  sponsorAdvert: SponsorAdvert;
  sponsorSlot: SponsorSlot;
};

export type SponsorAdvert = {
  __typename?: "SponsorAdvert";
  description: Scalars["String"];
  id: Scalars["ID"];
  imageUrl: Scalars["String"];
  redirectUrl?: Maybe<Scalars["String"]>;
};

export enum SponsorSlot {
  MobileClubLeaderboard = "MOBILE_CLUB_LEADERBOARD",
  MobileClubList = "MOBILE_CLUB_LIST",
  MobileClubPage = "MOBILE_CLUB_PAGE",
  MobileClubStats = "MOBILE_CLUB_STATS",
  MobileGroupLeaderboard = "MOBILE_GROUP_LEADERBOARD",
  MobileGroupList = "MOBILE_GROUP_LIST",
  MobileGroupStats = "MOBILE_GROUP_STATS",
  MobileProfilePage = "MOBILE_PROFILE_PAGE",
  MobileRoundPage = "MOBILE_ROUND_PAGE",
  MobileScoreCapture = "MOBILE_SCORE_CAPTURE",
  MobileRoundSummaryComplete = "MOBILE_ROUND_SUMMARY_COMPLETE",
  MobileRoundSummaryHalfway = "MOBILE_ROUND_SUMMARY_HALFWAY",
  MobileUserStats = "MOBILE_USER_STATS",
  WebClubLeaderboardFooterSlotFour = "WEB_CLUB_LEADERBOARD_FOOTER_SLOT_FOUR",
  WebClubLeaderboardFooterSlotOne = "WEB_CLUB_LEADERBOARD_FOOTER_SLOT_ONE",
  WebClubLeaderboardFooterSlotThree = "WEB_CLUB_LEADERBOARD_FOOTER_SLOT_THREE",
  WebClubLeaderboardFooterSlotTwo = "WEB_CLUB_LEADERBOARD_FOOTER_SLOT_TWO",
  WebClubLeaderboardSidebar = "WEB_CLUB_LEADERBOARD_SIDEBAR",
}

export type StrokeLeaderboardEntry = {
  __typename?: "StrokeLeaderboardEntry";
  position: Scalars["Int"];
  userStrokeOccurrence: UserStrokeOccurrence;
};

export type StrokeOccurrences = {
  __typename?: "StrokeOccurrences";
  albatrosses: Scalars["Int"];
  birdies: Scalars["Int"];
  blackouts: Scalars["Int"];
  bogeys: Scalars["Int"];
  doubleBogeys: Scalars["Int"];
  eagles: Scalars["Int"];
  holeInOnes: Scalars["Int"];
  pars: Scalars["Int"];
  tripleBogeys: Scalars["Int"];
};

export enum StrokeType {
  Albatross = "ALBATROSS",
  Birdie = "BIRDIE",
  Blackout = "BLACKOUT",
  Bogey = "BOGEY",
  DoubleBogey = "DOUBLE_BOGEY",
  Eagle = "EAGLE",
  HoleInOne = "HOLE_IN_ONE",
  Par = "PAR",
  TripleBogey = "TRIPLE_BOGEY",
}

export type Subscription = {
  __typename?: "Subscription";
  operationFinished: Operation;
  roundAttendanceUpdated: Round;
  roundUpdated: Round;
};

export type SubscriptionRoundAttendanceUpdatedArgs = {
  roundId: Scalars["ID"];
};

export type SubscriptionRoundUpdatedArgs = {
  roundId: Scalars["ID"];
};

export type SuggestedHandicap = {
  __typename?: "SuggestedHandicap";
  courseHandicap: Scalars["Int"];
  courseTeeId: Scalars["ID"];
  id: Scalars["ID"];
};

export type Team = {
  __typename?: "Team";
  id: Scalars["ID"];
  scorecard: TeamScorecard;
  specialUserRounds: Array<Scalars["ID"]>;
  total: Scalars["Int"];
  /** @deprecated No longer supported */
  totals: Array<LeaderboardValue>;
  userRounds: Array<UserRound>;
};

export type TeamLeaderboardEntry = {
  __typename?: "TeamLeaderboardEntry";
  id: Scalars["ID"];
  isCountOut: Scalars["Boolean"];
  rank: Scalars["Int"];
  thru: Scalars["Int"];
  total: Scalars["Int"];
  totals: LeaderboardEntryTotals;
  userRounds: Array<UserRound>;
};

export type TeamScore = {
  __typename?: "TeamScore";
  hole: Hole;
  id: Scalars["ID"];
  result: Scalars["String"];
  userScoreCards: Array<UserScoreCard>;
};

export type TeamScorecard = {
  __typename?: "TeamScorecard";
  id: Scalars["ID"];
  meta: TeamScorecardMeta;
  scores: Array<TeamScore>;
};

export type TeamScorecardMeta = {
  __typename?: "TeamScorecardMeta";
  id: Scalars["ID"];
  inTotal?: Maybe<Scalars["String"]>;
  outTotal?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["String"]>;
};

export type TeeTime = {
  __typename?: "TeeTime";
  availableSlots: Scalars["Int"];
  id: Scalars["ID"];
  locked: Scalars["Boolean"];
  players: Array<User>;
  startingHole: Scalars["Int"];
  time: Scalars["String"];
};

export type Tournament = {
  __typename?: "Tournament";
  createdBy: User;
  endTime?: Maybe<Scalars["String"]>;
  gameType: GameType;
  id: Scalars["ID"];
  meta: TournamentMeta;
  name: Scalars["String"];
  roundType: RoundType;
  rounds: Array<Round>;
  settings?: Maybe<TournamentSettings>;
  startTime?: Maybe<Scalars["String"]>;
  tournamentDivisions: Array<Division>;
};

export type TournamentMeta = {
  __typename?: "TournamentMeta";
  currentUser: CurrentUserTournamentMeta;
  id: Scalars["ID"];
  isRegistrationRequired: Scalars["Boolean"];
};

export type TournamentSettings = {
  __typename?: "TournamentSettings";
  determineWinnerBy?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  genderSeparatedDivisions?: Maybe<Scalars["Boolean"]>;
  handicapIndexHigh?: Maybe<Scalars["Float"]>;
  handicapIndexLow?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
};

export type TournamentView = {
  __typename?: "TournamentView";
  cycleTime: Scalars["Int"];
  id: Scalars["ID"];
  tournaments: Array<Tournament>;
};

export type TournamentViewInput = {
  cycleTime: Scalars["Int"];
  tournamentIds: Array<Scalars["ID"]>;
  tournamentViewId?: InputMaybe<Scalars["ID"]>;
};

export type UpdateClubAdminApplicationInput = {
  clubBannerUrl?: InputMaybe<Scalars["String"]>;
  clubId?: InputMaybe<Scalars["ID"]>;
  clubLogoUrl?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  submitted?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateClubRoundInput = {
  courseId: Scalars["ID"];
  divisions?: InputMaybe<Array<InputMaybe<DivisionUpdateInput>>>;
  gameTypeId: Scalars["ID"];
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  numberOfHoles: Scalars["Int"];
  roundSettings: RoundSettingsInput;
  roundTypeId: Scalars["ID"];
  startTime: Scalars["String"];
};

export type UpdatePlayingHandicapInput = {
  playingHandicap: Scalars["Int"];
  userRoundId: Scalars["ID"];
};

export type UpdateRoundInput = {
  numberOfHoles?: InputMaybe<Scalars["Int"]>;
  roundId: Scalars["ID"];
};

export type UpdateRoundSettingsInput = {
  determineWinnerBy?: InputMaybe<Scalars["String"]>;
  genderSeparatedDivisions?: InputMaybe<Scalars["Boolean"]>;
  leaderboardHoleLimit: Scalars["Int"];
  limitTvLeaderboardHoles?: InputMaybe<Scalars["Boolean"]>;
  roundSettingsId: Scalars["ID"];
};

export type UpdateTournamentSettingsInput = {
  determineWinnerBy?: InputMaybe<Scalars["String"]>;
  genderId?: InputMaybe<Scalars["ID"]>;
  genderSeparatedDivisions?: InputMaybe<Scalars["Boolean"]>;
  handicapIndexHigh?: InputMaybe<Scalars["Float"]>;
  handicapIndexLow?: InputMaybe<Scalars["Float"]>;
  id: Scalars["ID"];
};

export type UpdateUserRequestStatusInput = {
  estimatedReleaseDate?: InputMaybe<Scalars["String"]>;
  rejectionReason?: InputMaybe<Scalars["String"]>;
  statusId: Scalars["ID"];
  userRequestId: Scalars["ID"];
};

export type UpdateUserRoundScoresInput = {
  scores: Array<CreateOrUpdateUserScoreCardInput>;
  userRoundId: Scalars["ID"];
};

export type User = {
  __typename?: "User";
  addedBy?: Maybe<User>;
  avatarUrl: Scalars["String"];
  club?: Maybe<Club>;
  dateCreated: Scalars["String"];
  dateOfBirth?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  followers: Array<User>;
  following: Array<User>;
  gender?: Maybe<Gender>;
  genderId?: Maybe<Scalars["Int"]>;
  groups: Array<Group>;
  handicapIndex: HandicapIndex;
  homeClub?: Maybe<Club>;
  id: Scalars["ID"];
  /** @deprecated No longer supported */
  isFriend?: Maybe<Scalars["Boolean"]>;
  isPremium: Scalars["Boolean"];
  isPuttTeam: Scalars["Boolean"];
  lastName: Scalars["String"];
  latestRound?: Maybe<UserRound>;
  pushNotificationToken?: Maybe<Scalars["String"]>;
  roundsInProgress: Array<UserRound>;
  saPlayerId?: Maybe<Scalars["String"]>;
  stats: UserStats;
  userRounds: Array<UserRound>;
  userTypeId: Scalars["ID"];
};

export type UserBySaPlayerIdResult = {
  __typename?: "UserBySAPlayerIdResult";
  handicapsSAUser?: Maybe<HandicapsSaUser>;
  user?: Maybe<User>;
};

export type UserCourse = {
  __typename?: "UserCourse";
  course: Course;
  roundsPlayed: Scalars["Int"];
};

export type UserCourseStats = {
  __typename?: "UserCourseStats";
  eclecticScoreCard: Array<CourseEclecticScoreCardItem>;
  highestGrossScore: Scalars["Int"];
  highestHandicap: Scalars["Int"];
  highestNetScore: Scalars["Int"];
  lowestGrossScore: Scalars["Int"];
  lowestHandicap: Scalars["Int"];
  lowestNetScore: Scalars["Int"];
  roundsPlayed: Scalars["Int"];
};

export type UserEdit = {
  avatar?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  genderId: Scalars["ID"];
  homeClubId?: InputMaybe<Scalars["String"]>;
  lastName: Scalars["String"];
  saPlayerId?: InputMaybe<Scalars["String"]>;
};

export type UserGroupStats = {
  __typename?: "UserGroupStats";
  averagePoints: Scalars["Float"];
  averageScore: Scalars["Float"];
  bestPoints: Scalars["Int"];
  bestScore: Scalars["Int"];
  gamesPlayed: Scalars["Int"];
  group: Group;
  userStrokeStats: Array<Maybe<UserStrokeOccurrence>>;
};

export type UserInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  avatar?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  firebaseUid: Scalars["String"];
  firstName: Scalars["String"];
  groups?: InputMaybe<Array<InputMaybe<GroupInput>>>;
  lastName: Scalars["String"];
};

export type UserRegistration = {
  __typename?: "UserRegistration";
  dateCreated: Scalars["String"];
  handicapIndex?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  isBlackListed: Scalars["Boolean"];
  user: User;
};

export type UserRequest = {
  __typename?: "UserRequest";
  category: UserRequestCategory;
  createdBy?: Maybe<User>;
  dateCreated: Scalars["String"];
  dateModified?: Maybe<Scalars["String"]>;
  dateStatusUpdated?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  estimatedReleaseDate?: Maybe<Scalars["String"]>;
  featuredComments: Array<UserRequestComment>;
  id: Scalars["ID"];
  rejectionReason?: Maybe<Scalars["String"]>;
  status: UserRequestStatus;
  title: Scalars["String"];
  upvoted: Scalars["Boolean"];
  votes: Scalars["Int"];
  watching: Scalars["Boolean"];
};

export type UserRequestCategory = {
  __typename?: "UserRequestCategory";
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type UserRequestComment = {
  __typename?: "UserRequestComment";
  dateCreated: Scalars["String"];
  dateModified?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  id: Scalars["ID"];
  user: User;
  userRequest: UserRequest;
};

export type UserRequestCommentInput = {
  description: Scalars["String"];
  userRequestCommentId?: InputMaybe<Scalars["ID"]>;
  userRequestId: Scalars["ID"];
};

export type UserRequestCommentOrderByInput = {
  dateCreated?: InputMaybe<Sort>;
};

export type UserRequestCommentsConnection = {
  __typename?: "UserRequestCommentsConnection";
  count: Scalars["Int"];
  userRequestComments: Array<UserRequestComment>;
};

export type UserRequestCommentsFilterInput = {
  userRequestId: Scalars["ID"];
};

export type UserRequestCommentsPaginationInput = {
  offset?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type UserRequestInput = {
  categoryId: Scalars["ID"];
  description: Scalars["String"];
  title: Scalars["String"];
  userRequestId?: InputMaybe<Scalars["ID"]>;
};

export type UserRequestOrderByInput = {
  dateCreated?: InputMaybe<Sort>;
  votes?: InputMaybe<Sort>;
};

export type UserRequestStatus = {
  __typename?: "UserRequestStatus";
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type UserRequestVote = {
  __typename?: "UserRequestVote";
  id: Scalars["ID"];
  user: User;
  userRequest: UserRequest;
};

export type UserRequestWatcher = {
  __typename?: "UserRequestWatcher";
  id: Scalars["ID"];
  user: User;
  userRequest: UserRequest;
};

export type UserRequestsConnection = {
  __typename?: "UserRequestsConnection";
  count: Scalars["Int"];
  userRequests: Array<UserRequest>;
};

export type UserRequestsFilterInput = {
  categoryIds?: InputMaybe<Array<Scalars["ID"]>>;
  statusIds?: InputMaybe<Array<Scalars["ID"]>>;
};

export type UserRequestsPaginationInput = {
  offset?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type UserRound = {
  __typename?: "UserRound";
  course: Course;
  courseScoreCard: CourseScoreCard;
  courseTee?: Maybe<CourseTee>;
  dateCreated: Scalars["String"];
  differential?: Maybe<Scalars["Float"]>;
  gameType: GameType;
  /** @deprecated No longer supported */
  handicap?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  isComplete: Scalars["Boolean"];
  isSubmitted?: Maybe<Scalars["Boolean"]>;
  round: Round;
  roundDate: Scalars["String"];
  roundId: Scalars["ID"];
  roundParticipants: Array<User>;
  roundType: RoundType;
  scoringGroup?: Maybe<ScoringGroup>;
  stats: UserRoundStats;
  suggestedHandicaps: Array<SuggestedHandicap>;
  team?: Maybe<Team>;
  totals: UserRoundTotals;
  user: User;
  userRoundHandicap?: Maybe<UserRoundHandicap>;
  userRoundTeams: Array<UserRoundTeam>;
  userScoreCards: Array<UserScoreCard>;
};

export type UserRoundComment = {
  __typename?: "UserRoundComment";
  dateCreated: Scalars["String"];
  id: Scalars["ID"];
  message: Scalars["String"];
  user: User;
};

export type UserRoundConnection = {
  __typename?: "UserRoundConnection";
  cursor?: Maybe<Scalars["ID"]>;
  userId: Scalars["ID"];
  userRounds: Array<UserRound>;
};

export type UserRoundEdit = {
  courseTeeId: Scalars["ID"];
  handicap: Scalars["Int"];
  id: Scalars["ID"];
  scoreCards?: InputMaybe<Array<InputMaybe<UserScoreCardEdit>>>;
  submittedByUserId: Scalars["ID"];
};

export type UserRoundHandicap = {
  __typename?: "UserRoundHandicap";
  courseHandicap: Scalars["Int"];
  handicapIndex?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  isOfficial: Scalars["Boolean"];
  playingHandicap: Scalars["Int"];
};

export type UserRoundInput = {
  courseTeeId: Scalars["ID"];
  groupRoundId: Scalars["ID"];
  handicap: Scalars["Int"];
  scoreCards?: InputMaybe<Array<InputMaybe<ScoreInput>>>;
  userId: Scalars["ID"];
};

export type UserRoundOrderByInput = {
  dateCreated?: InputMaybe<Sort>;
  round?: InputMaybe<RoundOrderByInput>;
};

export type UserRoundStats = {
  __typename?: "UserRoundStats";
  /** @deprecated No longer supported */
  adjustedScore?: Maybe<Scalars["Int"]>;
  albatrosses: Scalars["Int"];
  birdies: Scalars["Int"];
  blackouts: Scalars["Int"];
  bogeys: Scalars["Int"];
  doubleBogeys: Scalars["Int"];
  eagles: Scalars["Int"];
  holeInOnes: Scalars["Int"];
  id: Scalars["ID"];
  pars: Scalars["Int"];
  points: Scalars["Int"];
  score: Scalars["Int"];
  status: Scalars["Int"];
  tripleBogeys: Scalars["Int"];
};

export type UserRoundTeam = {
  __typename?: "UserRoundTeam";
  id: Scalars["ID"];
  isSpecial: Scalars["Boolean"];
  teamId: Scalars["ID"];
};

export type UserRoundTotals = {
  __typename?: "UserRoundTotals";
  adjustedScore: Scalars["Int"];
  id: Scalars["ID"];
  net: Scalars["Int"];
  nettToHandicap: Scalars["Int"];
  nettToPar: Scalars["Int"];
  points: Scalars["Int"];
  score: Scalars["Int"];
};

export type UserRoundVerificationResponse = {
  __typename?: "UserRoundVerificationResponse";
  id: Scalars["ID"];
  isCorrect: Scalars["Boolean"];
  userRoundId: Scalars["Int"];
};

export type UserRoundsConnection = {
  __typename?: "UserRoundsConnection";
  count: Scalars["Int"];
  userRounds: Array<UserRound>;
};

export type UserRoundsFilterInput = {
  courseId?: InputMaybe<Scalars["ID"]>;
  groupSeasonId?: InputMaybe<Scalars["ID"]>;
  hasDifferential?: InputMaybe<Scalars["Boolean"]>;
  isComplete?: InputMaybe<Scalars["Boolean"]>;
  isSubmitted?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type UserRoundsPaginationInput = {
  offset?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export type UserScoreCard = {
  __typename?: "UserScoreCard";
  /** @deprecated No longer supported */
  adjustedScore?: Maybe<Scalars["Int"]>;
  dateCreated: Scalars["String"];
  dateModified?: Maybe<Scalars["String"]>;
  hole: Hole;
  holeNumber: Scalars["Int"];
  id: Scalars["ID"];
  par: Scalars["Int"];
  points: Scalars["Int"];
  score: Scalars["Int"];
  stroke: Scalars["Int"];
  strokeType?: Maybe<StrokeType>;
  totals: UserScoreCardTotals;
  user: User;
  userRound: UserRound;
  userRoundId: Scalars["ID"];
};

export type UserScoreCardEdit = {
  id: Scalars["ID"];
  points: Scalars["Int"];
  score: Scalars["Int"];
};

export type UserScoreCardTotals = {
  __typename?: "UserScoreCardTotals";
  adjustedScore: Scalars["Int"];
  nettToHandicap: Scalars["Int"];
  nettToPar: Scalars["Int"];
  points: Scalars["Int"];
  score: Scalars["Int"];
  userScoreCardId: Scalars["ID"];
};

export type UserStats = {
  __typename?: "UserStats";
  bestGross: Scalars["Int"];
  bestGrossAdjustedScore?: Maybe<BestStat>;
  bestGrossScore?: Maybe<BestStat>;
  bestNet: Scalars["Int"];
  bestNetAdjustedScore?: Maybe<BestStat>;
  bestNetScore?: Maybe<BestStat>;
  bestPoints: Scalars["Int"];
  /** @deprecated No longer supported */
  bestRound?: Maybe<BestRound>;
  /** @deprecated No longer supported */
  bestScore?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  lastHandicap?: Maybe<Scalars["Int"]>;
  numberOfAlbatrosses: Scalars["Int"];
  numberOfBirdies: Scalars["Int"];
  numberOfBlackOuts: Scalars["Int"];
  numberOfBogeys: Scalars["Int"];
  numberOfDoubleBogeys: Scalars["Int"];
  numberOfEagles: Scalars["Int"];
  numberOfHoleInOnes: Scalars["Int"];
  numberOfPars: Scalars["Int"];
  numberOfRounds: Scalars["Int"];
  numberOfTripleBogeys: Scalars["Int"];
  totalRounds: Scalars["Int"];
  totalStats: Scalars["Int"];
  userId: Scalars["ID"];
  worstGross: Scalars["Int"];
  worstNet: Scalars["Int"];
  worstPoints: Scalars["Int"];
};

export type UserStatsFilter = {
  take?: InputMaybe<Scalars["Int"]>;
};

export type UserStatsInput = {
  filter?: InputMaybe<UserStatsFilter>;
  userId: Scalars["ID"];
};

export type UserStrokeOccurrence = {
  __typename?: "UserStrokeOccurrence";
  numOccurrences: Scalars["Int"];
  strokeType?: Maybe<StrokeType>;
  user: User;
};

export type UserType = {
  __typename?: "UserType";
  description: Scalars["String"];
  id: Scalars["ID"];
};

export type UserWithPoints = {
  __typename?: "UserWithPoints";
  avatarUrl?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
  pointRank?: Maybe<Scalars["Int"]>;
  points: Scalars["Int"];
};

export type _Service = {
  __typename?: "_Service";
  sdl?: Maybe<Scalars["String"]>;
};

export type ClubAdminSignInMutationVariables = Exact<{ [key: string]: never }>;

export type ClubAdminSignInMutation = {
  __typename?: "Mutation";
  clubAdminSignIn: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl: string;
    userTypeId: string;
    club?: { __typename?: "Club"; id: string } | null;
    gender?: { __typename?: "Gender"; id: string } | null;
  };
};

export type GetCurrentClubQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentClubQuery = {
  __typename?: "Query";
  currentClub: {
    __typename?: "Club";
    id: string;
    name: string;
    logoUrl?: string | null;
    courses: Array<{ __typename?: "Course"; id: string; name: string }>;
  };
};

export type DashboardStatisticPartsFragment = {
  __typename?: "DashboardStatistic";
  previousMonth: number;
  currentMonth: number;
};

export type GetClubDashboardQueryVariables = Exact<{ [key: string]: never }>;

export type GetClubDashboardQuery = {
  __typename?: "Query";
  currentClub: {
    __typename?: "Club";
    id: string;
    name: string;
    logoUrl?: string | null;
    courses: Array<{ __typename?: "Course"; id: string; name: string }>;
    dashboard: {
      __typename?: "ClubDashboard";
      numberOfMonthlyPlayers: {
        __typename?: "DashboardStatistic";
        previousMonth: number;
        currentMonth: number;
      };
      totalNumberOfPlayers: {
        __typename?: "DashboardStatistic";
        previousMonth: number;
        currentMonth: number;
      };
      numberOfTournaments: {
        __typename?: "DashboardStatistic";
        previousMonth: number;
        currentMonth: number;
      };
      numberOfCompetitions: {
        __typename?: "DashboardStatistic";
        previousMonth: number;
        currentMonth: number;
      };
      numberOfMonthlyRounds: {
        __typename?: "DashboardStatistic";
        previousMonth: number;
        currentMonth: number;
      };
      totalNumberOfRounds: {
        __typename?: "DashboardStatistic";
        previousMonth: number;
        currentMonth: number;
      };
      leaderboard: Array<{
        __typename?: "UserWithPoints";
        id: string;
        firstName: string;
        lastName: string;
        points: number;
        pointRank?: number | null;
      }>;
      monthlyRoundsSummary: {
        __typename?: "MonthlyRoundsSummary";
        currentMonth: Array<{
          __typename?: "Round";
          id: string;
          startTime: string;
        }>;
        previousMonth: Array<{
          __typename?: "Round";
          id: string;
          startTime: string;
        }>;
      };
    };
  };
};

export type GetClubSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClubSettingsQuery = {
  __typename?: "Query";
  currentClub: {
    __typename?: "Club";
    id: string;
    settings?: {
      __typename?: "ClubSettings";
      id: string;
      amCutOffTime?: string | null;
      pmCutOffTime?: string | null;
      countOutMethod: string;
    } | null;
  };
};

export type GetCurrentClubCoursesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCurrentClubCoursesQuery = {
  __typename?: "Query";
  currentClub: {
    __typename?: "Club";
    id: string;
    courses: Array<{
      __typename?: "Course";
      id: string;
      name: string;
      courseTees: Array<{
        __typename?: "CourseTee";
        id: string;
        name: string;
        gender: { __typename?: "Gender"; id: string; description: string };
      }>;
    }>;
  };
};

export type ClubSearchQueryVariables = Exact<{
  clubNameSearch: Scalars["String"];
  filter?: InputMaybe<ClubSearchFilterInput>;
}>;

export type ClubSearchQuery = {
  __typename?: "Query";
  clubSearch: Array<{
    __typename?: "ClubSearchResult";
    id: string;
    name: string;
  }>;
};

export type GetClubRoundsInProgressQueryVariables = Exact<{
  clubId: Scalars["ID"];
}>;

export type GetClubRoundsInProgressQuery = {
  __typename?: "Query";
  club: {
    __typename?: "Club";
    id: string;
    name: string;
    currentClubRounds: Array<{
      __typename?: "Round";
      id: string;
      startTime: string;
      name?: string | null;
      course: { __typename?: "Course"; id: string; name: string };
      settings?: {
        __typename?: "RoundSettings";
        id: string;
        field?: string | null;
      } | null;
      gameType: { __typename?: "GameType"; id: string; description: string };
    }>;
  };
};

export type ClubAdminApplicationPartsFragment = {
  __typename?: "ClubAdminApplication";
  id: string;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  firebaseUid?: string | null;
  clubLogoUrl?: string | null;
  clubBannerUrl?: string | null;
  submitted?: boolean | null;
  club?: { __typename?: "Club"; id: string; name: string } | null;
};

export type GetClubAdminApplicationByEmailQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type GetClubAdminApplicationByEmailQuery = {
  __typename?: "Query";
  clubAdminApplicationByEmail: {
    __typename?: "ClubAdminApplication";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    firebaseUid?: string | null;
    clubLogoUrl?: string | null;
    clubBannerUrl?: string | null;
    submitted?: boolean | null;
    club?: { __typename?: "Club"; id: string; name: string } | null;
  };
};

export type UpdateClubAdminApplicationMutationVariables = Exact<{
  input: UpdateClubAdminApplicationInput;
}>;

export type UpdateClubAdminApplicationMutation = {
  __typename?: "Mutation";
  updateClubAdminApplication: {
    __typename?: "ClubAdminApplication";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    firebaseUid?: string | null;
    clubLogoUrl?: string | null;
    clubBannerUrl?: string | null;
    submitted?: boolean | null;
    club?: { __typename?: "Club"; id: string; name: string } | null;
  };
};

export type ClubSettingsPartsFragment = {
  __typename?: "ClubSettings";
  id: string;
  amCutOffTime?: string | null;
  pmCutOffTime?: string | null;
  countOutMethod: string;
};

export type CreateOrUpdateClubSettingsMutationVariables = Exact<{
  input: ClubSettingsInput;
}>;

export type CreateOrUpdateClubSettingsMutation = {
  __typename?: "Mutation";
  createOrUpdateClubSettings: {
    __typename?: "ClubSettings";
    id: string;
    club: {
      __typename?: "Club";
      id: string;
      settings?: {
        __typename?: "ClubSettings";
        id: string;
        amCutOffTime?: string | null;
        pmCutOffTime?: string | null;
        countOutMethod: string;
      } | null;
    };
  };
};

export type CoursePartsFragment = {
  __typename?: "Course";
  id: string;
  name: string;
  numberOfHoles: number;
  club: {
    __typename?: "Club";
    id: string;
    name: string;
    logoUrl?: string | null;
  };
};

export type GetClubCoursesQueryVariables = Exact<{ [key: string]: never }>;

export type GetClubCoursesQuery = {
  __typename?: "Query";
  currentClub: {
    __typename?: "Club";
    id: string;
    courses: Array<{
      __typename?: "Course";
      id: string;
      name: string;
      numberOfHoles: number;
      club: {
        __typename?: "Club";
        id: string;
        name: string;
        logoUrl?: string | null;
      };
    }>;
  };
};

export type GetCourseIpsStandingsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetCourseIpsStandingsQuery = {
  __typename?: "Query";
  course?: {
    __typename?: "Course";
    id: string;
    name: string;
    numberOfHoles: number;
    ipsStandings: Array<{
      __typename?: "UserWithPoints";
      id: string;
      firstName: string;
      lastName: string;
      points: number;
      pointRank?: number | null;
    }>;
    monthlyStats: {
      __typename?: "MonthlyCourseStats";
      roundsPlayed: number;
      uniquePlayers: number;
      lastMonthsWinner?: {
        __typename?: "UserWithPoints";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl?: string | null;
        points: number;
      } | null;
      rareScores: Array<{
        __typename?: "UserScoreCard";
        id: string;
        score: number;
        points: number;
        dateCreated: string;
        hole: {
          __typename?: "Hole";
          id: string;
          holeNumber: number;
          malePar: number;
          femalePar: number;
        };
        userRound: {
          __typename?: "UserRound";
          id: string;
          handicap?: number | null;
          isSubmitted?: boolean | null;
          isComplete: boolean;
          courseTee?: { __typename?: "CourseTee"; id: string } | null;
          userScoreCards: Array<{
            __typename?: "UserScoreCard";
            id: string;
            dateCreated: string;
            strokeType?: StrokeType | null;
            points: number;
            score: number;
            hole: {
              __typename?: "Hole";
              id: string;
              holeNumber: number;
              par: number;
              malePar: number;
              femalePar: number;
              maleStroke: number;
              femaleStroke: number;
            };
          }>;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
            saPlayerId?: string | null;
            gender?: { __typename?: "Gender"; id: string } | null;
          };
          stats: {
            __typename?: "UserRoundStats";
            id: string;
            points: number;
            status: number;
            score: number;
          };
        };
      }>;
      strokeOccurrences: {
        __typename?: "StrokeOccurrences";
        albatrosses: number;
        holeInOnes: number;
        eagles: number;
        birdies: number;
        pars: number;
        bogeys: number;
        doubleBogeys: number;
        tripleBogeys: number;
        blackouts: number;
      };
    };
    club: {
      __typename?: "Club";
      id: string;
      name: string;
      logoUrl?: string | null;
    };
  } | null;
};

export type GetCoursePerformanceQueryVariables = Exact<{
  input: CoursePerformanceInput;
}>;

export type GetCoursePerformanceQuery = {
  __typename?: "Query";
  coursePerformance: {
    __typename?: "CoursePerformance";
    id: string;
    holePerformance: Array<{
      __typename?: "HolePerformance";
      id: string;
      stroke: number;
      rank: number;
      averageScore: number;
      maxScore: number;
      eagles: number;
      birdies: number;
      pars: number;
      bogeys: number;
      doubleBogeys: number;
      doublePlus: number;
      hole: {
        __typename?: "Hole";
        id: string;
        holeNumber: number;
        par: number;
      };
    }>;
    rounds: Array<{ __typename?: "Round"; id: string }>;
  };
};

export type CourseScoreCardPartsFragment = {
  __typename?: "CourseScoreCard";
  id: string;
  versionNumber: number;
  activeTo?: string | null;
  activeFrom: string;
  holes: Array<{
    __typename?: "Hole";
    id: string;
    holeNumber: number;
    malePar: number;
    femalePar: number;
    maleStroke: number;
    malePlusStroke?: number | null;
    femaleStroke: number;
    femalePlusStroke?: number | null;
    dateCreated: string;
  }>;
};

export type GetCourseScoreCardsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetCourseScoreCardsQuery = {
  __typename?: "Query";
  course?: {
    __typename?: "Course";
    id: string;
    name: string;
    numberOfHoles: number;
    courseScoreCards: Array<{
      __typename?: "CourseScoreCard";
      id: string;
      versionNumber: number;
      activeTo?: string | null;
      activeFrom: string;
      holes: Array<{
        __typename?: "Hole";
        id: string;
        holeNumber: number;
        malePar: number;
        femalePar: number;
        maleStroke: number;
        malePlusStroke?: number | null;
        femaleStroke: number;
        femalePlusStroke?: number | null;
        dateCreated: string;
      }>;
    }>;
  } | null;
};

export type CreateCourseScoreCardVersionMutationVariables = Exact<{
  input: CreateCourseScoreCardInput;
}>;

export type CreateCourseScoreCardVersionMutation = {
  __typename?: "Mutation";
  createCourseScoreCardVersion: {
    __typename?: "CourseScoreCard";
    id: string;
    versionNumber: number;
    activeTo?: string | null;
    activeFrom: string;
    holes: Array<{
      __typename?: "Hole";
      id: string;
      holeNumber: number;
      malePar: number;
      femalePar: number;
      maleStroke: number;
      malePlusStroke?: number | null;
      femaleStroke: number;
      femalePlusStroke?: number | null;
      dateCreated: string;
    }>;
  };
};

export type CourseTeePartsFragment = {
  __typename?: "CourseTee";
  id: string;
  slope: number;
  rating: number;
  name: string;
  hexCode?: string | null;
  enabled: boolean;
  gender: { __typename?: "Gender"; id: string; description: string };
  colour: { __typename?: "Colour"; id: string; description: string };
};

export type GetCourseTeesQueryVariables = Exact<{
  id: Scalars["ID"];
  filters?: InputMaybe<CourseTeeFilters>;
}>;

export type GetCourseTeesQuery = {
  __typename?: "Query";
  course?: {
    __typename?: "Course";
    id: string;
    name: string;
    courseTees: Array<{
      __typename?: "CourseTee";
      id: string;
      slope: number;
      rating: number;
      name: string;
      hexCode?: string | null;
      enabled: boolean;
      gender: { __typename?: "Gender"; id: string; description: string };
      colour: { __typename?: "Colour"; id: string; description: string };
    }>;
  } | null;
};

export type CreateOrUpdateCourseTeesMutationVariables = Exact<{
  input: CreateOrUpdateCourseTeesInput;
}>;

export type CreateOrUpdateCourseTeesMutation = {
  __typename?: "Mutation";
  createOrUpdateCourseTees: Array<{
    __typename?: "CourseTee";
    id: string;
    slope: number;
    rating: number;
    name: string;
    hexCode?: string | null;
    enabled: boolean;
    gender: { __typename?: "Gender"; id: string; description: string };
    colour: { __typename?: "Colour"; id: string; description: string };
  }>;
};

export type AvailableTournamentsQueryVariables = Exact<{
  eventId: Scalars["ID"];
}>;

export type AvailableTournamentsQuery = {
  __typename?: "Query";
  availableTournaments: Array<{
    __typename?: "Tournament";
    id: string;
    name: string;
    settings?: {
      __typename?: "TournamentSettings";
      id: string;
      handicapIndexLow?: number | null;
      handicapIndexHigh?: number | null;
      gender?: {
        __typename?: "Gender";
        id: string;
        description: string;
      } | null;
    } | null;
  }>;
};

export type EventQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EventQuery = {
  __typename?: "Query";
  event: {
    __typename?: "Event";
    id: string;
    name: string;
    status: EventStatus;
    isSeedComplete: boolean;
    registrationOpen: string;
    registrationClose: string;
    handicapLockDate?: string | null;
    registrations: Array<{
      __typename?: "UserRegistration";
      id: string;
      isBlackListed: boolean;
      handicapIndex?: number | null;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        saPlayerId?: string | null;
        avatarUrl: string;
      };
    }>;
    tournaments: Array<{
      __typename?: "Tournament";
      id: string;
      name: string;
      settings?: {
        __typename?: "TournamentSettings";
        id: string;
        handicapIndexLow?: number | null;
        handicapIndexHigh?: number | null;
        gender?: {
          __typename?: "Gender";
          id: string;
          description: string;
        } | null;
      } | null;
      rounds: Array<{
        __typename?: "Round";
        id: string;
        registrations: Array<{
          __typename?: "RoundRegistration";
          id: string;
          handicapIndex?: number | null;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            saPlayerId?: string | null;
          };
        }>;
      }>;
    }>;
  };
};

export type EventsQueryVariables = Exact<{
  filter: EventFilter;
}>;

export type EventsQuery = {
  __typename?: "Query";
  events: Array<{
    __typename?: "Event";
    id: string;
    isSeedComplete: boolean;
    name: string;
    status: EventStatus;
    registrationOpen: string;
    registrationClose: string;
    registrations: Array<{
      __typename?: "UserRegistration";
      id: string;
      isBlackListed: boolean;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
    tournaments: Array<{
      __typename?: "Tournament";
      id: string;
      rounds: Array<{ __typename?: "Round"; id: string }>;
    }>;
  }>;
};

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;

export type CreateEventMutation = {
  __typename?: "Mutation";
  createEvent: {
    __typename?: "Event";
    id: string;
    name: string;
    registrationOpen: string;
    registrationClose: string;
    isSeedComplete: boolean;
  };
};

export type CreateEventTournamentMutationVariables = Exact<{
  eventId: Scalars["ID"];
  tournamentId: Scalars["ID"];
}>;

export type CreateEventTournamentMutation = {
  __typename?: "Mutation";
  createEventTournament: {
    __typename?: "Event";
    id: string;
    tournaments: Array<{
      __typename?: "Tournament";
      id: string;
      name: string;
      settings?: {
        __typename?: "TournamentSettings";
        id: string;
        handicapIndexLow?: number | null;
        handicapIndexHigh?: number | null;
        gender?: {
          __typename?: "Gender";
          id: string;
          description: string;
        } | null;
      } | null;
      rounds: Array<{ __typename?: "Round"; id: string }>;
    }>;
  };
};

export type DeleteEventTournamentMutationVariables = Exact<{
  eventId: Scalars["ID"];
  tournamentId: Scalars["ID"];
}>;

export type DeleteEventTournamentMutation = {
  __typename?: "Mutation";
  deleteEventTournament: {
    __typename?: "Event";
    id: string;
    tournaments: Array<{
      __typename?: "Tournament";
      id: string;
      name: string;
      settings?: {
        __typename?: "TournamentSettings";
        id: string;
        handicapIndexLow?: number | null;
        handicapIndexHigh?: number | null;
        gender?: {
          __typename?: "Gender";
          id: string;
          description: string;
        } | null;
      } | null;
    }>;
  };
};

export type DeleteEventMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteEventMutation = {
  __typename?: "Mutation";
  deleteEvent: { __typename?: "Event"; id: string };
};

export type SeedEventMutationVariables = Exact<{
  eventId: Scalars["ID"];
}>;

export type SeedEventMutation = {
  __typename?: "Mutation";
  seedEvent: {
    __typename?: "Event";
    id: string;
    isSeedComplete: boolean;
    registrations: Array<{
      __typename?: "UserRegistration";
      id: string;
      handicapIndex?: number | null;
    }>;
  };
};

export type UpdateEventStatusMutationVariables = Exact<{
  eventId: Scalars["ID"];
  status: EventStatus;
}>;

export type UpdateEventStatusMutation = {
  __typename?: "Mutation";
  updateEventStatus: { __typename?: "Event"; id: string; status: EventStatus };
};

export type CheckHandicapsMutationVariables = Exact<{
  eventId: Scalars["ID"];
}>;

export type CheckHandicapsMutation = {
  __typename?: "Mutation";
  checkHandicaps: {
    __typename?: "Event";
    id: string;
    registrations: Array<{
      __typename?: "UserRegistration";
      id: string;
      handicapIndex?: number | null;
    }>;
  };
};

export type DivisionPartsFragment = {
  __typename?: "Division";
  id: string;
  name?: string | null;
  description?: string | null;
  minHandicapIndex?: number | null;
  maxHandicapIndex?: number | null;
  determineWinnerBy?: string | null;
  gender?: { __typename?: "Gender"; id: string } | null;
  gameType: {
    __typename?: "GameType";
    id: string;
    description: string;
    teamSize: number;
    scoringMethod: { __typename?: "ScoringMethod"; id: string };
    gameFormat: { __typename?: "GameFormat"; id: string; description: string };
  };
};

export type GetRoundByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetRoundByIdQuery = {
  __typename?: "Query";
  round?: {
    __typename?: "Round";
    id: string;
    startTime: string;
    inviteCode?: string | null;
    isComplete: boolean;
    name?: string | null;
    numberOfHoles: number;
    roundStats: {
      __typename?: "RoundStats";
      albatrosses: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      pars: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      birdies: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      holeInOnes: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      eagles: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      bogeys: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      doubleBogeys: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      tripleBogeys: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      blackouts: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
    };
    course: {
      __typename?: "Course";
      id: string;
      name: string;
      club: {
        __typename?: "Club";
        id: string;
        name: string;
        logoUrl?: string | null;
        bannerUrl?: string | null;
      };
    };
    gameType: {
      __typename?: "GameType";
      id: string;
      description: string;
      teamSize: number;
      gameFormat: { __typename?: "GameFormat"; id: string };
      scoringMethod: { __typename?: "ScoringMethod"; id: string };
    };
    totals: { __typename?: "RoundTotals"; numberOfPlayers: number };
    userRounds: Array<{
      __typename?: "UserRound";
      id: string;
      isSubmitted?: boolean | null;
      isComplete: boolean;
      userRoundHandicap?: {
        __typename?: "UserRoundHandicap";
        id: string;
        courseHandicap: number;
        playingHandicap: number;
        handicapIndex?: number | null;
        isOfficial: boolean;
      } | null;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        saPlayerId?: string | null;
        handicapIndex: {
          __typename?: "HandicapIndex";
          userId: string;
          isOfficial: boolean;
          value?: number | null;
        };
      };
      stats: { __typename?: "UserRoundStats"; score: number; points: number };
      courseTee?: { __typename?: "CourseTee"; id: string; name: string } | null;
      userScoreCards: Array<{ __typename?: "UserScoreCard"; id: string }>;
    }>;
    settings?: {
      __typename?: "RoundSettings";
      id: string;
      determineWinnerBy?: string | null;
      genderSeparatedDivisions: boolean;
    } | null;
    roundType: { __typename?: "RoundType"; id: string };
    divisions?: Array<{
      __typename?: "Division";
      id: string;
      name?: string | null;
      description?: string | null;
      minHandicapIndex?: number | null;
      maxHandicapIndex?: number | null;
      determineWinnerBy?: string | null;
      gender?: { __typename?: "Gender"; id: string } | null;
      gameType: {
        __typename?: "GameType";
        id: string;
        description: string;
        teamSize: number;
        scoringMethod: { __typename?: "ScoringMethod"; id: string };
        gameFormat: {
          __typename?: "GameFormat";
          id: string;
          description: string;
        };
      };
    } | null> | null;
  } | null;
  individualLeaderboard: Array<{
    __typename?: "IndividualLeaderboardEntry";
    id: string;
    isCountOut: boolean;
    points: number;
    rank: number;
    score: number;
    thru: number;
    userRound: {
      __typename?: "UserRound";
      id: string;
      handicap?: number | null;
      userRoundTeams: Array<{
        __typename?: "UserRoundTeam";
        id: string;
        teamId: string;
      }>;
      userRoundHandicap?: {
        __typename?: "UserRoundHandicap";
        id: string;
        handicapIndex?: number | null;
        playingHandicap: number;
        courseHandicap: number;
        isOfficial: boolean;
      } | null;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        saPlayerId?: string | null;
        gender?: { __typename?: "Gender"; id: string } | null;
      };
      courseTee?: { __typename?: "CourseTee"; id: string; name: string } | null;
      scoringGroup?: { __typename?: "ScoringGroup"; id: string } | null;
    };
    totals: {
      __typename?: "LeaderboardEntryTotals";
      gross: number;
      nettToPar: number;
      nettToHandicap: number;
    };
  }>;
  teamLeaderboard: Array<{
    __typename?: "TeamLeaderboardEntry";
    id: string;
    isCountOut: boolean;
    rank: number;
    total: number;
    thru: number;
    userRounds: Array<{
      __typename?: "UserRound";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      scoringGroup?: {
        __typename?: "ScoringGroup";
        id: string;
        userRounds: Array<{
          __typename?: "UserRound";
          id: string;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
          userRoundHandicap?: {
            __typename?: "UserRoundHandicap";
            id: string;
            playingHandicap: number;
          } | null;
          userRoundTeams: Array<{
            __typename?: "UserRoundTeam";
            id: string;
            teamId: string;
          }>;
        }>;
      } | null;
      userRoundTeams: Array<{
        __typename?: "UserRoundTeam";
        id: string;
        teamId: string;
        isSpecial: boolean;
      }>;
    }>;
    totals: {
      __typename?: "LeaderboardEntryTotals";
      gross: number;
      nettToPar: number;
      nettToHandicap: number;
    };
  }>;
  roundPerformance: {
    __typename?: "RoundPerformance";
    id: string;
    holePerformance: Array<{
      __typename?: "HolePerformance";
      id: string;
      stroke: number;
      rank: number;
      averageScore: number;
      maxScore: number;
      eagles: number;
      birdies: number;
      pars: number;
      bogeys: number;
      doubleBogeys: number;
      doublePlus: number;
      hole: {
        __typename?: "Hole";
        id: string;
        holeNumber: number;
        par: number;
      };
    }>;
  };
};

export type ClubCompsQueryVariables = Exact<{
  filter?: InputMaybe<ClubRoundsFilter>;
}>;

export type ClubCompsQuery = {
  __typename?: "Query";
  clubRounds: {
    __typename?: "ClubRounds";
    count: number;
    rounds: Array<{
      __typename?: "Round";
      id: string;
      name?: string | null;
      startTime: string;
      gameType: { __typename?: "GameType"; id: string; description: string };
      course: { __typename?: "Course"; id: string; name: string };
      settings?: {
        __typename?: "RoundSettings";
        id: string;
        field?: string | null;
      } | null;
    }>;
  };
};

export type GetClubRoundsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClubRoundsQuery = {
  __typename?: "Query";
  clubRounds: {
    __typename?: "ClubRounds";
    count: number;
    rounds: Array<{
      __typename?: "Round";
      id: string;
      startTime: string;
      name?: string | null;
      course: {
        __typename?: "Course";
        id: string;
        name: string;
        club: { __typename?: "Club"; id: string; name: string };
      };
      gameType: { __typename?: "GameType"; id: string; description: string };
      userRounds: Array<{ __typename?: "UserRound"; id: string }>;
      tournament?: { __typename?: "Tournament"; id: string } | null;
      settings?: {
        __typename?: "RoundSettings";
        id: string;
        field?: string | null;
      } | null;
      roundType: { __typename?: "RoundType"; id: string };
    }>;
  };
};

export type ClubRoundMutationResultPartsFragment = {
  __typename?: "Round";
  id: string;
  startTime: string;
  name?: string | null;
  numberOfHoles: number;
  course: {
    __typename?: "Course";
    id: string;
    name: string;
    club: { __typename?: "Club"; id: string; name: string };
  };
  gameType: { __typename?: "GameType"; id: string; description: string };
  userRounds: Array<{ __typename?: "UserRound"; id: string }>;
  settings?: {
    __typename?: "RoundSettings";
    id: string;
    handicapEditAllowed: boolean;
    leaderboardHoleLimit: number;
    handicapAllowance: number;
    inviteCodeRequired: boolean;
    field?: string | null;
    cutOffDate?: string | null;
    determineWinnerBy?: string | null;
  } | null;
};

export type CreateClubRoundsMutationVariables = Exact<{
  input: CreateClubRoundsInput;
}>;

export type CreateClubRoundsMutation = {
  __typename?: "Mutation";
  createClubRounds: Array<{
    __typename?: "Round";
    id: string;
    startTime: string;
    name?: string | null;
    numberOfHoles: number;
    course: {
      __typename?: "Course";
      id: string;
      name: string;
      club: { __typename?: "Club"; id: string; name: string };
    };
    gameType: { __typename?: "GameType"; id: string; description: string };
    userRounds: Array<{ __typename?: "UserRound"; id: string }>;
    settings?: {
      __typename?: "RoundSettings";
      id: string;
      handicapEditAllowed: boolean;
      leaderboardHoleLimit: number;
      handicapAllowance: number;
      inviteCodeRequired: boolean;
      field?: string | null;
      cutOffDate?: string | null;
      determineWinnerBy?: string | null;
    } | null;
  }>;
};

export type UpdateClubRoundMutationVariables = Exact<{
  input: UpdateClubRoundInput;
}>;

export type UpdateClubRoundMutation = {
  __typename?: "Mutation";
  updateClubRound: {
    __typename?: "Round";
    id: string;
    startTime: string;
    name?: string | null;
    numberOfHoles: number;
    course: {
      __typename?: "Course";
      id: string;
      name: string;
      club: { __typename?: "Club"; id: string; name: string };
    };
    gameType: { __typename?: "GameType"; id: string; description: string };
    userRounds: Array<{ __typename?: "UserRound"; id: string }>;
    settings?: {
      __typename?: "RoundSettings";
      id: string;
      handicapEditAllowed: boolean;
      leaderboardHoleLimit: number;
      handicapAllowance: number;
      inviteCodeRequired: boolean;
      field?: string | null;
      cutOffDate?: string | null;
      determineWinnerBy?: string | null;
    } | null;
  };
};

export type CreatePrivateRoundMutationVariables = Exact<{
  privateRound: PrivateRoundInput;
}>;

export type CreatePrivateRoundMutation = {
  __typename?: "Mutation";
  createPrivateRound: {
    __typename?: "Round";
    id: string;
    startTime: string;
    course: {
      __typename?: "Course";
      id: string;
      name: string;
      club: { __typename?: "Club"; id: string; name: string };
    };
    gameType: { __typename?: "GameType"; id: string; description: string };
    userRounds: Array<{ __typename?: "UserRound"; id: string }>;
  };
};

export type RoundStatsPartsFragment = {
  __typename?: "RoundStats";
  albatrosses: Array<{
    __typename?: "StrokeLeaderboardEntry";
    position: number;
    userStrokeOccurrence: {
      __typename?: "UserStrokeOccurrence";
      numOccurrences: number;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
      };
    };
  }>;
  pars: Array<{
    __typename?: "StrokeLeaderboardEntry";
    position: number;
    userStrokeOccurrence: {
      __typename?: "UserStrokeOccurrence";
      numOccurrences: number;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
      };
    };
  }>;
  birdies: Array<{
    __typename?: "StrokeLeaderboardEntry";
    position: number;
    userStrokeOccurrence: {
      __typename?: "UserStrokeOccurrence";
      numOccurrences: number;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
      };
    };
  }>;
  holeInOnes: Array<{
    __typename?: "StrokeLeaderboardEntry";
    position: number;
    userStrokeOccurrence: {
      __typename?: "UserStrokeOccurrence";
      numOccurrences: number;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
      };
    };
  }>;
  eagles: Array<{
    __typename?: "StrokeLeaderboardEntry";
    position: number;
    userStrokeOccurrence: {
      __typename?: "UserStrokeOccurrence";
      numOccurrences: number;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
      };
    };
  }>;
  bogeys: Array<{
    __typename?: "StrokeLeaderboardEntry";
    position: number;
    userStrokeOccurrence: {
      __typename?: "UserStrokeOccurrence";
      numOccurrences: number;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
      };
    };
  }>;
  doubleBogeys: Array<{
    __typename?: "StrokeLeaderboardEntry";
    position: number;
    userStrokeOccurrence: {
      __typename?: "UserStrokeOccurrence";
      numOccurrences: number;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
      };
    };
  }>;
  tripleBogeys: Array<{
    __typename?: "StrokeLeaderboardEntry";
    position: number;
    userStrokeOccurrence: {
      __typename?: "UserStrokeOccurrence";
      numOccurrences: number;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
      };
    };
  }>;
  blackouts: Array<{
    __typename?: "StrokeLeaderboardEntry";
    position: number;
    userStrokeOccurrence: {
      __typename?: "UserStrokeOccurrence";
      numOccurrences: number;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
      };
    };
  }>;
};

export type StrokeLeaderboardEntryPartsFragment = {
  __typename?: "StrokeLeaderboardEntry";
  position: number;
  userStrokeOccurrence: {
    __typename?: "UserStrokeOccurrence";
    numOccurrences: number;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      avatarUrl: string;
    };
  };
};

export type UserScoreCardPartsFragment = {
  __typename?: "UserScoreCard";
  id: string;
  score: number;
  points: number;
  dateCreated: string;
  hole: {
    __typename?: "Hole";
    id: string;
    holeNumber: number;
    malePar: number;
    femalePar: number;
  };
  userRound: {
    __typename?: "UserRound";
    id: string;
    handicap?: number | null;
    isSubmitted?: boolean | null;
    isComplete: boolean;
    courseTee?: { __typename?: "CourseTee"; id: string } | null;
    userScoreCards: Array<{
      __typename?: "UserScoreCard";
      id: string;
      dateCreated: string;
      strokeType?: StrokeType | null;
      points: number;
      score: number;
      hole: {
        __typename?: "Hole";
        id: string;
        holeNumber: number;
        par: number;
        malePar: number;
        femalePar: number;
        maleStroke: number;
        femaleStroke: number;
      };
    }>;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      avatarUrl: string;
      saPlayerId?: string | null;
      gender?: { __typename?: "Gender"; id: string } | null;
    };
    stats: {
      __typename?: "UserRoundStats";
      id: string;
      points: number;
      status: number;
      score: number;
    };
  };
};

export type DeleteRoundMutationVariables = Exact<{
  roundId: Scalars["ID"];
}>;

export type DeleteRoundMutation = {
  __typename?: "Mutation";
  deleteRound: { __typename?: "Round"; id: string };
};

export type GetRoundLeaderboardByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetRoundLeaderboardByIdQuery = {
  __typename?: "Query";
  publicRoundLeaderboard: {
    __typename?: "PublicLeaderboard";
    id: string;
    round: {
      __typename?: "Round";
      id: string;
      name?: string | null;
      startTime: string;
      isComplete: boolean;
      roundType: { __typename?: "RoundType"; id: string };
      latestScores: Array<{
        __typename?: "UserScoreCard";
        id: string;
        score: number;
        points: number;
        dateCreated: string;
        hole: {
          __typename?: "Hole";
          id: string;
          holeNumber: number;
          malePar: number;
          femalePar: number;
        };
        userRound: {
          __typename?: "UserRound";
          id: string;
          handicap?: number | null;
          isSubmitted?: boolean | null;
          isComplete: boolean;
          courseTee?: { __typename?: "CourseTee"; id: string } | null;
          userScoreCards: Array<{
            __typename?: "UserScoreCard";
            id: string;
            dateCreated: string;
            strokeType?: StrokeType | null;
            points: number;
            score: number;
            hole: {
              __typename?: "Hole";
              id: string;
              holeNumber: number;
              par: number;
              malePar: number;
              femalePar: number;
              maleStroke: number;
              femaleStroke: number;
            };
          }>;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
            saPlayerId?: string | null;
            gender?: { __typename?: "Gender"; id: string } | null;
          };
          stats: {
            __typename?: "UserRoundStats";
            id: string;
            points: number;
            status: number;
            score: number;
          };
        };
      }>;
      rareScores: Array<{
        __typename?: "UserScoreCard";
        id: string;
        score: number;
        points: number;
        dateCreated: string;
        hole: {
          __typename?: "Hole";
          id: string;
          holeNumber: number;
          malePar: number;
          femalePar: number;
        };
        userRound: {
          __typename?: "UserRound";
          id: string;
          handicap?: number | null;
          isSubmitted?: boolean | null;
          isComplete: boolean;
          courseTee?: { __typename?: "CourseTee"; id: string } | null;
          userScoreCards: Array<{
            __typename?: "UserScoreCard";
            id: string;
            dateCreated: string;
            strokeType?: StrokeType | null;
            points: number;
            score: number;
            hole: {
              __typename?: "Hole";
              id: string;
              holeNumber: number;
              par: number;
              malePar: number;
              femalePar: number;
              maleStroke: number;
              femaleStroke: number;
            };
          }>;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
            saPlayerId?: string | null;
            gender?: { __typename?: "Gender"; id: string } | null;
          };
          stats: {
            __typename?: "UserRoundStats";
            id: string;
            points: number;
            status: number;
            score: number;
          };
        };
      }>;
      roundStats: {
        __typename?: "RoundStats";
        albatrosses: Array<{
          __typename?: "StrokeLeaderboardEntry";
          position: number;
          userStrokeOccurrence: {
            __typename?: "UserStrokeOccurrence";
            numOccurrences: number;
            user: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: string;
            };
          };
        }>;
        pars: Array<{
          __typename?: "StrokeLeaderboardEntry";
          position: number;
          userStrokeOccurrence: {
            __typename?: "UserStrokeOccurrence";
            numOccurrences: number;
            user: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: string;
            };
          };
        }>;
        birdies: Array<{
          __typename?: "StrokeLeaderboardEntry";
          position: number;
          userStrokeOccurrence: {
            __typename?: "UserStrokeOccurrence";
            numOccurrences: number;
            user: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: string;
            };
          };
        }>;
        holeInOnes: Array<{
          __typename?: "StrokeLeaderboardEntry";
          position: number;
          userStrokeOccurrence: {
            __typename?: "UserStrokeOccurrence";
            numOccurrences: number;
            user: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: string;
            };
          };
        }>;
        eagles: Array<{
          __typename?: "StrokeLeaderboardEntry";
          position: number;
          userStrokeOccurrence: {
            __typename?: "UserStrokeOccurrence";
            numOccurrences: number;
            user: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: string;
            };
          };
        }>;
        bogeys: Array<{
          __typename?: "StrokeLeaderboardEntry";
          position: number;
          userStrokeOccurrence: {
            __typename?: "UserStrokeOccurrence";
            numOccurrences: number;
            user: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: string;
            };
          };
        }>;
        doubleBogeys: Array<{
          __typename?: "StrokeLeaderboardEntry";
          position: number;
          userStrokeOccurrence: {
            __typename?: "UserStrokeOccurrence";
            numOccurrences: number;
            user: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: string;
            };
          };
        }>;
        tripleBogeys: Array<{
          __typename?: "StrokeLeaderboardEntry";
          position: number;
          userStrokeOccurrence: {
            __typename?: "UserStrokeOccurrence";
            numOccurrences: number;
            user: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: string;
            };
          };
        }>;
        blackouts: Array<{
          __typename?: "StrokeLeaderboardEntry";
          position: number;
          userStrokeOccurrence: {
            __typename?: "UserStrokeOccurrence";
            numOccurrences: number;
            user: {
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: string;
            };
          };
        }>;
      };
      course: {
        __typename?: "Course";
        id: string;
        name: string;
        club: {
          __typename?: "Club";
          id: string;
          name: string;
          logoUrl?: string | null;
          bannerUrl?: string | null;
          webLeaderboardSidebarAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
          webLeaderboardFooterSlotOneAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
          webLeaderboardFooterSlotTwoAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
          webLeaderboardFooterSlotThreeAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
          webLeaderboardFooterSlotFourAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
        };
      };
      gameType: {
        __typename?: "GameType";
        id: string;
        description: string;
        teamSize: number;
        gameFormat: { __typename?: "GameFormat"; id: string };
        scoringMethod: { __typename?: "ScoringMethod"; id: string };
      };
      settings?: {
        __typename?: "RoundSettings";
        id: string;
        determineWinnerBy?: string | null;
        genderSeparatedDivisions: boolean;
        leaderboardHoleLimit: number;
        limitTvLeaderboardHoles: boolean;
      } | null;
      divisions?: Array<{
        __typename?: "Division";
        id: string;
        name?: string | null;
        description?: string | null;
        minHandicapIndex?: number | null;
        maxHandicapIndex?: number | null;
        determineWinnerBy?: string | null;
        gender?: { __typename?: "Gender"; id: string } | null;
        gameType: {
          __typename?: "GameType";
          id: string;
          description: string;
          teamSize: number;
          scoringMethod: { __typename?: "ScoringMethod"; id: string };
          gameFormat: {
            __typename?: "GameFormat";
            id: string;
            description: string;
          };
        };
      } | null> | null;
    };
    leaderboard: Array<{
      __typename?: "TeamLeaderboardEntry";
      id: string;
      isCountOut: boolean;
      rank: number;
      total: number;
      thru: number;
      userRounds: Array<{
        __typename?: "UserRound";
        id: string;
        isSubmitted?: boolean | null;
        handicap?: number | null;
        userRoundHandicap?: {
          __typename?: "UserRoundHandicap";
          handicapIndex?: number | null;
          isOfficial: boolean;
        } | null;
        user: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          gender?: { __typename?: "Gender"; id: string } | null;
        };
      }>;
      totals: {
        __typename?: "LeaderboardEntryTotals";
        gross: number;
        nettToPar: number;
        nettToHandicap: number;
      };
    }>;
  };
};

export type GetRoundByInviteCodeQueryVariables = Exact<{
  inviteCode: Scalars["String"];
}>;

export type GetRoundByInviteCodeQuery = {
  __typename?: "Query";
  roundLookup?: { __typename?: "Round"; id: string } | null;
};

export type GetClubRoundByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetClubRoundByIdQuery = {
  __typename?: "Query";
  round?: {
    __typename?: "Round";
    id: string;
    startTime: string;
    name?: string | null;
    numberOfHoles: number;
    isComplete: boolean;
    isInProgress: boolean;
    course: { __typename?: "Course"; id: string; name: string };
    gameType: { __typename?: "GameType"; id: string; description: string };
    settings?: {
      __typename?: "RoundSettings";
      id: string;
      handicapEditAllowed: boolean;
      captureLimitInSeconds?: number | null;
      leaderboardHoleLimit: number;
      limitTvLeaderboardHoles: boolean;
      handicapAllowance: number;
      inviteCodeRequired: boolean;
      field?: string | null;
      cutOffDate?: string | null;
      determineWinnerBy?: string | null;
      genderSeparatedDivisions: boolean;
    } | null;
    tickerItems: Array<{
      __typename?: "RoundTickerItem";
      id: string;
      description: string;
    }>;
    tournament?: { __typename?: "Tournament"; id: string } | null;
    userRounds: Array<{ __typename?: "UserRound"; id: string }>;
    roundType: { __typename?: "RoundType"; id: string };
    divisions?: Array<{
      __typename?: "Division";
      id: string;
      name?: string | null;
      genderId?: string | null;
      gameTypeId: string;
      maxHandicapIndex?: number | null;
      minHandicapIndex?: number | null;
      description?: string | null;
      determineWinnerBy?: string | null;
    } | null> | null;
  } | null;
};

export type GetTickerItemsOfPreviousClubRoundQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetTickerItemsOfPreviousClubRoundQuery = {
  __typename?: "Query";
  tickerItemsOfPreviousRound: Array<{
    __typename?: "RoundTickerItem";
    id: string;
    description: string;
  }>;
};

export type GetUserRoundsByRoundIdQueryVariables = Exact<{
  roundId: Scalars["ID"];
}>;

export type GetUserRoundsByRoundIdQuery = {
  __typename?: "Query";
  round?: {
    __typename?: "Round";
    id: string;
    gameType: { __typename?: "GameType"; id: string };
    userRounds: Array<{
      __typename?: "UserRound";
      id: string;
      scoringGroup?: {
        __typename?: "ScoringGroup";
        id: string;
        startingHole: number;
      } | null;
      team?: { __typename?: "Team"; id: string } | null;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      userRoundHandicap?: {
        __typename?: "UserRoundHandicap";
        id: string;
        playingHandicap: number;
      } | null;
      userScoreCards: Array<{
        __typename?: "UserScoreCard";
        id: string;
        points: number;
        score: number;
        par: number;
        hole: { __typename?: "Hole"; id: string; holeNumber: number };
      }>;
    }>;
  } | null;
};

export type GetRoundStatsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetRoundStatsQuery = {
  __typename?: "Query";
  round?: {
    __typename?: "Round";
    id: string;
    isComplete: boolean;
    numberOfHoles: number;
    roundStats: {
      __typename?: "RoundStats";
      albatrosses: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      pars: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      birdies: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      holeInOnes: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      eagles: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      bogeys: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      doubleBogeys: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      tripleBogeys: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
      blackouts: Array<{
        __typename?: "StrokeLeaderboardEntry";
        position: number;
        userStrokeOccurrence: {
          __typename?: "UserStrokeOccurrence";
          numOccurrences: number;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
          };
        };
      }>;
    };
    settings?: {
      __typename?: "RoundSettings";
      id: string;
      leaderboardHoleLimit: number;
    } | null;
  } | null;
};

export type HolePerformancePartsFragment = {
  __typename?: "HolePerformance";
  id: string;
  stroke: number;
  rank: number;
  averageScore: number;
  maxScore: number;
  eagles: number;
  birdies: number;
  pars: number;
  bogeys: number;
  doubleBogeys: number;
  doublePlus: number;
  hole: { __typename?: "Hole"; id: string; holeNumber: number; par: number };
};

export type GetRoundPerformanceQueryVariables = Exact<{
  roundId: Scalars["ID"];
}>;

export type GetRoundPerformanceQuery = {
  __typename?: "Query";
  roundPerformance: {
    __typename?: "RoundPerformance";
    id: string;
    holePerformance: Array<{
      __typename?: "HolePerformance";
      id: string;
      stroke: number;
      rank: number;
      averageScore: number;
      maxScore: number;
      eagles: number;
      birdies: number;
      pars: number;
      bogeys: number;
      doubleBogeys: number;
      doublePlus: number;
      hole: {
        __typename?: "Hole";
        id: string;
        holeNumber: number;
        par: number;
      };
    }>;
  };
};

export type AddPlayersByEmailMutationVariables = Exact<{
  input: AddPlayersByEmailInput;
}>;

export type AddPlayersByEmailMutation = {
  __typename?: "Mutation";
  addPlayersByEmail: {
    __typename?: "Round";
    id: string;
    userRounds: Array<{
      __typename?: "UserRound";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
    }>;
  };
};

export type CreateFourballMutationVariables = Exact<{
  input: CreateFourballInput;
}>;

export type CreateFourballMutation = {
  __typename?: "Mutation";
  createFourball: Array<{
    __typename?: "UserRound";
    id: string;
    scoringGroup?: {
      __typename?: "ScoringGroup";
      id: string;
      startingHole: number;
    } | null;
  }>;
};

export type AddGhostPlayerMutationVariables = Exact<{
  input: AddGhostPlayerInput;
}>;

export type AddGhostPlayerMutation = {
  __typename?: "Mutation";
  addGhostPlayer: boolean;
};

export type AddRandomGhostPlayerMutationVariables = Exact<{
  input: AddRandomGhostPlayerInput;
}>;

export type AddRandomGhostPlayerMutation = {
  __typename?: "Mutation";
  addRandomGhostPlayer: boolean;
};

export type AddSpecificPivotPlayerMutationVariables = Exact<{
  input: AddSpecificPivotPlayerInput;
}>;

export type AddSpecificPivotPlayerMutation = {
  __typename?: "Mutation";
  addSpecificPivotPlayer: {
    __typename?: "ScoringGroup";
    id: string;
    teams: Array<{
      __typename?: "Team";
      id: string;
      total: number;
      specialUserRounds: Array<string>;
      userRounds: Array<{ __typename?: "UserRound"; id: string }>;
    }>;
  };
};

export type AddPivotPlayerMutationVariables = Exact<{
  input: AddPivotPlayerInput;
}>;

export type AddPivotPlayerMutation = {
  __typename?: "Mutation";
  addPivotPlayer: {
    __typename?: "ScoringGroup";
    id: string;
    teams: Array<{
      __typename?: "Team";
      id: string;
      total: number;
      specialUserRounds: Array<string>;
      userRounds: Array<{ __typename?: "UserRound"; id: string }>;
    }>;
  };
};

export type AdvertPartsFragment = {
  __typename?: "SponsorAdvert";
  description: string;
  id: string;
  imageUrl: string;
  redirectUrl?: string | null;
};

export type GetSponsorAdvertBySlotQueryVariables = Exact<{
  slot: SponsorSlot;
  clubId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetSponsorAdvertBySlotQuery = {
  __typename?: "Query";
  advert?: {
    __typename?: "SponsorAdvert";
    description: string;
    id: string;
    imageUrl: string;
    redirectUrl?: string | null;
  } | null;
};

export type GetTeamScorecardByTeamIdQueryVariables = Exact<{
  teamId: Scalars["ID"];
  roundId: Scalars["ID"];
}>;

export type GetTeamScorecardByTeamIdQuery = {
  __typename?: "Query";
  round?: {
    __typename?: "Round";
    id: string;
    dateCreated: string;
    course: {
      __typename?: "Course";
      id: string;
      name: string;
      club: { __typename?: "Club"; id: string; name: string };
    };
    settings?: {
      __typename?: "RoundSettings";
      id: string;
      determineWinnerBy?: string | null;
    } | null;
    courseScoreCard: {
      __typename?: "CourseScoreCard";
      id: string;
      holes: Array<{
        __typename?: "Hole";
        id: string;
        holeNumber: number;
        malePar: number;
        malePlusStroke?: number | null;
        maleStroke: number;
        femalePar: number;
        femalePlusStroke?: number | null;
        femaleStroke: number;
      }>;
    };
    gameType: {
      __typename?: "GameType";
      id: string;
      scoringMethod: { __typename?: "ScoringMethod"; id: string };
    };
  } | null;
  team: {
    __typename?: "Team";
    id: string;
    total: number;
    specialUserRounds: Array<string>;
    userRounds: Array<{
      __typename?: "UserRound";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        gender?: { __typename?: "Gender"; id: string } | null;
      };
      userRoundHandicap?: {
        __typename?: "UserRoundHandicap";
        id: string;
        playingHandicap: number;
        courseHandicap: number;
        handicapIndex?: number | null;
      } | null;
      userScoreCards: Array<{
        __typename?: "UserScoreCard";
        id: string;
        holeNumber: number;
        score: number;
        points: number;
        par: number;
        stroke: number;
      }>;
    }>;
  };
};

export type TournamentRoundPartsFragment = {
  __typename?: "Round";
  id: string;
  startTime: string;
  inviteCode?: string | null;
  gameType: { __typename?: "GameType"; id: string; description: string };
  course: {
    __typename?: "Course";
    id: string;
    name: string;
    club: { __typename?: "Club"; id: string; name: string };
  };
  userRounds: Array<{ __typename?: "UserRound"; id: string }>;
  settings?: {
    __typename?: "RoundSettings";
    genderSeparatedDivisions: boolean;
  } | null;
  divisions?: Array<{
    __typename?: "Division";
    id: string;
    name?: string | null;
    description?: string | null;
    minHandicapIndex?: number | null;
    maxHandicapIndex?: number | null;
    determineWinnerBy?: string | null;
    gender?: { __typename?: "Gender"; id: string } | null;
    gameType: {
      __typename?: "GameType";
      id: string;
      description: string;
      teamSize: number;
      scoringMethod: { __typename?: "ScoringMethod"; id: string };
      gameFormat: {
        __typename?: "GameFormat";
        id: string;
        description: string;
      };
    };
  } | null> | null;
  roundType: { __typename?: "RoundType"; id: string };
};

export type TournamentPartsFragment = {
  __typename?: "Tournament";
  id: string;
  name: string;
  gameType: { __typename?: "GameType"; id: string; description: string };
  rounds: Array<{
    __typename?: "Round";
    id: string;
    startTime: string;
    inviteCode?: string | null;
    gameType: { __typename?: "GameType"; id: string; description: string };
    course: {
      __typename?: "Course";
      id: string;
      name: string;
      club: { __typename?: "Club"; id: string; name: string };
    };
    userRounds: Array<{ __typename?: "UserRound"; id: string }>;
    settings?: {
      __typename?: "RoundSettings";
      genderSeparatedDivisions: boolean;
    } | null;
    divisions?: Array<{
      __typename?: "Division";
      id: string;
      name?: string | null;
      description?: string | null;
      minHandicapIndex?: number | null;
      maxHandicapIndex?: number | null;
      determineWinnerBy?: string | null;
      gender?: { __typename?: "Gender"; id: string } | null;
      gameType: {
        __typename?: "GameType";
        id: string;
        description: string;
        teamSize: number;
        scoringMethod: { __typename?: "ScoringMethod"; id: string };
        gameFormat: {
          __typename?: "GameFormat";
          id: string;
          description: string;
        };
      };
    } | null> | null;
    roundType: { __typename?: "RoundType"; id: string };
  }>;
  roundType: { __typename?: "RoundType"; id: string };
  tournamentDivisions: Array<{
    __typename?: "Division";
    id: string;
    name?: string | null;
    description?: string | null;
    minHandicapIndex?: number | null;
    maxHandicapIndex?: number | null;
    determineWinnerBy?: string | null;
    gender?: { __typename?: "Gender"; id: string } | null;
    gameType: {
      __typename?: "GameType";
      id: string;
      description: string;
      teamSize: number;
      scoringMethod: { __typename?: "ScoringMethod"; id: string };
      gameFormat: {
        __typename?: "GameFormat";
        id: string;
        description: string;
      };
    };
  }>;
};

export type TournamentLeaderboardPartsFragment = {
  __typename?: "Tournament";
  id: string;
  name: string;
  gameType: {
    __typename?: "GameType";
    id: string;
    description: string;
    teamSize: number;
    scoringMethod: { __typename?: "ScoringMethod"; id: string };
  };
  roundType: { __typename?: "RoundType"; id: string };
  tournamentDivisions: Array<{
    __typename?: "Division";
    id: string;
    name?: string | null;
    description?: string | null;
    minHandicapIndex?: number | null;
    maxHandicapIndex?: number | null;
    determineWinnerBy?: string | null;
    gender?: { __typename?: "Gender"; id: string } | null;
    gameType: {
      __typename?: "GameType";
      id: string;
      description: string;
      teamSize: number;
      scoringMethod: { __typename?: "ScoringMethod"; id: string };
      gameFormat: {
        __typename?: "GameFormat";
        id: string;
        description: string;
      };
    };
  }>;
  settings?: {
    __typename?: "TournamentSettings";
    id: string;
    genderSeparatedDivisions?: boolean | null;
  } | null;
  rounds: Array<{
    __typename?: "Round";
    id: string;
    startTime: string;
    isComplete: boolean;
    latestScores: Array<{
      __typename?: "UserScoreCard";
      id: string;
      score: number;
      points: number;
      dateCreated: string;
      hole: {
        __typename?: "Hole";
        id: string;
        holeNumber: number;
        malePar: number;
        femalePar: number;
      };
      userRound: {
        __typename?: "UserRound";
        id: string;
        handicap?: number | null;
        isSubmitted?: boolean | null;
        isComplete: boolean;
        courseTee?: { __typename?: "CourseTee"; id: string } | null;
        userScoreCards: Array<{
          __typename?: "UserScoreCard";
          id: string;
          dateCreated: string;
          strokeType?: StrokeType | null;
          points: number;
          score: number;
          hole: {
            __typename?: "Hole";
            id: string;
            holeNumber: number;
            par: number;
            malePar: number;
            femalePar: number;
            maleStroke: number;
            femaleStroke: number;
          };
        }>;
        user: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: string;
          saPlayerId?: string | null;
          gender?: { __typename?: "Gender"; id: string } | null;
        };
        stats: {
          __typename?: "UserRoundStats";
          id: string;
          points: number;
          status: number;
          score: number;
        };
      };
    }>;
    rareScores: Array<{
      __typename?: "UserScoreCard";
      id: string;
      score: number;
      points: number;
      dateCreated: string;
      hole: {
        __typename?: "Hole";
        id: string;
        holeNumber: number;
        malePar: number;
        femalePar: number;
      };
      userRound: {
        __typename?: "UserRound";
        id: string;
        handicap?: number | null;
        isSubmitted?: boolean | null;
        isComplete: boolean;
        courseTee?: { __typename?: "CourseTee"; id: string } | null;
        userScoreCards: Array<{
          __typename?: "UserScoreCard";
          id: string;
          dateCreated: string;
          strokeType?: StrokeType | null;
          points: number;
          score: number;
          hole: {
            __typename?: "Hole";
            id: string;
            holeNumber: number;
            par: number;
            malePar: number;
            femalePar: number;
            maleStroke: number;
            femaleStroke: number;
          };
        }>;
        user: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: string;
          saPlayerId?: string | null;
          gender?: { __typename?: "Gender"; id: string } | null;
        };
        stats: {
          __typename?: "UserRoundStats";
          id: string;
          points: number;
          status: number;
          score: number;
        };
      };
    }>;
    course: {
      __typename?: "Course";
      id: string;
      name: string;
      club: {
        __typename?: "Club";
        id: string;
        logoUrl?: string | null;
        webLeaderboardSidebarAdvert?: {
          __typename?: "SponsorAdvert";
          description: string;
          id: string;
          imageUrl: string;
          redirectUrl?: string | null;
        } | null;
        webLeaderboardFooterSlotOneAdvert?: {
          __typename?: "SponsorAdvert";
          description: string;
          id: string;
          imageUrl: string;
          redirectUrl?: string | null;
        } | null;
        webLeaderboardFooterSlotTwoAdvert?: {
          __typename?: "SponsorAdvert";
          description: string;
          id: string;
          imageUrl: string;
          redirectUrl?: string | null;
        } | null;
        webLeaderboardFooterSlotThreeAdvert?: {
          __typename?: "SponsorAdvert";
          description: string;
          id: string;
          imageUrl: string;
          redirectUrl?: string | null;
        } | null;
        webLeaderboardFooterSlotFourAdvert?: {
          __typename?: "SponsorAdvert";
          description: string;
          id: string;
          imageUrl: string;
          redirectUrl?: string | null;
        } | null;
      };
    };
    teams: Array<{
      __typename?: "Team";
      id: string;
      total: number;
      userRounds: Array<{
        __typename?: "UserRound";
        id: string;
        handicap?: number | null;
        isSubmitted?: boolean | null;
        stats: {
          __typename?: "UserRoundStats";
          id: string;
          score: number;
          points: number;
        };
        userRoundHandicap?: {
          __typename?: "UserRoundHandicap";
          id: string;
          handicapIndex?: number | null;
          isOfficial: boolean;
        } | null;
        user: {
          __typename?: "User";
          id: string;
          avatarUrl: string;
          firstName: string;
          lastName: string;
          gender?: { __typename?: "Gender"; id: string } | null;
          handicapIndex: {
            __typename?: "HandicapIndex";
            userId: string;
            isOfficial: boolean;
            value?: number | null;
          };
        };
        round: { __typename?: "Round"; id: string; startTime: string };
        userScoreCards: Array<{ __typename?: "UserScoreCard"; id: string }>;
      }>;
    }>;
  }>;
};

export type CreateOrUpdateTournamentMutationVariables = Exact<{
  createOrUpdateTournamentInput: CreateOrUpdateTournamentInput;
}>;

export type CreateOrUpdateTournamentMutation = {
  __typename?: "Mutation";
  createOrUpdateTournament: {
    __typename?: "Tournament";
    id: string;
    name: string;
    gameType: { __typename?: "GameType"; id: string; description: string };
    rounds: Array<{
      __typename?: "Round";
      id: string;
      startTime: string;
      inviteCode?: string | null;
      gameType: { __typename?: "GameType"; id: string; description: string };
      course: {
        __typename?: "Course";
        id: string;
        name: string;
        club: { __typename?: "Club"; id: string; name: string };
      };
      userRounds: Array<{ __typename?: "UserRound"; id: string }>;
      settings?: {
        __typename?: "RoundSettings";
        genderSeparatedDivisions: boolean;
      } | null;
      divisions?: Array<{
        __typename?: "Division";
        id: string;
        name?: string | null;
        description?: string | null;
        minHandicapIndex?: number | null;
        maxHandicapIndex?: number | null;
        determineWinnerBy?: string | null;
        gender?: { __typename?: "Gender"; id: string } | null;
        gameType: {
          __typename?: "GameType";
          id: string;
          description: string;
          teamSize: number;
          scoringMethod: { __typename?: "ScoringMethod"; id: string };
          gameFormat: {
            __typename?: "GameFormat";
            id: string;
            description: string;
          };
        };
      } | null> | null;
      roundType: { __typename?: "RoundType"; id: string };
    }>;
    roundType: { __typename?: "RoundType"; id: string };
    tournamentDivisions: Array<{
      __typename?: "Division";
      id: string;
      name?: string | null;
      description?: string | null;
      minHandicapIndex?: number | null;
      maxHandicapIndex?: number | null;
      determineWinnerBy?: string | null;
      gender?: { __typename?: "Gender"; id: string } | null;
      gameType: {
        __typename?: "GameType";
        id: string;
        description: string;
        teamSize: number;
        scoringMethod: { __typename?: "ScoringMethod"; id: string };
        gameFormat: {
          __typename?: "GameFormat";
          id: string;
          description: string;
        };
      };
    }>;
  };
};

export type GetClubTournamentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClubTournamentsQuery = {
  __typename?: "Query";
  clubTournaments: Array<{
    __typename?: "Tournament";
    id: string;
    name: string;
    gameType: { __typename?: "GameType"; id: string; description: string };
    rounds: Array<{
      __typename?: "Round";
      id: string;
      startTime: string;
      inviteCode?: string | null;
      gameType: { __typename?: "GameType"; id: string; description: string };
      course: {
        __typename?: "Course";
        id: string;
        name: string;
        club: { __typename?: "Club"; id: string; name: string };
      };
      userRounds: Array<{ __typename?: "UserRound"; id: string }>;
      settings?: {
        __typename?: "RoundSettings";
        genderSeparatedDivisions: boolean;
      } | null;
      divisions?: Array<{
        __typename?: "Division";
        id: string;
        name?: string | null;
        description?: string | null;
        minHandicapIndex?: number | null;
        maxHandicapIndex?: number | null;
        determineWinnerBy?: string | null;
        gender?: { __typename?: "Gender"; id: string } | null;
        gameType: {
          __typename?: "GameType";
          id: string;
          description: string;
          teamSize: number;
          scoringMethod: { __typename?: "ScoringMethod"; id: string };
          gameFormat: {
            __typename?: "GameFormat";
            id: string;
            description: string;
          };
        };
      } | null> | null;
      roundType: { __typename?: "RoundType"; id: string };
    }>;
    roundType: { __typename?: "RoundType"; id: string };
    tournamentDivisions: Array<{
      __typename?: "Division";
      id: string;
      name?: string | null;
      description?: string | null;
      minHandicapIndex?: number | null;
      maxHandicapIndex?: number | null;
      determineWinnerBy?: string | null;
      gender?: { __typename?: "Gender"; id: string } | null;
      gameType: {
        __typename?: "GameType";
        id: string;
        description: string;
        teamSize: number;
        scoringMethod: { __typename?: "ScoringMethod"; id: string };
        gameFormat: {
          __typename?: "GameFormat";
          id: string;
          description: string;
        };
      };
    }>;
  }>;
};

export type GetSimpleClubTournamentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSimpleClubTournamentsQuery = {
  __typename?: "Query";
  clubTournaments: Array<{
    __typename?: "Tournament";
    id: string;
    name: string;
  }>;
};

export type GetTournamentAndClubCoursesQueryVariables = Exact<{
  tournamentId: Scalars["ID"];
}>;

export type GetTournamentAndClubCoursesQuery = {
  __typename?: "Query";
  tournament: {
    __typename?: "Tournament";
    id: string;
    name: string;
    gameType: { __typename?: "GameType"; id: string; description: string };
    rounds: Array<{
      __typename?: "Round";
      id: string;
      startTime: string;
      inviteCode?: string | null;
      gameType: { __typename?: "GameType"; id: string; description: string };
      course: {
        __typename?: "Course";
        id: string;
        name: string;
        club: { __typename?: "Club"; id: string; name: string };
      };
      userRounds: Array<{ __typename?: "UserRound"; id: string }>;
      settings?: {
        __typename?: "RoundSettings";
        genderSeparatedDivisions: boolean;
      } | null;
      divisions?: Array<{
        __typename?: "Division";
        id: string;
        name?: string | null;
        description?: string | null;
        minHandicapIndex?: number | null;
        maxHandicapIndex?: number | null;
        determineWinnerBy?: string | null;
        gender?: { __typename?: "Gender"; id: string } | null;
        gameType: {
          __typename?: "GameType";
          id: string;
          description: string;
          teamSize: number;
          scoringMethod: { __typename?: "ScoringMethod"; id: string };
          gameFormat: {
            __typename?: "GameFormat";
            id: string;
            description: string;
          };
        };
      } | null> | null;
      roundType: { __typename?: "RoundType"; id: string };
    }>;
    roundType: { __typename?: "RoundType"; id: string };
    tournamentDivisions: Array<{
      __typename?: "Division";
      id: string;
      name?: string | null;
      description?: string | null;
      minHandicapIndex?: number | null;
      maxHandicapIndex?: number | null;
      determineWinnerBy?: string | null;
      gender?: { __typename?: "Gender"; id: string } | null;
      gameType: {
        __typename?: "GameType";
        id: string;
        description: string;
        teamSize: number;
        scoringMethod: { __typename?: "ScoringMethod"; id: string };
        gameFormat: {
          __typename?: "GameFormat";
          id: string;
          description: string;
        };
      };
    }>;
  };
  currentClub: {
    __typename?: "Club";
    id: string;
    name: string;
    logoUrl?: string | null;
    courses: Array<{ __typename?: "Course"; id: string; name: string }>;
  };
};

export type CreateOfUpdateTournamentRoundMutationVariables = Exact<{
  createOrUpdateTournamentRoundInput: CreateOrUpdateTournamentRoundInput;
}>;

export type CreateOfUpdateTournamentRoundMutation = {
  __typename?: "Mutation";
  createOrUpdateTournamentRound: {
    __typename?: "Round";
    id: string;
    startTime: string;
    inviteCode?: string | null;
    gameType: { __typename?: "GameType"; id: string; description: string };
    course: {
      __typename?: "Course";
      id: string;
      name: string;
      club: { __typename?: "Club"; id: string; name: string };
    };
    userRounds: Array<{ __typename?: "UserRound"; id: string }>;
    settings?: {
      __typename?: "RoundSettings";
      genderSeparatedDivisions: boolean;
    } | null;
    divisions?: Array<{
      __typename?: "Division";
      id: string;
      name?: string | null;
      description?: string | null;
      minHandicapIndex?: number | null;
      maxHandicapIndex?: number | null;
      determineWinnerBy?: string | null;
      gender?: { __typename?: "Gender"; id: string } | null;
      gameType: {
        __typename?: "GameType";
        id: string;
        description: string;
        teamSize: number;
        scoringMethod: { __typename?: "ScoringMethod"; id: string };
        gameFormat: {
          __typename?: "GameFormat";
          id: string;
          description: string;
        };
      };
    } | null> | null;
    roundType: { __typename?: "RoundType"; id: string };
  };
};

export type DeleteTournamentMutationVariables = Exact<{
  tournamentId: Scalars["ID"];
}>;

export type DeleteTournamentMutation = {
  __typename?: "Mutation";
  deleteTournament: { __typename?: "Tournament"; id: string };
};

export type DeleteTournamentRoundMutationVariables = Exact<{
  roundId: Scalars["ID"];
}>;

export type DeleteTournamentRoundMutation = {
  __typename?: "Mutation";
  deleteTournamentRound: { __typename?: "Round"; id: string };
};

export type GetTournamentRoundQueryVariables = Exact<{
  roundId: Scalars["ID"];
}>;

export type GetTournamentRoundQuery = {
  __typename?: "Query";
  round?: {
    __typename?: "Round";
    id: string;
    startTime: string;
    inviteCode?: string | null;
    gameType: { __typename?: "GameType"; id: string; description: string };
    course: {
      __typename?: "Course";
      id: string;
      name: string;
      club: { __typename?: "Club"; id: string; name: string };
    };
    userRounds: Array<{ __typename?: "UserRound"; id: string }>;
    settings?: {
      __typename?: "RoundSettings";
      genderSeparatedDivisions: boolean;
    } | null;
    divisions?: Array<{
      __typename?: "Division";
      id: string;
      name?: string | null;
      description?: string | null;
      minHandicapIndex?: number | null;
      maxHandicapIndex?: number | null;
      determineWinnerBy?: string | null;
      gender?: { __typename?: "Gender"; id: string } | null;
      gameType: {
        __typename?: "GameType";
        id: string;
        description: string;
        teamSize: number;
        scoringMethod: { __typename?: "ScoringMethod"; id: string };
        gameFormat: {
          __typename?: "GameFormat";
          id: string;
          description: string;
        };
      };
    } | null> | null;
    roundType: { __typename?: "RoundType"; id: string };
  } | null;
};

export type GetTournamentLeaderboardQueryVariables = Exact<{
  tournamentId: Scalars["ID"];
}>;

export type GetTournamentLeaderboardQuery = {
  __typename?: "Query";
  tournament: {
    __typename?: "Tournament";
    id: string;
    name: string;
    gameType: {
      __typename?: "GameType";
      id: string;
      description: string;
      teamSize: number;
      scoringMethod: { __typename?: "ScoringMethod"; id: string };
    };
    roundType: { __typename?: "RoundType"; id: string };
    tournamentDivisions: Array<{
      __typename?: "Division";
      id: string;
      name?: string | null;
      description?: string | null;
      minHandicapIndex?: number | null;
      maxHandicapIndex?: number | null;
      determineWinnerBy?: string | null;
      gender?: { __typename?: "Gender"; id: string } | null;
      gameType: {
        __typename?: "GameType";
        id: string;
        description: string;
        teamSize: number;
        scoringMethod: { __typename?: "ScoringMethod"; id: string };
        gameFormat: {
          __typename?: "GameFormat";
          id: string;
          description: string;
        };
      };
    }>;
    settings?: {
      __typename?: "TournamentSettings";
      id: string;
      genderSeparatedDivisions?: boolean | null;
    } | null;
    rounds: Array<{
      __typename?: "Round";
      id: string;
      startTime: string;
      isComplete: boolean;
      latestScores: Array<{
        __typename?: "UserScoreCard";
        id: string;
        score: number;
        points: number;
        dateCreated: string;
        hole: {
          __typename?: "Hole";
          id: string;
          holeNumber: number;
          malePar: number;
          femalePar: number;
        };
        userRound: {
          __typename?: "UserRound";
          id: string;
          handicap?: number | null;
          isSubmitted?: boolean | null;
          isComplete: boolean;
          courseTee?: { __typename?: "CourseTee"; id: string } | null;
          userScoreCards: Array<{
            __typename?: "UserScoreCard";
            id: string;
            dateCreated: string;
            strokeType?: StrokeType | null;
            points: number;
            score: number;
            hole: {
              __typename?: "Hole";
              id: string;
              holeNumber: number;
              par: number;
              malePar: number;
              femalePar: number;
              maleStroke: number;
              femaleStroke: number;
            };
          }>;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
            saPlayerId?: string | null;
            gender?: { __typename?: "Gender"; id: string } | null;
          };
          stats: {
            __typename?: "UserRoundStats";
            id: string;
            points: number;
            status: number;
            score: number;
          };
        };
      }>;
      rareScores: Array<{
        __typename?: "UserScoreCard";
        id: string;
        score: number;
        points: number;
        dateCreated: string;
        hole: {
          __typename?: "Hole";
          id: string;
          holeNumber: number;
          malePar: number;
          femalePar: number;
        };
        userRound: {
          __typename?: "UserRound";
          id: string;
          handicap?: number | null;
          isSubmitted?: boolean | null;
          isComplete: boolean;
          courseTee?: { __typename?: "CourseTee"; id: string } | null;
          userScoreCards: Array<{
            __typename?: "UserScoreCard";
            id: string;
            dateCreated: string;
            strokeType?: StrokeType | null;
            points: number;
            score: number;
            hole: {
              __typename?: "Hole";
              id: string;
              holeNumber: number;
              par: number;
              malePar: number;
              femalePar: number;
              maleStroke: number;
              femaleStroke: number;
            };
          }>;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
            saPlayerId?: string | null;
            gender?: { __typename?: "Gender"; id: string } | null;
          };
          stats: {
            __typename?: "UserRoundStats";
            id: string;
            points: number;
            status: number;
            score: number;
          };
        };
      }>;
      course: {
        __typename?: "Course";
        id: string;
        name: string;
        club: {
          __typename?: "Club";
          id: string;
          logoUrl?: string | null;
          webLeaderboardSidebarAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
          webLeaderboardFooterSlotOneAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
          webLeaderboardFooterSlotTwoAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
          webLeaderboardFooterSlotThreeAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
          webLeaderboardFooterSlotFourAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
        };
      };
      teams: Array<{
        __typename?: "Team";
        id: string;
        total: number;
        userRounds: Array<{
          __typename?: "UserRound";
          id: string;
          handicap?: number | null;
          isSubmitted?: boolean | null;
          stats: {
            __typename?: "UserRoundStats";
            id: string;
            score: number;
            points: number;
          };
          userRoundHandicap?: {
            __typename?: "UserRoundHandicap";
            id: string;
            handicapIndex?: number | null;
            isOfficial: boolean;
          } | null;
          user: {
            __typename?: "User";
            id: string;
            avatarUrl: string;
            firstName: string;
            lastName: string;
            gender?: { __typename?: "Gender"; id: string } | null;
            handicapIndex: {
              __typename?: "HandicapIndex";
              userId: string;
              isOfficial: boolean;
              value?: number | null;
            };
          };
          round: { __typename?: "Round"; id: string; startTime: string };
          userScoreCards: Array<{ __typename?: "UserScoreCard"; id: string }>;
        }>;
      }>;
    }>;
  };
};

export type GetTournamentsForLeaderboardQueryVariables = Exact<{
  tournamentIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type GetTournamentsForLeaderboardQuery = {
  __typename?: "Query";
  tournaments: Array<{
    __typename?: "Tournament";
    id: string;
    name: string;
    gameType: {
      __typename?: "GameType";
      id: string;
      description: string;
      teamSize: number;
      scoringMethod: { __typename?: "ScoringMethod"; id: string };
    };
    roundType: { __typename?: "RoundType"; id: string };
    tournamentDivisions: Array<{
      __typename?: "Division";
      id: string;
      name?: string | null;
      description?: string | null;
      minHandicapIndex?: number | null;
      maxHandicapIndex?: number | null;
      determineWinnerBy?: string | null;
      gender?: { __typename?: "Gender"; id: string } | null;
      gameType: {
        __typename?: "GameType";
        id: string;
        description: string;
        teamSize: number;
        scoringMethod: { __typename?: "ScoringMethod"; id: string };
        gameFormat: {
          __typename?: "GameFormat";
          id: string;
          description: string;
        };
      };
    }>;
    settings?: {
      __typename?: "TournamentSettings";
      id: string;
      genderSeparatedDivisions?: boolean | null;
    } | null;
    rounds: Array<{
      __typename?: "Round";
      id: string;
      startTime: string;
      isComplete: boolean;
      latestScores: Array<{
        __typename?: "UserScoreCard";
        id: string;
        score: number;
        points: number;
        dateCreated: string;
        hole: {
          __typename?: "Hole";
          id: string;
          holeNumber: number;
          malePar: number;
          femalePar: number;
        };
        userRound: {
          __typename?: "UserRound";
          id: string;
          handicap?: number | null;
          isSubmitted?: boolean | null;
          isComplete: boolean;
          courseTee?: { __typename?: "CourseTee"; id: string } | null;
          userScoreCards: Array<{
            __typename?: "UserScoreCard";
            id: string;
            dateCreated: string;
            strokeType?: StrokeType | null;
            points: number;
            score: number;
            hole: {
              __typename?: "Hole";
              id: string;
              holeNumber: number;
              par: number;
              malePar: number;
              femalePar: number;
              maleStroke: number;
              femaleStroke: number;
            };
          }>;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
            saPlayerId?: string | null;
            gender?: { __typename?: "Gender"; id: string } | null;
          };
          stats: {
            __typename?: "UserRoundStats";
            id: string;
            points: number;
            status: number;
            score: number;
          };
        };
      }>;
      rareScores: Array<{
        __typename?: "UserScoreCard";
        id: string;
        score: number;
        points: number;
        dateCreated: string;
        hole: {
          __typename?: "Hole";
          id: string;
          holeNumber: number;
          malePar: number;
          femalePar: number;
        };
        userRound: {
          __typename?: "UserRound";
          id: string;
          handicap?: number | null;
          isSubmitted?: boolean | null;
          isComplete: boolean;
          courseTee?: { __typename?: "CourseTee"; id: string } | null;
          userScoreCards: Array<{
            __typename?: "UserScoreCard";
            id: string;
            dateCreated: string;
            strokeType?: StrokeType | null;
            points: number;
            score: number;
            hole: {
              __typename?: "Hole";
              id: string;
              holeNumber: number;
              par: number;
              malePar: number;
              femalePar: number;
              maleStroke: number;
              femaleStroke: number;
            };
          }>;
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: string;
            saPlayerId?: string | null;
            gender?: { __typename?: "Gender"; id: string } | null;
          };
          stats: {
            __typename?: "UserRoundStats";
            id: string;
            points: number;
            status: number;
            score: number;
          };
        };
      }>;
      course: {
        __typename?: "Course";
        id: string;
        name: string;
        club: {
          __typename?: "Club";
          id: string;
          logoUrl?: string | null;
          webLeaderboardSidebarAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
          webLeaderboardFooterSlotOneAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
          webLeaderboardFooterSlotTwoAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
          webLeaderboardFooterSlotThreeAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
          webLeaderboardFooterSlotFourAdvert?: {
            __typename?: "SponsorAdvert";
            description: string;
            id: string;
            imageUrl: string;
            redirectUrl?: string | null;
          } | null;
        };
      };
      teams: Array<{
        __typename?: "Team";
        id: string;
        total: number;
        userRounds: Array<{
          __typename?: "UserRound";
          id: string;
          handicap?: number | null;
          isSubmitted?: boolean | null;
          stats: {
            __typename?: "UserRoundStats";
            id: string;
            score: number;
            points: number;
          };
          userRoundHandicap?: {
            __typename?: "UserRoundHandicap";
            id: string;
            handicapIndex?: number | null;
            isOfficial: boolean;
          } | null;
          user: {
            __typename?: "User";
            id: string;
            avatarUrl: string;
            firstName: string;
            lastName: string;
            gender?: { __typename?: "Gender"; id: string } | null;
            handicapIndex: {
              __typename?: "HandicapIndex";
              userId: string;
              isOfficial: boolean;
              value?: number | null;
            };
          };
          round: { __typename?: "Round"; id: string; startTime: string };
          userScoreCards: Array<{ __typename?: "UserScoreCard"; id: string }>;
        }>;
      }>;
    }>;
  }>;
};

export type GetTournamentToEditQueryVariables = Exact<{
  tournamentId: Scalars["ID"];
}>;

export type GetTournamentToEditQuery = {
  __typename?: "Query";
  tournament: {
    __typename?: "Tournament";
    id: string;
    name: string;
    settings?: {
      __typename?: "TournamentSettings";
      id: string;
      determineWinnerBy?: string | null;
      genderSeparatedDivisions?: boolean | null;
    } | null;
    gameType: { __typename?: "GameType"; id: string; description: string };
    rounds: Array<{
      __typename?: "Round";
      id: string;
      startTime: string;
      inviteCode?: string | null;
      gameType: { __typename?: "GameType"; id: string; description: string };
      course: {
        __typename?: "Course";
        id: string;
        name: string;
        club: { __typename?: "Club"; id: string; name: string };
      };
      userRounds: Array<{ __typename?: "UserRound"; id: string }>;
      settings?: {
        __typename?: "RoundSettings";
        genderSeparatedDivisions: boolean;
      } | null;
      divisions?: Array<{
        __typename?: "Division";
        id: string;
        name?: string | null;
        description?: string | null;
        minHandicapIndex?: number | null;
        maxHandicapIndex?: number | null;
        determineWinnerBy?: string | null;
        gender?: { __typename?: "Gender"; id: string } | null;
        gameType: {
          __typename?: "GameType";
          id: string;
          description: string;
          teamSize: number;
          scoringMethod: { __typename?: "ScoringMethod"; id: string };
          gameFormat: {
            __typename?: "GameFormat";
            id: string;
            description: string;
          };
        };
      } | null> | null;
      roundType: { __typename?: "RoundType"; id: string };
    }>;
    roundType: { __typename?: "RoundType"; id: string };
    tournamentDivisions: Array<{
      __typename?: "Division";
      id: string;
      name?: string | null;
      description?: string | null;
      minHandicapIndex?: number | null;
      maxHandicapIndex?: number | null;
      determineWinnerBy?: string | null;
      gender?: { __typename?: "Gender"; id: string } | null;
      gameType: {
        __typename?: "GameType";
        id: string;
        description: string;
        teamSize: number;
        scoringMethod: { __typename?: "ScoringMethod"; id: string };
        gameFormat: {
          __typename?: "GameFormat";
          id: string;
          description: string;
        };
      };
    }>;
  };
  allGameTypes: Array<{
    __typename?: "GameType";
    id: string;
    description: string;
    scoringMethod: { __typename?: "ScoringMethod"; id: string };
  }>;
};

export type GetTournamentSettingsQueryVariables = Exact<{
  tournamentId: Scalars["ID"];
}>;

export type GetTournamentSettingsQuery = {
  __typename?: "Query";
  tournament: {
    __typename?: "Tournament";
    id: string;
    settings?: {
      __typename?: "TournamentSettings";
      id: string;
      handicapIndexLow?: number | null;
      handicapIndexHigh?: number | null;
      genderSeparatedDivisions?: boolean | null;
      gender?: {
        __typename?: "Gender";
        id: string;
        description: string;
      } | null;
    } | null;
  };
};

export type CreateTournamentSettingsMutationVariables = Exact<{
  input: CreateTournamentSettingsInput;
}>;

export type CreateTournamentSettingsMutation = {
  __typename?: "Mutation";
  createTournamentSettings: {
    __typename?: "TournamentSettings";
    id: string;
    handicapIndexLow?: number | null;
    handicapIndexHigh?: number | null;
    determineWinnerBy?: string | null;
    genderSeparatedDivisions?: boolean | null;
    gender?: { __typename?: "Gender"; id: string } | null;
  };
};

export type UpdateTournamentSettingsMutationVariables = Exact<{
  input: UpdateTournamentSettingsInput;
}>;

export type UpdateTournamentSettingsMutation = {
  __typename?: "Mutation";
  updateTournamentSettings: {
    __typename?: "TournamentSettings";
    id: string;
    handicapIndexLow?: number | null;
    handicapIndexHigh?: number | null;
    determineWinnerBy?: string | null;
    genderSeparatedDivisions?: boolean | null;
    gender?: { __typename?: "Gender"; id: string } | null;
  };
};

export type TournamentViewPartsFragment = {
  __typename?: "TournamentView";
  id: string;
  cycleTime: number;
  tournaments: Array<{ __typename?: "Tournament"; id: string; name: string }>;
};

export type GetTournamentViewByUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetTournamentViewByUserQuery = {
  __typename?: "Query";
  tournamentViewByUser?: {
    __typename?: "TournamentView";
    id: string;
    cycleTime: number;
    tournaments: Array<{ __typename?: "Tournament"; id: string; name: string }>;
  } | null;
};

export type GetTournamentViewByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetTournamentViewByIdQuery = {
  __typename?: "Query";
  tournamentView: {
    __typename?: "TournamentView";
    id: string;
    cycleTime: number;
    tournaments: Array<{ __typename?: "Tournament"; id: string; name: string }>;
  };
};

export type CreateOrUpdateTournamentViewMutationVariables = Exact<{
  input: TournamentViewInput;
}>;

export type CreateOrUpdateTournamentViewMutation = {
  __typename?: "Mutation";
  createOrUpdateTournamentView: {
    __typename?: "TournamentView";
    id: string;
    cycleTime: number;
    tournaments: Array<{ __typename?: "Tournament"; id: string; name: string }>;
  };
};

export type UserPartsFragment = {
  __typename?: "User";
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  userTypeId: string;
  gender?: { __typename?: "Gender"; id: string } | null;
};

export type CurrentUserAndGameTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentUserAndGameTypesQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl: string;
    userTypeId: string;
    club?: {
      __typename?: "Club";
      id: string;
      name: string;
      logoUrl?: string | null;
      courses: Array<{ __typename?: "Course"; id: string; name: string }>;
      settings?: {
        __typename?: "ClubSettings";
        id: string;
        amCutOffTime?: string | null;
        pmCutOffTime?: string | null;
        countOutMethod: string;
      } | null;
    } | null;
    gender?: { __typename?: "Gender"; id: string } | null;
  };
  allGameTypes: Array<{
    __typename?: "GameType";
    id: string;
    details?: string | null;
    description: string;
    scoringMethod: { __typename?: "ScoringMethod"; id: string };
  }>;
};

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type GetUserByEmailQuery = {
  __typename?: "Query";
  getUserByEmail: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
};

export type UpdateUserRegistrationBlacklistStatusMutationVariables = Exact<{
  userRegistrationId: Scalars["ID"];
  isBlackListed: Scalars["Boolean"];
}>;

export type UpdateUserRegistrationBlacklistStatusMutation = {
  __typename?: "Mutation";
  updateUserRegistrationBlacklistStatus: {
    __typename?: "UserRegistration";
    id: string;
    isBlackListed: boolean;
  };
};

export type UserRoundPartsFragment = {
  __typename?: "UserRound";
  id: string;
  handicap?: number | null;
  isSubmitted?: boolean | null;
  isComplete: boolean;
  courseTee?: { __typename?: "CourseTee"; id: string } | null;
  userScoreCards: Array<{
    __typename?: "UserScoreCard";
    id: string;
    dateCreated: string;
    strokeType?: StrokeType | null;
    points: number;
    score: number;
    hole: {
      __typename?: "Hole";
      id: string;
      holeNumber: number;
      par: number;
      malePar: number;
      femalePar: number;
      maleStroke: number;
      femaleStroke: number;
    };
  }>;
  user: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl: string;
    saPlayerId?: string | null;
    gender?: { __typename?: "Gender"; id: string } | null;
  };
  stats: {
    __typename?: "UserRoundStats";
    id: string;
    points: number;
    status: number;
    score: number;
  };
};

export type UserScoreCardsPartsFragment = {
  __typename?: "UserScoreCard";
  id: string;
  dateCreated: string;
  strokeType?: StrokeType | null;
  points: number;
  score: number;
  hole: {
    __typename?: "Hole";
    id: string;
    holeNumber: number;
    par: number;
    malePar: number;
    femalePar: number;
    maleStroke: number;
    femaleStroke: number;
  };
};

export type ScoringGroupPartsFragment = {
  __typename?: "ScoringGroup";
  id: string;
  startingHole: number;
  teams: Array<{
    __typename?: "Team";
    id: string;
    userRounds: Array<{
      __typename?: "UserRound";
      id: string;
      handicap?: number | null;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
        userTypeId: string;
        gender?: { __typename?: "Gender"; id: string } | null;
      };
      course: {
        __typename?: "Course";
        id: string;
        name: string;
        numberOfHoles: number;
        club: {
          __typename?: "Club";
          id: string;
          name: string;
          logoUrl?: string | null;
        };
      };
      courseTee?: {
        __typename?: "CourseTee";
        id: string;
        slope: number;
        rating: number;
        name: string;
        hexCode?: string | null;
        enabled: boolean;
        gender: { __typename?: "Gender"; id: string; description: string };
        colour: { __typename?: "Colour"; id: string; description: string };
      } | null;
      gameType: {
        __typename?: "GameType";
        id: string;
        scoringMethod: { __typename?: "ScoringMethod"; id: string };
      };
      courseScoreCard: {
        __typename?: "CourseScoreCard";
        id: string;
        versionNumber: number;
        activeTo?: string | null;
        activeFrom: string;
        holes: Array<{
          __typename?: "Hole";
          id: string;
          holeNumber: number;
          malePar: number;
          femalePar: number;
          maleStroke: number;
          malePlusStroke?: number | null;
          femaleStroke: number;
          femalePlusStroke?: number | null;
          dateCreated: string;
        }>;
      };
      scoringGroup?: {
        __typename?: "ScoringGroup";
        id: string;
        startingHole: number;
      } | null;
      userScoreCards: Array<{
        __typename?: "UserScoreCard";
        id: string;
        dateCreated: string;
        strokeType?: StrokeType | null;
        points: number;
        score: number;
        hole: {
          __typename?: "Hole";
          id: string;
          holeNumber: number;
          par: number;
          malePar: number;
          femalePar: number;
          maleStroke: number;
          femaleStroke: number;
        };
      }>;
      stats: {
        __typename?: "UserRoundStats";
        id: string;
        points: number;
        score: number;
        status: number;
      };
      team?: {
        __typename?: "Team";
        id: string;
        totals: Array<{
          __typename?: "LeaderboardValue";
          id: string;
          label: string;
          value: string;
        }>;
      } | null;
    }>;
  }>;
};

export type GetBestUsersIpsUserRoundQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBestUsersIpsUserRoundQuery = {
  __typename?: "Query";
  bestUsersIPSUserRound: {
    __typename?: "RoundUserRoundConnection";
    totalCount: number;
    userRounds: Array<{
      __typename?: "UserRound";
      id: string;
      handicap?: number | null;
      isSubmitted?: boolean | null;
      isComplete: boolean;
      round: { __typename?: "Round"; id: string; startTime: string };
      courseTee?: { __typename?: "CourseTee"; id: string } | null;
      userScoreCards: Array<{
        __typename?: "UserScoreCard";
        id: string;
        dateCreated: string;
        strokeType?: StrokeType | null;
        points: number;
        score: number;
        hole: {
          __typename?: "Hole";
          id: string;
          holeNumber: number;
          par: number;
          malePar: number;
          femalePar: number;
          maleStroke: number;
          femaleStroke: number;
        };
      }>;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
        saPlayerId?: string | null;
        gender?: { __typename?: "Gender"; id: string } | null;
      };
      stats: {
        __typename?: "UserRoundStats";
        id: string;
        points: number;
        status: number;
        score: number;
      };
    }>;
  };
};

export type GetUserRoundByUserRoundIdQueryVariables = Exact<{
  userRoundId: Scalars["ID"];
}>;

export type GetUserRoundByUserRoundIdQuery = {
  __typename?: "Query";
  userRound?: {
    __typename?: "UserRound";
    id: string;
    dateCreated: string;
    courseScoreCard: {
      __typename?: "CourseScoreCard";
      id: string;
      holes: Array<{
        __typename?: "Hole";
        id: string;
        holeNumber: number;
        malePar: number;
        malePlusStroke?: number | null;
        maleStroke: number;
        femalePar: number;
        femalePlusStroke?: number | null;
        femaleStroke: number;
      }>;
    };
    gameType: {
      __typename?: "GameType";
      id: string;
      scoringMethod: { __typename?: "ScoringMethod"; id: string };
    };
    userRoundHandicap?: {
      __typename?: "UserRoundHandicap";
      id: string;
      playingHandicap: number;
    } | null;
    round: {
      __typename?: "Round";
      id: string;
      course: {
        __typename?: "Course";
        id: string;
        name: string;
        club: { __typename?: "Club"; id: string; name: string };
      };
      settings?: {
        __typename?: "RoundSettings";
        id: string;
        determineWinnerBy?: string | null;
      } | null;
    };
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      gender?: { __typename?: "Gender"; id: string } | null;
    };
    userScoreCards: Array<{
      __typename?: "UserScoreCard";
      id: string;
      points: number;
      score: number;
      hole: { __typename?: "Hole"; id: string; holeNumber: number };
    }>;
  } | null;
};

export type GetUserRoundToPrintQueryVariables = Exact<{
  userRoundId: Scalars["ID"];
}>;

export type GetUserRoundToPrintQuery = {
  __typename?: "Query";
  userRound?: {
    __typename?: "UserRound";
    id: string;
    handicap?: number | null;
    dateCreated: string;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      gender?: { __typename?: "Gender"; id: string } | null;
    };
    userScoreCards: Array<{
      __typename?: "UserScoreCard";
      id: string;
      points: number;
      score: number;
      hole: { __typename?: "Hole"; id: string; holeNumber: number };
      totals: {
        __typename?: "UserScoreCardTotals";
        nettToPar: number;
        nettToHandicap: number;
      };
    }>;
    courseTee?: { __typename?: "CourseTee"; id: string; name: string } | null;
    userRoundHandicap?: {
      __typename?: "UserRoundHandicap";
      id: string;
      playingHandicap: number;
    } | null;
    courseScoreCard: {
      __typename?: "CourseScoreCard";
      id: string;
      holes: Array<{
        __typename?: "Hole";
        id: string;
        holeNumber: number;
        malePar: number;
        malePlusStroke?: number | null;
        maleStroke: number;
        femalePar: number;
        femalePlusStroke?: number | null;
        femaleStroke: number;
      }>;
    };
    round: {
      __typename?: "Round";
      id: string;
      startTime: string;
      settings?: {
        __typename?: "RoundSettings";
        id: string;
        handicapAllowance: number;
        determineWinnerBy?: string | null;
      } | null;
      gameType: {
        __typename?: "GameType";
        id: string;
        description: string;
        scoringMethod: { __typename?: "ScoringMethod"; id: string };
      };
      course: {
        __typename?: "Course";
        id: string;
        name: string;
        club: {
          __typename?: "Club";
          id: string;
          name: string;
          logoUrl?: string | null;
        };
      };
    };
  } | null;
};

export type CreateOrUpdateUserRoundScoresMutationVariables = Exact<{
  userRoundId: Scalars["ID"];
  scores:
    | Array<CreateOrUpdateUserScoreCardInput>
    | CreateOrUpdateUserScoreCardInput;
}>;

export type CreateOrUpdateUserRoundScoresMutation = {
  __typename?: "Mutation";
  createOrUpdateUserRoundScores: {
    __typename?: "UserRound";
    id: string;
    userScoreCards: Array<{
      __typename?: "UserScoreCard";
      id: string;
      points: number;
      score: number;
      hole: { __typename?: "Hole"; id: string; holeNumber: number };
    }>;
  };
};

export type CreateOrUpdateMultipleUserRoundScoresMutationVariables = Exact<{
  inputs: Array<UpdateUserRoundScoresInput> | UpdateUserRoundScoresInput;
}>;

export type CreateOrUpdateMultipleUserRoundScoresMutation = {
  __typename?: "Mutation";
  createOrUpdateMultipleUserRoundScores: Array<{
    __typename?: "UserRound";
    id: string;
    userScoreCards: Array<{
      __typename?: "UserScoreCard";
      id: string;
      points: number;
      score: number;
      hole: { __typename?: "Hole"; id: string; holeNumber: number };
    }>;
  }>;
};

export type UpdatePlayingHandicapsMutationVariables = Exact<{
  inputs: Array<UpdatePlayingHandicapInput> | UpdatePlayingHandicapInput;
}>;

export type UpdatePlayingHandicapsMutation = {
  __typename?: "Mutation";
  updatePlayingHandicaps: Array<{
    __typename?: "UserRound";
    id: string;
    userRoundHandicap?: {
      __typename?: "UserRoundHandicap";
      id: string;
      playingHandicap: number;
    } | null;
  }>;
};

export type EditPlayingHandicapMutationVariables = Exact<{
  input: EditPlayingHandicapInput;
}>;

export type EditPlayingHandicapMutation = {
  __typename?: "Mutation";
  editPlayingHandicap: {
    __typename?: "UserRound";
    id: string;
    userRoundHandicap?: {
      __typename?: "UserRoundHandicap";
      id: string;
      playingHandicap: number;
    } | null;
    userScoreCards: Array<{
      __typename?: "UserScoreCard";
      id: string;
      points: number;
      score: number;
      hole: { __typename?: "Hole"; id: string; holeNumber: number };
    }>;
  };
};

export const DashboardStatisticPartsFragmentDoc = gql`
  fragment DashboardStatisticParts on DashboardStatistic {
    previousMonth
    currentMonth
  }
`;
export const ClubAdminApplicationPartsFragmentDoc = gql`
  fragment ClubAdminApplicationParts on ClubAdminApplication {
    id
    email
    club {
      id
      name
    }
    firstName
    lastName
    phoneNumber
    firebaseUid
    clubLogoUrl
    clubBannerUrl
    submitted
  }
`;
export const ClubSettingsPartsFragmentDoc = gql`
  fragment ClubSettingsParts on ClubSettings {
    id
    amCutOffTime
    pmCutOffTime
    countOutMethod
  }
`;
export const ClubRoundMutationResultPartsFragmentDoc = gql`
  fragment ClubRoundMutationResultParts on Round {
    id
    startTime
    course {
      id
      name
      club {
        id
        name
      }
    }
    gameType {
      id
      description
    }
    userRounds {
      id
    }
    name
    numberOfHoles
    settings {
      id
      handicapEditAllowed
      leaderboardHoleLimit
      handicapAllowance
      inviteCodeRequired
      field
      cutOffDate
      determineWinnerBy
    }
  }
`;
export const StrokeLeaderboardEntryPartsFragmentDoc = gql`
  fragment strokeLeaderboardEntryParts on StrokeLeaderboardEntry {
    position
    userStrokeOccurrence {
      numOccurrences
      user {
        id
        firstName
        lastName
        avatarUrl
      }
    }
  }
`;
export const RoundStatsPartsFragmentDoc = gql`
  fragment roundStatsParts on RoundStats {
    albatrosses {
      ...strokeLeaderboardEntryParts
    }
    pars {
      ...strokeLeaderboardEntryParts
    }
    birdies {
      ...strokeLeaderboardEntryParts
    }
    holeInOnes {
      ...strokeLeaderboardEntryParts
    }
    eagles {
      ...strokeLeaderboardEntryParts
    }
    bogeys {
      ...strokeLeaderboardEntryParts
    }
    doubleBogeys {
      ...strokeLeaderboardEntryParts
    }
    tripleBogeys {
      ...strokeLeaderboardEntryParts
    }
    blackouts {
      ...strokeLeaderboardEntryParts
    }
  }
  ${StrokeLeaderboardEntryPartsFragmentDoc}
`;
export const HolePerformancePartsFragmentDoc = gql`
  fragment HolePerformanceParts on HolePerformance {
    id
    hole {
      id
      holeNumber
      par
    }
    stroke
    rank
    averageScore
    maxScore
    eagles
    birdies
    pars
    bogeys
    doubleBogeys
    doublePlus
  }
`;
export const DivisionPartsFragmentDoc = gql`
  fragment divisionParts on Division {
    id
    name
    description
    gender {
      id
    }
    gameType {
      id
      description
      teamSize
      scoringMethod {
        id
      }
      gameFormat {
        id
        description
      }
    }
    minHandicapIndex
    maxHandicapIndex
    determineWinnerBy
  }
`;
export const TournamentRoundPartsFragmentDoc = gql`
  fragment TournamentRoundParts on Round {
    id
    startTime
    inviteCode
    gameType {
      id
      description
    }
    course {
      id
      name
      club {
        id
        name
      }
    }
    userRounds {
      id
    }
    settings {
      genderSeparatedDivisions
    }
    divisions {
      ...divisionParts
    }
    roundType {
      id
    }
  }
  ${DivisionPartsFragmentDoc}
`;
export const TournamentPartsFragmentDoc = gql`
  fragment TournamentParts on Tournament {
    id
    name
    gameType {
      id
      description
    }
    rounds {
      ...TournamentRoundParts
    }
    roundType {
      id
    }
    tournamentDivisions {
      ...divisionParts
    }
  }
  ${TournamentRoundPartsFragmentDoc}
  ${DivisionPartsFragmentDoc}
`;
export const UserScoreCardsPartsFragmentDoc = gql`
  fragment UserScoreCardsParts on UserScoreCard {
    id
    dateCreated
    hole {
      id
      holeNumber
      par
      malePar
      femalePar
      maleStroke
      femaleStroke
    }
    strokeType
    points
    score
  }
`;
export const UserRoundPartsFragmentDoc = gql`
  fragment UserRoundParts on UserRound {
    id
    courseTee {
      id
    }
    handicap
    isSubmitted
    isComplete
    userScoreCards {
      ...UserScoreCardsParts
    }
    user {
      id
      firstName
      lastName
      avatarUrl
      saPlayerId
      gender {
        id
      }
    }
    stats {
      id
      points
      status
      score
    }
  }
  ${UserScoreCardsPartsFragmentDoc}
`;
export const UserScoreCardPartsFragmentDoc = gql`
  fragment UserScoreCardParts on UserScoreCard {
    id
    hole {
      id
      holeNumber
      malePar
      femalePar
    }
    score
    points
    userRound {
      ...UserRoundParts
    }
    dateCreated
  }
  ${UserRoundPartsFragmentDoc}
`;
export const AdvertPartsFragmentDoc = gql`
  fragment AdvertParts on SponsorAdvert {
    description
    id
    imageUrl
    redirectUrl
  }
`;
export const TournamentLeaderboardPartsFragmentDoc = gql`
  fragment TournamentLeaderboardParts on Tournament {
    id
    name
    gameType {
      id
      description
      teamSize
      scoringMethod {
        id
      }
    }
    roundType {
      id
    }
    tournamentDivisions {
      ...divisionParts
    }
    settings {
      id
      genderSeparatedDivisions
    }
    rounds {
      id
      startTime
      isComplete
      latestScores {
        ...UserScoreCardParts
      }
      rareScores {
        ...UserScoreCardParts
      }
      course {
        id
        name
        club {
          id
          logoUrl
          webLeaderboardSidebarAdvert: advert(
            slot: WEB_CLUB_LEADERBOARD_SIDEBAR
          ) {
            ...AdvertParts
          }
          webLeaderboardFooterSlotOneAdvert: advert(
            slot: WEB_CLUB_LEADERBOARD_FOOTER_SLOT_ONE
          ) {
            ...AdvertParts
          }
          webLeaderboardFooterSlotTwoAdvert: advert(
            slot: WEB_CLUB_LEADERBOARD_FOOTER_SLOT_TWO
          ) {
            ...AdvertParts
          }
          webLeaderboardFooterSlotThreeAdvert: advert(
            slot: WEB_CLUB_LEADERBOARD_FOOTER_SLOT_THREE
          ) {
            ...AdvertParts
          }
          webLeaderboardFooterSlotFourAdvert: advert(
            slot: WEB_CLUB_LEADERBOARD_FOOTER_SLOT_FOUR
          ) {
            ...AdvertParts
          }
        }
      }
      teams {
        id
        total
        userRounds {
          id
          handicap
          isSubmitted
          stats {
            id
            score
            points
          }
          userRoundHandicap {
            id
            handicapIndex
            isOfficial
          }
          user {
            id
            avatarUrl
            firstName
            lastName
            gender {
              id
            }
            handicapIndex {
              userId
              isOfficial
              value
            }
          }
          round {
            id
            startTime
          }
          userScoreCards {
            id
          }
        }
      }
    }
  }
  ${DivisionPartsFragmentDoc}
  ${UserScoreCardPartsFragmentDoc}
  ${AdvertPartsFragmentDoc}
`;
export const TournamentViewPartsFragmentDoc = gql`
  fragment TournamentViewParts on TournamentView {
    id
    cycleTime
    tournaments {
      id
      name
    }
  }
`;
export const UserPartsFragmentDoc = gql`
  fragment UserParts on User {
    id
    firstName
    lastName
    avatarUrl
    userTypeId
    gender {
      id
    }
  }
`;
export const CoursePartsFragmentDoc = gql`
  fragment CourseParts on Course {
    id
    name
    numberOfHoles
    club {
      id
      name
      logoUrl
    }
  }
`;
export const CourseTeePartsFragmentDoc = gql`
  fragment CourseTeeParts on CourseTee {
    id
    gender {
      id
      description
    }
    slope
    colour {
      id
      description
    }
    rating
    name
    hexCode
    enabled
  }
`;
export const CourseScoreCardPartsFragmentDoc = gql`
  fragment CourseScoreCardParts on CourseScoreCard {
    id
    versionNumber
    activeTo
    activeFrom
    holes {
      id
      holeNumber
      malePar
      femalePar
      maleStroke
      malePlusStroke
      femaleStroke
      femalePlusStroke
      dateCreated
    }
  }
`;
export const ScoringGroupPartsFragmentDoc = gql`
  fragment ScoringGroupParts on ScoringGroup {
    id
    startingHole
    teams {
      id
      userRounds {
        id
        user {
          ...UserParts
          gender {
            id
          }
        }
        course {
          ...CourseParts
        }
        courseTee {
          ...CourseTeeParts
        }
        handicap
        gameType {
          id
          scoringMethod {
            id
          }
        }
        courseScoreCard {
          ...CourseScoreCardParts
        }
        scoringGroup {
          id
          startingHole
        }
        userScoreCards {
          ...UserScoreCardsParts
        }
        stats {
          id
          points
          score
          status
        }
        team {
          id
          totals {
            id
            label
            value
          }
        }
      }
    }
  }
  ${UserPartsFragmentDoc}
  ${CoursePartsFragmentDoc}
  ${CourseTeePartsFragmentDoc}
  ${CourseScoreCardPartsFragmentDoc}
  ${UserScoreCardsPartsFragmentDoc}
`;
export const ClubAdminSignInDocument = gql`
  mutation clubAdminSignIn {
    clubAdminSignIn {
      ...UserParts
      club {
        id
      }
    }
  }
  ${UserPartsFragmentDoc}
`;
export type ClubAdminSignInMutationFn = Apollo.MutationFunction<
  ClubAdminSignInMutation,
  ClubAdminSignInMutationVariables
>;

/**
 * __useClubAdminSignInMutation__
 *
 * To run a mutation, you first call `useClubAdminSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClubAdminSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clubAdminSignInMutation, { data, loading, error }] = useClubAdminSignInMutation({
 *   variables: {
 *   },
 * });
 */
export function useClubAdminSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClubAdminSignInMutation,
    ClubAdminSignInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClubAdminSignInMutation,
    ClubAdminSignInMutationVariables
  >(ClubAdminSignInDocument, options);
}
export type ClubAdminSignInMutationHookResult = ReturnType<
  typeof useClubAdminSignInMutation
>;
export type ClubAdminSignInMutationResult =
  Apollo.MutationResult<ClubAdminSignInMutation>;
export type ClubAdminSignInMutationOptions = Apollo.BaseMutationOptions<
  ClubAdminSignInMutation,
  ClubAdminSignInMutationVariables
>;
export const GetCurrentClubDocument = gql`
  query getCurrentClub {
    currentClub {
      id
      name
      logoUrl
      courses {
        id
        name
      }
    }
  }
`;

/**
 * __useGetCurrentClubQuery__
 *
 * To run a query within a React component, call `useGetCurrentClubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentClubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentClubQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentClubQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentClubQuery,
    GetCurrentClubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentClubQuery, GetCurrentClubQueryVariables>(
    GetCurrentClubDocument,
    options,
  );
}
export function useGetCurrentClubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentClubQuery,
    GetCurrentClubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentClubQuery, GetCurrentClubQueryVariables>(
    GetCurrentClubDocument,
    options,
  );
}
export type GetCurrentClubQueryHookResult = ReturnType<
  typeof useGetCurrentClubQuery
>;
export type GetCurrentClubLazyQueryHookResult = ReturnType<
  typeof useGetCurrentClubLazyQuery
>;
export type GetCurrentClubQueryResult = Apollo.QueryResult<
  GetCurrentClubQuery,
  GetCurrentClubQueryVariables
>;
export const GetClubDashboardDocument = gql`
  query getClubDashboard {
    currentClub {
      id
      name
      logoUrl
      courses {
        id
        name
      }
      dashboard {
        numberOfMonthlyPlayers {
          ...DashboardStatisticParts
        }
        totalNumberOfPlayers {
          ...DashboardStatisticParts
        }
        numberOfTournaments {
          ...DashboardStatisticParts
        }
        numberOfCompetitions {
          ...DashboardStatisticParts
        }
        numberOfMonthlyRounds {
          ...DashboardStatisticParts
        }
        totalNumberOfRounds {
          ...DashboardStatisticParts
        }
        leaderboard {
          id
          firstName
          lastName
          points
          pointRank
        }
        monthlyRoundsSummary {
          currentMonth {
            id
            startTime
          }
          previousMonth {
            id
            startTime
          }
        }
      }
    }
  }
  ${DashboardStatisticPartsFragmentDoc}
`;

/**
 * __useGetClubDashboardQuery__
 *
 * To run a query within a React component, call `useGetClubDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClubDashboardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClubDashboardQuery,
    GetClubDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClubDashboardQuery, GetClubDashboardQueryVariables>(
    GetClubDashboardDocument,
    options,
  );
}
export function useGetClubDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubDashboardQuery,
    GetClubDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClubDashboardQuery,
    GetClubDashboardQueryVariables
  >(GetClubDashboardDocument, options);
}
export type GetClubDashboardQueryHookResult = ReturnType<
  typeof useGetClubDashboardQuery
>;
export type GetClubDashboardLazyQueryHookResult = ReturnType<
  typeof useGetClubDashboardLazyQuery
>;
export type GetClubDashboardQueryResult = Apollo.QueryResult<
  GetClubDashboardQuery,
  GetClubDashboardQueryVariables
>;
export const GetClubSettingsDocument = gql`
  query getClubSettings {
    currentClub {
      id
      settings {
        ...ClubSettingsParts
      }
    }
  }
  ${ClubSettingsPartsFragmentDoc}
`;

/**
 * __useGetClubSettingsQuery__
 *
 * To run a query within a React component, call `useGetClubSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClubSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClubSettingsQuery,
    GetClubSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClubSettingsQuery, GetClubSettingsQueryVariables>(
    GetClubSettingsDocument,
    options,
  );
}
export function useGetClubSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubSettingsQuery,
    GetClubSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClubSettingsQuery,
    GetClubSettingsQueryVariables
  >(GetClubSettingsDocument, options);
}
export type GetClubSettingsQueryHookResult = ReturnType<
  typeof useGetClubSettingsQuery
>;
export type GetClubSettingsLazyQueryHookResult = ReturnType<
  typeof useGetClubSettingsLazyQuery
>;
export type GetClubSettingsQueryResult = Apollo.QueryResult<
  GetClubSettingsQuery,
  GetClubSettingsQueryVariables
>;
export const GetCurrentClubCoursesDocument = gql`
  query getCurrentClubCourses {
    currentClub {
      id
      courses {
        id
        name
        courseTees {
          id
          name
          gender {
            id
            description
          }
        }
      }
    }
  }
`;

/**
 * __useGetCurrentClubCoursesQuery__
 *
 * To run a query within a React component, call `useGetCurrentClubCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentClubCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentClubCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentClubCoursesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentClubCoursesQuery,
    GetCurrentClubCoursesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentClubCoursesQuery,
    GetCurrentClubCoursesQueryVariables
  >(GetCurrentClubCoursesDocument, options);
}
export function useGetCurrentClubCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentClubCoursesQuery,
    GetCurrentClubCoursesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentClubCoursesQuery,
    GetCurrentClubCoursesQueryVariables
  >(GetCurrentClubCoursesDocument, options);
}
export type GetCurrentClubCoursesQueryHookResult = ReturnType<
  typeof useGetCurrentClubCoursesQuery
>;
export type GetCurrentClubCoursesLazyQueryHookResult = ReturnType<
  typeof useGetCurrentClubCoursesLazyQuery
>;
export type GetCurrentClubCoursesQueryResult = Apollo.QueryResult<
  GetCurrentClubCoursesQuery,
  GetCurrentClubCoursesQueryVariables
>;
export const ClubSearchDocument = gql`
  query clubSearch($clubNameSearch: String!, $filter: ClubSearchFilterInput) {
    clubSearch(clubNameSearch: $clubNameSearch, filter: $filter) {
      id
      name
    }
  }
`;

/**
 * __useClubSearchQuery__
 *
 * To run a query within a React component, call `useClubSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useClubSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClubSearchQuery({
 *   variables: {
 *      clubNameSearch: // value for 'clubNameSearch'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useClubSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClubSearchQuery,
    ClubSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClubSearchQuery, ClubSearchQueryVariables>(
    ClubSearchDocument,
    options,
  );
}
export function useClubSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClubSearchQuery,
    ClubSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClubSearchQuery, ClubSearchQueryVariables>(
    ClubSearchDocument,
    options,
  );
}
export type ClubSearchQueryHookResult = ReturnType<typeof useClubSearchQuery>;
export type ClubSearchLazyQueryHookResult = ReturnType<
  typeof useClubSearchLazyQuery
>;
export type ClubSearchQueryResult = Apollo.QueryResult<
  ClubSearchQuery,
  ClubSearchQueryVariables
>;
export const GetClubRoundsInProgressDocument = gql`
  query getClubRoundsInProgress($clubId: ID!) {
    club(clubId: $clubId) {
      id
      name
      currentClubRounds {
        id
        startTime
        name
        course {
          id
          name
        }
        settings {
          id
          field
        }
        gameType {
          id
          description
        }
      }
    }
  }
`;

/**
 * __useGetClubRoundsInProgressQuery__
 *
 * To run a query within a React component, call `useGetClubRoundsInProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubRoundsInProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubRoundsInProgressQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useGetClubRoundsInProgressQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubRoundsInProgressQuery,
    GetClubRoundsInProgressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClubRoundsInProgressQuery,
    GetClubRoundsInProgressQueryVariables
  >(GetClubRoundsInProgressDocument, options);
}
export function useGetClubRoundsInProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubRoundsInProgressQuery,
    GetClubRoundsInProgressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClubRoundsInProgressQuery,
    GetClubRoundsInProgressQueryVariables
  >(GetClubRoundsInProgressDocument, options);
}
export type GetClubRoundsInProgressQueryHookResult = ReturnType<
  typeof useGetClubRoundsInProgressQuery
>;
export type GetClubRoundsInProgressLazyQueryHookResult = ReturnType<
  typeof useGetClubRoundsInProgressLazyQuery
>;
export type GetClubRoundsInProgressQueryResult = Apollo.QueryResult<
  GetClubRoundsInProgressQuery,
  GetClubRoundsInProgressQueryVariables
>;
export const GetClubAdminApplicationByEmailDocument = gql`
  query GetClubAdminApplicationByEmail($email: String!) {
    clubAdminApplicationByEmail(email: $email) {
      ...ClubAdminApplicationParts
    }
  }
  ${ClubAdminApplicationPartsFragmentDoc}
`;

/**
 * __useGetClubAdminApplicationByEmailQuery__
 *
 * To run a query within a React component, call `useGetClubAdminApplicationByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubAdminApplicationByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubAdminApplicationByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetClubAdminApplicationByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubAdminApplicationByEmailQuery,
    GetClubAdminApplicationByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClubAdminApplicationByEmailQuery,
    GetClubAdminApplicationByEmailQueryVariables
  >(GetClubAdminApplicationByEmailDocument, options);
}
export function useGetClubAdminApplicationByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubAdminApplicationByEmailQuery,
    GetClubAdminApplicationByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClubAdminApplicationByEmailQuery,
    GetClubAdminApplicationByEmailQueryVariables
  >(GetClubAdminApplicationByEmailDocument, options);
}
export type GetClubAdminApplicationByEmailQueryHookResult = ReturnType<
  typeof useGetClubAdminApplicationByEmailQuery
>;
export type GetClubAdminApplicationByEmailLazyQueryHookResult = ReturnType<
  typeof useGetClubAdminApplicationByEmailLazyQuery
>;
export type GetClubAdminApplicationByEmailQueryResult = Apollo.QueryResult<
  GetClubAdminApplicationByEmailQuery,
  GetClubAdminApplicationByEmailQueryVariables
>;
export const UpdateClubAdminApplicationDocument = gql`
  mutation UpdateClubAdminApplication(
    $input: UpdateClubAdminApplicationInput!
  ) {
    updateClubAdminApplication(input: $input) {
      ...ClubAdminApplicationParts
    }
  }
  ${ClubAdminApplicationPartsFragmentDoc}
`;
export type UpdateClubAdminApplicationMutationFn = Apollo.MutationFunction<
  UpdateClubAdminApplicationMutation,
  UpdateClubAdminApplicationMutationVariables
>;

/**
 * __useUpdateClubAdminApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateClubAdminApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClubAdminApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClubAdminApplicationMutation, { data, loading, error }] = useUpdateClubAdminApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClubAdminApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClubAdminApplicationMutation,
    UpdateClubAdminApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClubAdminApplicationMutation,
    UpdateClubAdminApplicationMutationVariables
  >(UpdateClubAdminApplicationDocument, options);
}
export type UpdateClubAdminApplicationMutationHookResult = ReturnType<
  typeof useUpdateClubAdminApplicationMutation
>;
export type UpdateClubAdminApplicationMutationResult =
  Apollo.MutationResult<UpdateClubAdminApplicationMutation>;
export type UpdateClubAdminApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateClubAdminApplicationMutation,
    UpdateClubAdminApplicationMutationVariables
  >;
export const CreateOrUpdateClubSettingsDocument = gql`
  mutation createOrUpdateClubSettings($input: ClubSettingsInput!) {
    createOrUpdateClubSettings(input: $input) {
      id
      club {
        id
        settings {
          ...ClubSettingsParts
        }
      }
    }
  }
  ${ClubSettingsPartsFragmentDoc}
`;
export type CreateOrUpdateClubSettingsMutationFn = Apollo.MutationFunction<
  CreateOrUpdateClubSettingsMutation,
  CreateOrUpdateClubSettingsMutationVariables
>;

/**
 * __useCreateOrUpdateClubSettingsMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateClubSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateClubSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateClubSettingsMutation, { data, loading, error }] = useCreateOrUpdateClubSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateClubSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateClubSettingsMutation,
    CreateOrUpdateClubSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateClubSettingsMutation,
    CreateOrUpdateClubSettingsMutationVariables
  >(CreateOrUpdateClubSettingsDocument, options);
}
export type CreateOrUpdateClubSettingsMutationHookResult = ReturnType<
  typeof useCreateOrUpdateClubSettingsMutation
>;
export type CreateOrUpdateClubSettingsMutationResult =
  Apollo.MutationResult<CreateOrUpdateClubSettingsMutation>;
export type CreateOrUpdateClubSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrUpdateClubSettingsMutation,
    CreateOrUpdateClubSettingsMutationVariables
  >;
export const GetClubCoursesDocument = gql`
  query getClubCourses {
    currentClub {
      id
      courses {
        ...CourseParts
      }
    }
  }
  ${CoursePartsFragmentDoc}
`;

/**
 * __useGetClubCoursesQuery__
 *
 * To run a query within a React component, call `useGetClubCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClubCoursesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClubCoursesQuery,
    GetClubCoursesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClubCoursesQuery, GetClubCoursesQueryVariables>(
    GetClubCoursesDocument,
    options,
  );
}
export function useGetClubCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubCoursesQuery,
    GetClubCoursesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClubCoursesQuery, GetClubCoursesQueryVariables>(
    GetClubCoursesDocument,
    options,
  );
}
export type GetClubCoursesQueryHookResult = ReturnType<
  typeof useGetClubCoursesQuery
>;
export type GetClubCoursesLazyQueryHookResult = ReturnType<
  typeof useGetClubCoursesLazyQuery
>;
export type GetClubCoursesQueryResult = Apollo.QueryResult<
  GetClubCoursesQuery,
  GetClubCoursesQueryVariables
>;
export const GetCourseIpsStandingsDocument = gql`
  query getCourseIPSStandings($id: ID!) {
    course(id: $id) {
      ...CourseParts
      ipsStandings {
        id
        firstName
        lastName
        points
        pointRank
      }
      monthlyStats {
        lastMonthsWinner {
          id
          firstName
          lastName
          avatarUrl
          points
        }
        rareScores {
          ...UserScoreCardParts
        }
        strokeOccurrences {
          albatrosses
          holeInOnes
          eagles
          birdies
          pars
          bogeys
          doubleBogeys
          tripleBogeys
          blackouts
        }
        roundsPlayed
        uniquePlayers
      }
    }
  }
  ${CoursePartsFragmentDoc}
  ${UserScoreCardPartsFragmentDoc}
`;

/**
 * __useGetCourseIpsStandingsQuery__
 *
 * To run a query within a React component, call `useGetCourseIpsStandingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseIpsStandingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseIpsStandingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseIpsStandingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseIpsStandingsQuery,
    GetCourseIpsStandingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseIpsStandingsQuery,
    GetCourseIpsStandingsQueryVariables
  >(GetCourseIpsStandingsDocument, options);
}
export function useGetCourseIpsStandingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseIpsStandingsQuery,
    GetCourseIpsStandingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseIpsStandingsQuery,
    GetCourseIpsStandingsQueryVariables
  >(GetCourseIpsStandingsDocument, options);
}
export type GetCourseIpsStandingsQueryHookResult = ReturnType<
  typeof useGetCourseIpsStandingsQuery
>;
export type GetCourseIpsStandingsLazyQueryHookResult = ReturnType<
  typeof useGetCourseIpsStandingsLazyQuery
>;
export type GetCourseIpsStandingsQueryResult = Apollo.QueryResult<
  GetCourseIpsStandingsQuery,
  GetCourseIpsStandingsQueryVariables
>;
export const GetCoursePerformanceDocument = gql`
  query getCoursePerformance($input: CoursePerformanceInput!) {
    coursePerformance(input: $input) {
      id
      holePerformance {
        ...HolePerformanceParts
      }
      rounds {
        id
      }
    }
  }
  ${HolePerformancePartsFragmentDoc}
`;

/**
 * __useGetCoursePerformanceQuery__
 *
 * To run a query within a React component, call `useGetCoursePerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursePerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursePerformanceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCoursePerformanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCoursePerformanceQuery,
    GetCoursePerformanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCoursePerformanceQuery,
    GetCoursePerformanceQueryVariables
  >(GetCoursePerformanceDocument, options);
}
export function useGetCoursePerformanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoursePerformanceQuery,
    GetCoursePerformanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCoursePerformanceQuery,
    GetCoursePerformanceQueryVariables
  >(GetCoursePerformanceDocument, options);
}
export type GetCoursePerformanceQueryHookResult = ReturnType<
  typeof useGetCoursePerformanceQuery
>;
export type GetCoursePerformanceLazyQueryHookResult = ReturnType<
  typeof useGetCoursePerformanceLazyQuery
>;
export type GetCoursePerformanceQueryResult = Apollo.QueryResult<
  GetCoursePerformanceQuery,
  GetCoursePerformanceQueryVariables
>;
export const GetCourseScoreCardsDocument = gql`
  query getCourseScoreCards($id: ID!) {
    course(id: $id) {
      id
      name
      numberOfHoles
      courseScoreCards {
        ...CourseScoreCardParts
      }
    }
  }
  ${CourseScoreCardPartsFragmentDoc}
`;

/**
 * __useGetCourseScoreCardsQuery__
 *
 * To run a query within a React component, call `useGetCourseScoreCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseScoreCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseScoreCardsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseScoreCardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseScoreCardsQuery,
    GetCourseScoreCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseScoreCardsQuery,
    GetCourseScoreCardsQueryVariables
  >(GetCourseScoreCardsDocument, options);
}
export function useGetCourseScoreCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseScoreCardsQuery,
    GetCourseScoreCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseScoreCardsQuery,
    GetCourseScoreCardsQueryVariables
  >(GetCourseScoreCardsDocument, options);
}
export type GetCourseScoreCardsQueryHookResult = ReturnType<
  typeof useGetCourseScoreCardsQuery
>;
export type GetCourseScoreCardsLazyQueryHookResult = ReturnType<
  typeof useGetCourseScoreCardsLazyQuery
>;
export type GetCourseScoreCardsQueryResult = Apollo.QueryResult<
  GetCourseScoreCardsQuery,
  GetCourseScoreCardsQueryVariables
>;
export const CreateCourseScoreCardVersionDocument = gql`
  mutation createCourseScoreCardVersion($input: CreateCourseScoreCardInput!) {
    createCourseScoreCardVersion(input: $input) {
      ...CourseScoreCardParts
    }
  }
  ${CourseScoreCardPartsFragmentDoc}
`;
export type CreateCourseScoreCardVersionMutationFn = Apollo.MutationFunction<
  CreateCourseScoreCardVersionMutation,
  CreateCourseScoreCardVersionMutationVariables
>;

/**
 * __useCreateCourseScoreCardVersionMutation__
 *
 * To run a mutation, you first call `useCreateCourseScoreCardVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseScoreCardVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseScoreCardVersionMutation, { data, loading, error }] = useCreateCourseScoreCardVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseScoreCardVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCourseScoreCardVersionMutation,
    CreateCourseScoreCardVersionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCourseScoreCardVersionMutation,
    CreateCourseScoreCardVersionMutationVariables
  >(CreateCourseScoreCardVersionDocument, options);
}
export type CreateCourseScoreCardVersionMutationHookResult = ReturnType<
  typeof useCreateCourseScoreCardVersionMutation
>;
export type CreateCourseScoreCardVersionMutationResult =
  Apollo.MutationResult<CreateCourseScoreCardVersionMutation>;
export type CreateCourseScoreCardVersionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCourseScoreCardVersionMutation,
    CreateCourseScoreCardVersionMutationVariables
  >;
export const GetCourseTeesDocument = gql`
  query getCourseTees($id: ID!, $filters: CourseTeeFilters) {
    course(id: $id) {
      id
      name
      courseTees(filters: $filters) {
        ...CourseTeeParts
      }
    }
  }
  ${CourseTeePartsFragmentDoc}
`;

/**
 * __useGetCourseTeesQuery__
 *
 * To run a query within a React component, call `useGetCourseTeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseTeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseTeesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCourseTeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseTeesQuery,
    GetCourseTeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseTeesQuery, GetCourseTeesQueryVariables>(
    GetCourseTeesDocument,
    options,
  );
}
export function useGetCourseTeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseTeesQuery,
    GetCourseTeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCourseTeesQuery, GetCourseTeesQueryVariables>(
    GetCourseTeesDocument,
    options,
  );
}
export type GetCourseTeesQueryHookResult = ReturnType<
  typeof useGetCourseTeesQuery
>;
export type GetCourseTeesLazyQueryHookResult = ReturnType<
  typeof useGetCourseTeesLazyQuery
>;
export type GetCourseTeesQueryResult = Apollo.QueryResult<
  GetCourseTeesQuery,
  GetCourseTeesQueryVariables
>;
export const CreateOrUpdateCourseTeesDocument = gql`
  mutation createOrUpdateCourseTees($input: CreateOrUpdateCourseTeesInput!) {
    createOrUpdateCourseTees(input: $input) {
      ...CourseTeeParts
    }
  }
  ${CourseTeePartsFragmentDoc}
`;
export type CreateOrUpdateCourseTeesMutationFn = Apollo.MutationFunction<
  CreateOrUpdateCourseTeesMutation,
  CreateOrUpdateCourseTeesMutationVariables
>;

/**
 * __useCreateOrUpdateCourseTeesMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCourseTeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCourseTeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCourseTeesMutation, { data, loading, error }] = useCreateOrUpdateCourseTeesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateCourseTeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateCourseTeesMutation,
    CreateOrUpdateCourseTeesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateCourseTeesMutation,
    CreateOrUpdateCourseTeesMutationVariables
  >(CreateOrUpdateCourseTeesDocument, options);
}
export type CreateOrUpdateCourseTeesMutationHookResult = ReturnType<
  typeof useCreateOrUpdateCourseTeesMutation
>;
export type CreateOrUpdateCourseTeesMutationResult =
  Apollo.MutationResult<CreateOrUpdateCourseTeesMutation>;
export type CreateOrUpdateCourseTeesMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrUpdateCourseTeesMutation,
    CreateOrUpdateCourseTeesMutationVariables
  >;
export const AvailableTournamentsDocument = gql`
  query availableTournaments($eventId: ID!) {
    availableTournaments(eventId: $eventId) {
      id
      name
      settings {
        id
        handicapIndexLow
        handicapIndexHigh
        gender {
          id
          description
        }
      }
    }
  }
`;

/**
 * __useAvailableTournamentsQuery__
 *
 * To run a query within a React component, call `useAvailableTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableTournamentsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useAvailableTournamentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableTournamentsQuery,
    AvailableTournamentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableTournamentsQuery,
    AvailableTournamentsQueryVariables
  >(AvailableTournamentsDocument, options);
}
export function useAvailableTournamentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableTournamentsQuery,
    AvailableTournamentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableTournamentsQuery,
    AvailableTournamentsQueryVariables
  >(AvailableTournamentsDocument, options);
}
export type AvailableTournamentsQueryHookResult = ReturnType<
  typeof useAvailableTournamentsQuery
>;
export type AvailableTournamentsLazyQueryHookResult = ReturnType<
  typeof useAvailableTournamentsLazyQuery
>;
export type AvailableTournamentsQueryResult = Apollo.QueryResult<
  AvailableTournamentsQuery,
  AvailableTournamentsQueryVariables
>;
export const EventDocument = gql`
  query event($id: ID!) {
    event(id: $id) {
      id
      name
      status
      isSeedComplete
      registrationOpen
      registrationClose
      handicapLockDate
      registrations {
        id
        user {
          id
          firstName
          lastName
          saPlayerId
          avatarUrl
        }
        isBlackListed
        handicapIndex
      }
      tournaments {
        id
        name
        settings {
          id
          handicapIndexLow
          handicapIndexHigh
          gender {
            id
            description
          }
        }
        rounds {
          id
          registrations {
            id
            user {
              id
              firstName
              lastName
              saPlayerId
            }
            handicapIndex
          }
        }
      }
    }
  }
`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(
  baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventQuery, EventQueryVariables>(
    EventDocument,
    options,
  );
}
export function useEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(
    EventDocument,
    options,
  );
}
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<
  EventQuery,
  EventQueryVariables
>;
export const EventsDocument = gql`
  query events($filter: EventFilter!) {
    events(filter: $filter) {
      id
      isSeedComplete
      name
      status
      registrationOpen
      registrationClose
      registrations {
        id
        user {
          id
          firstName
          lastName
        }
        isBlackListed
      }
      tournaments {
        id
        rounds {
          id
        }
      }
    }
  }
`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEventsQuery(
  baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    options,
  );
}
export function useEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    options,
  );
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<
  EventsQuery,
  EventsQueryVariables
>;
export const CreateEventDocument = gql`
  mutation createEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      name
      registrationOpen
      registrationClose
      isSeedComplete
    }
  }
`;
export type CreateEventMutationFn = Apollo.MutationFunction<
  CreateEventMutation,
  CreateEventMutationVariables
>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventMutation,
    CreateEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(
    CreateEventDocument,
    options,
  );
}
export type CreateEventMutationHookResult = ReturnType<
  typeof useCreateEventMutation
>;
export type CreateEventMutationResult =
  Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<
  CreateEventMutation,
  CreateEventMutationVariables
>;
export const CreateEventTournamentDocument = gql`
  mutation createEventTournament($eventId: ID!, $tournamentId: ID!) {
    createEventTournament(eventId: $eventId, tournamentId: $tournamentId) {
      id
      tournaments {
        id
        name
        settings {
          id
          handicapIndexLow
          handicapIndexHigh
          gender {
            id
            description
          }
        }
        rounds {
          id
        }
      }
    }
  }
`;
export type CreateEventTournamentMutationFn = Apollo.MutationFunction<
  CreateEventTournamentMutation,
  CreateEventTournamentMutationVariables
>;

/**
 * __useCreateEventTournamentMutation__
 *
 * To run a mutation, you first call `useCreateEventTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventTournamentMutation, { data, loading, error }] = useCreateEventTournamentMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useCreateEventTournamentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventTournamentMutation,
    CreateEventTournamentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEventTournamentMutation,
    CreateEventTournamentMutationVariables
  >(CreateEventTournamentDocument, options);
}
export type CreateEventTournamentMutationHookResult = ReturnType<
  typeof useCreateEventTournamentMutation
>;
export type CreateEventTournamentMutationResult =
  Apollo.MutationResult<CreateEventTournamentMutation>;
export type CreateEventTournamentMutationOptions = Apollo.BaseMutationOptions<
  CreateEventTournamentMutation,
  CreateEventTournamentMutationVariables
>;
export const DeleteEventTournamentDocument = gql`
  mutation deleteEventTournament($eventId: ID!, $tournamentId: ID!) {
    deleteEventTournament(eventId: $eventId, tournamentId: $tournamentId) {
      id
      tournaments {
        id
        name
        settings {
          id
          handicapIndexLow
          handicapIndexHigh
          gender {
            id
            description
          }
        }
      }
    }
  }
`;
export type DeleteEventTournamentMutationFn = Apollo.MutationFunction<
  DeleteEventTournamentMutation,
  DeleteEventTournamentMutationVariables
>;

/**
 * __useDeleteEventTournamentMutation__
 *
 * To run a mutation, you first call `useDeleteEventTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventTournamentMutation, { data, loading, error }] = useDeleteEventTournamentMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useDeleteEventTournamentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEventTournamentMutation,
    DeleteEventTournamentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEventTournamentMutation,
    DeleteEventTournamentMutationVariables
  >(DeleteEventTournamentDocument, options);
}
export type DeleteEventTournamentMutationHookResult = ReturnType<
  typeof useDeleteEventTournamentMutation
>;
export type DeleteEventTournamentMutationResult =
  Apollo.MutationResult<DeleteEventTournamentMutation>;
export type DeleteEventTournamentMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventTournamentMutation,
  DeleteEventTournamentMutationVariables
>;
export const DeleteEventDocument = gql`
  mutation deleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      id
    }
  }
`;
export type DeleteEventMutationFn = Apollo.MutationFunction<
  DeleteEventMutation,
  DeleteEventMutationVariables
>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEventMutation,
    DeleteEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(
    DeleteEventDocument,
    options,
  );
}
export type DeleteEventMutationHookResult = ReturnType<
  typeof useDeleteEventMutation
>;
export type DeleteEventMutationResult =
  Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventMutation,
  DeleteEventMutationVariables
>;
export const SeedEventDocument = gql`
  mutation seedEvent($eventId: ID!) {
    seedEvent(eventId: $eventId) {
      id
      isSeedComplete
      registrations {
        id
        handicapIndex
      }
    }
  }
`;
export type SeedEventMutationFn = Apollo.MutationFunction<
  SeedEventMutation,
  SeedEventMutationVariables
>;

/**
 * __useSeedEventMutation__
 *
 * To run a mutation, you first call `useSeedEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeedEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seedEventMutation, { data, loading, error }] = useSeedEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useSeedEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SeedEventMutation,
    SeedEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SeedEventMutation, SeedEventMutationVariables>(
    SeedEventDocument,
    options,
  );
}
export type SeedEventMutationHookResult = ReturnType<
  typeof useSeedEventMutation
>;
export type SeedEventMutationResult = Apollo.MutationResult<SeedEventMutation>;
export type SeedEventMutationOptions = Apollo.BaseMutationOptions<
  SeedEventMutation,
  SeedEventMutationVariables
>;
export const UpdateEventStatusDocument = gql`
  mutation updateEventStatus($eventId: ID!, $status: EventStatus!) {
    updateEventStatus(eventId: $eventId, status: $status) {
      id
      status
    }
  }
`;
export type UpdateEventStatusMutationFn = Apollo.MutationFunction<
  UpdateEventStatusMutation,
  UpdateEventStatusMutationVariables
>;

/**
 * __useUpdateEventStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEventStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventStatusMutation, { data, loading, error }] = useUpdateEventStatusMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateEventStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventStatusMutation,
    UpdateEventStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEventStatusMutation,
    UpdateEventStatusMutationVariables
  >(UpdateEventStatusDocument, options);
}
export type UpdateEventStatusMutationHookResult = ReturnType<
  typeof useUpdateEventStatusMutation
>;
export type UpdateEventStatusMutationResult =
  Apollo.MutationResult<UpdateEventStatusMutation>;
export type UpdateEventStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventStatusMutation,
  UpdateEventStatusMutationVariables
>;
export const CheckHandicapsDocument = gql`
  mutation checkHandicaps($eventId: ID!) {
    checkHandicaps(eventId: $eventId) {
      id
      registrations {
        id
        handicapIndex
      }
    }
  }
`;
export type CheckHandicapsMutationFn = Apollo.MutationFunction<
  CheckHandicapsMutation,
  CheckHandicapsMutationVariables
>;

/**
 * __useCheckHandicapsMutation__
 *
 * To run a mutation, you first call `useCheckHandicapsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckHandicapsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkHandicapsMutation, { data, loading, error }] = useCheckHandicapsMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useCheckHandicapsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckHandicapsMutation,
    CheckHandicapsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CheckHandicapsMutation,
    CheckHandicapsMutationVariables
  >(CheckHandicapsDocument, options);
}
export type CheckHandicapsMutationHookResult = ReturnType<
  typeof useCheckHandicapsMutation
>;
export type CheckHandicapsMutationResult =
  Apollo.MutationResult<CheckHandicapsMutation>;
export type CheckHandicapsMutationOptions = Apollo.BaseMutationOptions<
  CheckHandicapsMutation,
  CheckHandicapsMutationVariables
>;
export const GetRoundByIdDocument = gql`
  query getRoundById($id: ID!) {
    round(id: $id) {
      id
      startTime
      inviteCode
      isComplete
      roundStats {
        ...roundStatsParts
      }
      course {
        id
        name
        club {
          id
          name
          logoUrl
          bannerUrl
        }
      }
      gameType {
        id
        description
        gameFormat {
          id
        }
        scoringMethod {
          id
        }
        teamSize
      }
      name
      numberOfHoles
      totals {
        numberOfPlayers
      }
      userRounds {
        id
        isSubmitted
        isComplete
        userRoundHandicap {
          id
          courseHandicap
          playingHandicap
          handicapIndex
          isOfficial
        }
        user {
          id
          firstName
          lastName
          saPlayerId
          handicapIndex {
            userId
            isOfficial
            value
          }
        }
        stats {
          score
          points
        }
        courseTee {
          id
          name
        }
        userScoreCards {
          id
        }
      }
      settings {
        id
        determineWinnerBy
        genderSeparatedDivisions
      }
      roundType {
        id
      }
      divisions {
        ...divisionParts
      }
    }
    individualLeaderboard(roundId: $id) {
      id
      isCountOut
      points
      rank
      score
      thru
      userRound {
        id
        handicap
        userRoundTeams {
          id
          teamId
        }
        userRoundHandicap {
          id
          handicapIndex
          playingHandicap
          courseHandicap
          isOfficial
        }
        user {
          id
          firstName
          lastName
          saPlayerId
          gender {
            id
          }
        }
        courseTee {
          id
          name
        }
        scoringGroup {
          id
        }
      }
      totals {
        gross
        nettToPar
        nettToHandicap
      }
    }
    teamLeaderboard(roundId: $id) {
      id
      isCountOut
      rank
      total
      thru
      userRounds {
        id
        user {
          id
          firstName
          lastName
        }
        scoringGroup {
          id
          userRounds {
            id
            user {
              id
              firstName
              lastName
            }
            userRoundHandicap {
              id
              playingHandicap
            }
            userRoundTeams {
              id
              teamId
            }
          }
        }
        userRoundTeams {
          id
          teamId
          isSpecial
        }
      }
      totals {
        gross
        nettToPar
        nettToHandicap
      }
    }
    roundPerformance(roundId: $id) {
      id
      holePerformance {
        ...HolePerformanceParts
      }
    }
  }
  ${RoundStatsPartsFragmentDoc}
  ${DivisionPartsFragmentDoc}
  ${HolePerformancePartsFragmentDoc}
`;

/**
 * __useGetRoundByIdQuery__
 *
 * To run a query within a React component, call `useGetRoundByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoundByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoundByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoundByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoundByIdQuery,
    GetRoundByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoundByIdQuery, GetRoundByIdQueryVariables>(
    GetRoundByIdDocument,
    options,
  );
}
export function useGetRoundByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoundByIdQuery,
    GetRoundByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoundByIdQuery, GetRoundByIdQueryVariables>(
    GetRoundByIdDocument,
    options,
  );
}
export type GetRoundByIdQueryHookResult = ReturnType<
  typeof useGetRoundByIdQuery
>;
export type GetRoundByIdLazyQueryHookResult = ReturnType<
  typeof useGetRoundByIdLazyQuery
>;
export type GetRoundByIdQueryResult = Apollo.QueryResult<
  GetRoundByIdQuery,
  GetRoundByIdQueryVariables
>;
export const ClubCompsDocument = gql`
  query ClubComps($filter: ClubRoundsFilter) {
    clubRounds(filter: $filter) {
      rounds {
        id
        name
        startTime
        gameType {
          id
          description
        }
        course {
          id
          name
        }
        settings {
          id
          field
        }
      }
      count
    }
  }
`;

/**
 * __useClubCompsQuery__
 *
 * To run a query within a React component, call `useClubCompsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClubCompsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClubCompsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useClubCompsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ClubCompsQuery,
    ClubCompsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClubCompsQuery, ClubCompsQueryVariables>(
    ClubCompsDocument,
    options,
  );
}
export function useClubCompsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClubCompsQuery,
    ClubCompsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClubCompsQuery, ClubCompsQueryVariables>(
    ClubCompsDocument,
    options,
  );
}
export type ClubCompsQueryHookResult = ReturnType<typeof useClubCompsQuery>;
export type ClubCompsLazyQueryHookResult = ReturnType<
  typeof useClubCompsLazyQuery
>;
export type ClubCompsQueryResult = Apollo.QueryResult<
  ClubCompsQuery,
  ClubCompsQueryVariables
>;
export const GetClubRoundsDocument = gql`
  query getClubRounds {
    clubRounds {
      rounds {
        id
        startTime
        name
        course {
          id
          name
          club {
            id
            name
          }
        }
        gameType {
          id
          description
        }
        userRounds {
          id
        }
        tournament {
          id
        }
        settings {
          id
          field
        }
        roundType {
          id
        }
      }
      count
    }
  }
`;

/**
 * __useGetClubRoundsQuery__
 *
 * To run a query within a React component, call `useGetClubRoundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubRoundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubRoundsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClubRoundsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClubRoundsQuery,
    GetClubRoundsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClubRoundsQuery, GetClubRoundsQueryVariables>(
    GetClubRoundsDocument,
    options,
  );
}
export function useGetClubRoundsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubRoundsQuery,
    GetClubRoundsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClubRoundsQuery, GetClubRoundsQueryVariables>(
    GetClubRoundsDocument,
    options,
  );
}
export type GetClubRoundsQueryHookResult = ReturnType<
  typeof useGetClubRoundsQuery
>;
export type GetClubRoundsLazyQueryHookResult = ReturnType<
  typeof useGetClubRoundsLazyQuery
>;
export type GetClubRoundsQueryResult = Apollo.QueryResult<
  GetClubRoundsQuery,
  GetClubRoundsQueryVariables
>;
export const CreateClubRoundsDocument = gql`
  mutation createClubRounds($input: CreateClubRoundsInput!) {
    createClubRounds(input: $input) {
      ...ClubRoundMutationResultParts
    }
  }
  ${ClubRoundMutationResultPartsFragmentDoc}
`;
export type CreateClubRoundsMutationFn = Apollo.MutationFunction<
  CreateClubRoundsMutation,
  CreateClubRoundsMutationVariables
>;

/**
 * __useCreateClubRoundsMutation__
 *
 * To run a mutation, you first call `useCreateClubRoundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClubRoundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClubRoundsMutation, { data, loading, error }] = useCreateClubRoundsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClubRoundsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClubRoundsMutation,
    CreateClubRoundsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClubRoundsMutation,
    CreateClubRoundsMutationVariables
  >(CreateClubRoundsDocument, options);
}
export type CreateClubRoundsMutationHookResult = ReturnType<
  typeof useCreateClubRoundsMutation
>;
export type CreateClubRoundsMutationResult =
  Apollo.MutationResult<CreateClubRoundsMutation>;
export type CreateClubRoundsMutationOptions = Apollo.BaseMutationOptions<
  CreateClubRoundsMutation,
  CreateClubRoundsMutationVariables
>;
export const UpdateClubRoundDocument = gql`
  mutation updateClubRound($input: UpdateClubRoundInput!) {
    updateClubRound(input: $input) {
      ...ClubRoundMutationResultParts
    }
  }
  ${ClubRoundMutationResultPartsFragmentDoc}
`;
export type UpdateClubRoundMutationFn = Apollo.MutationFunction<
  UpdateClubRoundMutation,
  UpdateClubRoundMutationVariables
>;

/**
 * __useUpdateClubRoundMutation__
 *
 * To run a mutation, you first call `useUpdateClubRoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClubRoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClubRoundMutation, { data, loading, error }] = useUpdateClubRoundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClubRoundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClubRoundMutation,
    UpdateClubRoundMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClubRoundMutation,
    UpdateClubRoundMutationVariables
  >(UpdateClubRoundDocument, options);
}
export type UpdateClubRoundMutationHookResult = ReturnType<
  typeof useUpdateClubRoundMutation
>;
export type UpdateClubRoundMutationResult =
  Apollo.MutationResult<UpdateClubRoundMutation>;
export type UpdateClubRoundMutationOptions = Apollo.BaseMutationOptions<
  UpdateClubRoundMutation,
  UpdateClubRoundMutationVariables
>;
export const CreatePrivateRoundDocument = gql`
  mutation createPrivateRound($privateRound: PrivateRoundInput!) {
    createPrivateRound(privateRound: $privateRound) {
      id
      startTime
      course {
        id
        name
        club {
          id
          name
        }
      }
      gameType {
        id
        description
      }
      userRounds {
        id
      }
    }
  }
`;
export type CreatePrivateRoundMutationFn = Apollo.MutationFunction<
  CreatePrivateRoundMutation,
  CreatePrivateRoundMutationVariables
>;

/**
 * __useCreatePrivateRoundMutation__
 *
 * To run a mutation, you first call `useCreatePrivateRoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrivateRoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrivateRoundMutation, { data, loading, error }] = useCreatePrivateRoundMutation({
 *   variables: {
 *      privateRound: // value for 'privateRound'
 *   },
 * });
 */
export function useCreatePrivateRoundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePrivateRoundMutation,
    CreatePrivateRoundMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePrivateRoundMutation,
    CreatePrivateRoundMutationVariables
  >(CreatePrivateRoundDocument, options);
}
export type CreatePrivateRoundMutationHookResult = ReturnType<
  typeof useCreatePrivateRoundMutation
>;
export type CreatePrivateRoundMutationResult =
  Apollo.MutationResult<CreatePrivateRoundMutation>;
export type CreatePrivateRoundMutationOptions = Apollo.BaseMutationOptions<
  CreatePrivateRoundMutation,
  CreatePrivateRoundMutationVariables
>;
export const DeleteRoundDocument = gql`
  mutation deleteRound($roundId: ID!) {
    deleteRound(roundId: $roundId) {
      id
    }
  }
`;
export type DeleteRoundMutationFn = Apollo.MutationFunction<
  DeleteRoundMutation,
  DeleteRoundMutationVariables
>;

/**
 * __useDeleteRoundMutation__
 *
 * To run a mutation, you first call `useDeleteRoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoundMutation, { data, loading, error }] = useDeleteRoundMutation({
 *   variables: {
 *      roundId: // value for 'roundId'
 *   },
 * });
 */
export function useDeleteRoundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRoundMutation,
    DeleteRoundMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRoundMutation, DeleteRoundMutationVariables>(
    DeleteRoundDocument,
    options,
  );
}
export type DeleteRoundMutationHookResult = ReturnType<
  typeof useDeleteRoundMutation
>;
export type DeleteRoundMutationResult =
  Apollo.MutationResult<DeleteRoundMutation>;
export type DeleteRoundMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoundMutation,
  DeleteRoundMutationVariables
>;
export const GetRoundLeaderboardByIdDocument = gql`
  query getRoundLeaderboardById($id: ID!) {
    publicRoundLeaderboard(id: $id) {
      id
      round {
        id
        name
        startTime
        isComplete
        roundType {
          id
        }
        latestScores {
          ...UserScoreCardParts
        }
        rareScores {
          ...UserScoreCardParts
        }
        roundStats {
          ...roundStatsParts
        }
        course {
          id
          name
          club {
            id
            name
            logoUrl
            bannerUrl
            webLeaderboardSidebarAdvert: advert(
              slot: WEB_CLUB_LEADERBOARD_SIDEBAR
            ) {
              ...AdvertParts
            }
            webLeaderboardFooterSlotOneAdvert: advert(
              slot: WEB_CLUB_LEADERBOARD_FOOTER_SLOT_ONE
            ) {
              ...AdvertParts
            }
            webLeaderboardFooterSlotTwoAdvert: advert(
              slot: WEB_CLUB_LEADERBOARD_FOOTER_SLOT_TWO
            ) {
              ...AdvertParts
            }
            webLeaderboardFooterSlotThreeAdvert: advert(
              slot: WEB_CLUB_LEADERBOARD_FOOTER_SLOT_THREE
            ) {
              ...AdvertParts
            }
            webLeaderboardFooterSlotFourAdvert: advert(
              slot: WEB_CLUB_LEADERBOARD_FOOTER_SLOT_FOUR
            ) {
              ...AdvertParts
            }
          }
        }
        gameType {
          id
          description
          teamSize
          gameFormat {
            id
          }
          scoringMethod {
            id
          }
        }
        settings {
          id
          determineWinnerBy
          genderSeparatedDivisions
          leaderboardHoleLimit
          limitTvLeaderboardHoles
        }
        divisions {
          ...divisionParts
        }
      }
      leaderboard {
        id
        isCountOut
        rank
        total
        thru
        userRounds {
          id
          isSubmitted
          handicap
          userRoundHandicap {
            handicapIndex
            isOfficial
          }
          user {
            id
            firstName
            lastName
            gender {
              id
            }
          }
        }
        totals {
          gross
          nettToPar
          nettToHandicap
        }
      }
    }
  }
  ${UserScoreCardPartsFragmentDoc}
  ${RoundStatsPartsFragmentDoc}
  ${AdvertPartsFragmentDoc}
  ${DivisionPartsFragmentDoc}
`;

/**
 * __useGetRoundLeaderboardByIdQuery__
 *
 * To run a query within a React component, call `useGetRoundLeaderboardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoundLeaderboardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoundLeaderboardByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoundLeaderboardByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoundLeaderboardByIdQuery,
    GetRoundLeaderboardByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRoundLeaderboardByIdQuery,
    GetRoundLeaderboardByIdQueryVariables
  >(GetRoundLeaderboardByIdDocument, options);
}
export function useGetRoundLeaderboardByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoundLeaderboardByIdQuery,
    GetRoundLeaderboardByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRoundLeaderboardByIdQuery,
    GetRoundLeaderboardByIdQueryVariables
  >(GetRoundLeaderboardByIdDocument, options);
}
export type GetRoundLeaderboardByIdQueryHookResult = ReturnType<
  typeof useGetRoundLeaderboardByIdQuery
>;
export type GetRoundLeaderboardByIdLazyQueryHookResult = ReturnType<
  typeof useGetRoundLeaderboardByIdLazyQuery
>;
export type GetRoundLeaderboardByIdQueryResult = Apollo.QueryResult<
  GetRoundLeaderboardByIdQuery,
  GetRoundLeaderboardByIdQueryVariables
>;
export const GetRoundByInviteCodeDocument = gql`
  query getRoundByInviteCode($inviteCode: String!) {
    roundLookup(inviteCode: $inviteCode) {
      id
    }
  }
`;

/**
 * __useGetRoundByInviteCodeQuery__
 *
 * To run a query within a React component, call `useGetRoundByInviteCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoundByInviteCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoundByInviteCodeQuery({
 *   variables: {
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useGetRoundByInviteCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoundByInviteCodeQuery,
    GetRoundByInviteCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRoundByInviteCodeQuery,
    GetRoundByInviteCodeQueryVariables
  >(GetRoundByInviteCodeDocument, options);
}
export function useGetRoundByInviteCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoundByInviteCodeQuery,
    GetRoundByInviteCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRoundByInviteCodeQuery,
    GetRoundByInviteCodeQueryVariables
  >(GetRoundByInviteCodeDocument, options);
}
export type GetRoundByInviteCodeQueryHookResult = ReturnType<
  typeof useGetRoundByInviteCodeQuery
>;
export type GetRoundByInviteCodeLazyQueryHookResult = ReturnType<
  typeof useGetRoundByInviteCodeLazyQuery
>;
export type GetRoundByInviteCodeQueryResult = Apollo.QueryResult<
  GetRoundByInviteCodeQuery,
  GetRoundByInviteCodeQueryVariables
>;
export const GetClubRoundByIdDocument = gql`
  query getClubRoundById($id: ID!) {
    round(id: $id) {
      id
      startTime
      course {
        id
        name
      }
      gameType {
        id
        description
      }
      name
      numberOfHoles
      settings {
        id
        handicapEditAllowed
        captureLimitInSeconds
        leaderboardHoleLimit
        limitTvLeaderboardHoles
        handicapAllowance
        inviteCodeRequired
        field
        cutOffDate
        determineWinnerBy
        genderSeparatedDivisions
      }
      tickerItems {
        id
        description
      }
      tournament {
        id
      }
      userRounds {
        id
      }
      isComplete
      roundType {
        id
      }
      divisions {
        id
        name
        genderId
        gameTypeId
        maxHandicapIndex
        minHandicapIndex
        description
        determineWinnerBy
      }
      isInProgress
    }
  }
`;

/**
 * __useGetClubRoundByIdQuery__
 *
 * To run a query within a React component, call `useGetClubRoundByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubRoundByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubRoundByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClubRoundByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubRoundByIdQuery,
    GetClubRoundByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClubRoundByIdQuery, GetClubRoundByIdQueryVariables>(
    GetClubRoundByIdDocument,
    options,
  );
}
export function useGetClubRoundByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubRoundByIdQuery,
    GetClubRoundByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClubRoundByIdQuery,
    GetClubRoundByIdQueryVariables
  >(GetClubRoundByIdDocument, options);
}
export type GetClubRoundByIdQueryHookResult = ReturnType<
  typeof useGetClubRoundByIdQuery
>;
export type GetClubRoundByIdLazyQueryHookResult = ReturnType<
  typeof useGetClubRoundByIdLazyQuery
>;
export type GetClubRoundByIdQueryResult = Apollo.QueryResult<
  GetClubRoundByIdQuery,
  GetClubRoundByIdQueryVariables
>;
export const GetTickerItemsOfPreviousClubRoundDocument = gql`
  query getTickerItemsOfPreviousClubRound {
    tickerItemsOfPreviousRound {
      id
      description
    }
  }
`;

/**
 * __useGetTickerItemsOfPreviousClubRoundQuery__
 *
 * To run a query within a React component, call `useGetTickerItemsOfPreviousClubRoundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTickerItemsOfPreviousClubRoundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTickerItemsOfPreviousClubRoundQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTickerItemsOfPreviousClubRoundQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTickerItemsOfPreviousClubRoundQuery,
    GetTickerItemsOfPreviousClubRoundQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTickerItemsOfPreviousClubRoundQuery,
    GetTickerItemsOfPreviousClubRoundQueryVariables
  >(GetTickerItemsOfPreviousClubRoundDocument, options);
}
export function useGetTickerItemsOfPreviousClubRoundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTickerItemsOfPreviousClubRoundQuery,
    GetTickerItemsOfPreviousClubRoundQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTickerItemsOfPreviousClubRoundQuery,
    GetTickerItemsOfPreviousClubRoundQueryVariables
  >(GetTickerItemsOfPreviousClubRoundDocument, options);
}
export type GetTickerItemsOfPreviousClubRoundQueryHookResult = ReturnType<
  typeof useGetTickerItemsOfPreviousClubRoundQuery
>;
export type GetTickerItemsOfPreviousClubRoundLazyQueryHookResult = ReturnType<
  typeof useGetTickerItemsOfPreviousClubRoundLazyQuery
>;
export type GetTickerItemsOfPreviousClubRoundQueryResult = Apollo.QueryResult<
  GetTickerItemsOfPreviousClubRoundQuery,
  GetTickerItemsOfPreviousClubRoundQueryVariables
>;
export const GetUserRoundsByRoundIdDocument = gql`
  query getUserRoundsByRoundId($roundId: ID!) {
    round(id: $roundId) {
      id
      gameType {
        id
      }
      userRounds {
        id
        scoringGroup {
          id
          startingHole
        }
        team {
          id
        }
        user {
          id
          firstName
          lastName
        }
        userRoundHandicap {
          id
          playingHandicap
        }
        userScoreCards {
          id
          points
          score
          par
          hole {
            id
            holeNumber
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserRoundsByRoundIdQuery__
 *
 * To run a query within a React component, call `useGetUserRoundsByRoundIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoundsByRoundIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoundsByRoundIdQuery({
 *   variables: {
 *      roundId: // value for 'roundId'
 *   },
 * });
 */
export function useGetUserRoundsByRoundIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserRoundsByRoundIdQuery,
    GetUserRoundsByRoundIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserRoundsByRoundIdQuery,
    GetUserRoundsByRoundIdQueryVariables
  >(GetUserRoundsByRoundIdDocument, options);
}
export function useGetUserRoundsByRoundIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRoundsByRoundIdQuery,
    GetUserRoundsByRoundIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserRoundsByRoundIdQuery,
    GetUserRoundsByRoundIdQueryVariables
  >(GetUserRoundsByRoundIdDocument, options);
}
export type GetUserRoundsByRoundIdQueryHookResult = ReturnType<
  typeof useGetUserRoundsByRoundIdQuery
>;
export type GetUserRoundsByRoundIdLazyQueryHookResult = ReturnType<
  typeof useGetUserRoundsByRoundIdLazyQuery
>;
export type GetUserRoundsByRoundIdQueryResult = Apollo.QueryResult<
  GetUserRoundsByRoundIdQuery,
  GetUserRoundsByRoundIdQueryVariables
>;
export const GetRoundStatsDocument = gql`
  query getRoundStats($id: ID!) {
    round(id: $id) {
      id
      isComplete
      numberOfHoles
      roundStats {
        ...roundStatsParts
      }
      settings {
        id
        leaderboardHoleLimit
      }
    }
  }
  ${RoundStatsPartsFragmentDoc}
`;

/**
 * __useGetRoundStatsQuery__
 *
 * To run a query within a React component, call `useGetRoundStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoundStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoundStatsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoundStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoundStatsQuery,
    GetRoundStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoundStatsQuery, GetRoundStatsQueryVariables>(
    GetRoundStatsDocument,
    options,
  );
}
export function useGetRoundStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoundStatsQuery,
    GetRoundStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoundStatsQuery, GetRoundStatsQueryVariables>(
    GetRoundStatsDocument,
    options,
  );
}
export type GetRoundStatsQueryHookResult = ReturnType<
  typeof useGetRoundStatsQuery
>;
export type GetRoundStatsLazyQueryHookResult = ReturnType<
  typeof useGetRoundStatsLazyQuery
>;
export type GetRoundStatsQueryResult = Apollo.QueryResult<
  GetRoundStatsQuery,
  GetRoundStatsQueryVariables
>;
export const GetRoundPerformanceDocument = gql`
  query getRoundPerformance($roundId: ID!) {
    roundPerformance(roundId: $roundId) {
      id
      holePerformance {
        ...HolePerformanceParts
      }
    }
  }
  ${HolePerformancePartsFragmentDoc}
`;

/**
 * __useGetRoundPerformanceQuery__
 *
 * To run a query within a React component, call `useGetRoundPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoundPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoundPerformanceQuery({
 *   variables: {
 *      roundId: // value for 'roundId'
 *   },
 * });
 */
export function useGetRoundPerformanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoundPerformanceQuery,
    GetRoundPerformanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRoundPerformanceQuery,
    GetRoundPerformanceQueryVariables
  >(GetRoundPerformanceDocument, options);
}
export function useGetRoundPerformanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoundPerformanceQuery,
    GetRoundPerformanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRoundPerformanceQuery,
    GetRoundPerformanceQueryVariables
  >(GetRoundPerformanceDocument, options);
}
export type GetRoundPerformanceQueryHookResult = ReturnType<
  typeof useGetRoundPerformanceQuery
>;
export type GetRoundPerformanceLazyQueryHookResult = ReturnType<
  typeof useGetRoundPerformanceLazyQuery
>;
export type GetRoundPerformanceQueryResult = Apollo.QueryResult<
  GetRoundPerformanceQuery,
  GetRoundPerformanceQueryVariables
>;
export const AddPlayersByEmailDocument = gql`
  mutation addPlayersByEmail($input: AddPlayersByEmailInput!) {
    addPlayersByEmail(input: $input) {
      id
      userRounds {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export type AddPlayersByEmailMutationFn = Apollo.MutationFunction<
  AddPlayersByEmailMutation,
  AddPlayersByEmailMutationVariables
>;

/**
 * __useAddPlayersByEmailMutation__
 *
 * To run a mutation, you first call `useAddPlayersByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlayersByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlayersByEmailMutation, { data, loading, error }] = useAddPlayersByEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPlayersByEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPlayersByEmailMutation,
    AddPlayersByEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPlayersByEmailMutation,
    AddPlayersByEmailMutationVariables
  >(AddPlayersByEmailDocument, options);
}
export type AddPlayersByEmailMutationHookResult = ReturnType<
  typeof useAddPlayersByEmailMutation
>;
export type AddPlayersByEmailMutationResult =
  Apollo.MutationResult<AddPlayersByEmailMutation>;
export type AddPlayersByEmailMutationOptions = Apollo.BaseMutationOptions<
  AddPlayersByEmailMutation,
  AddPlayersByEmailMutationVariables
>;
export const CreateFourballDocument = gql`
  mutation createFourball($input: CreateFourballInput!) {
    createFourball(input: $input) {
      id
      scoringGroup {
        id
        startingHole
      }
    }
  }
`;
export type CreateFourballMutationFn = Apollo.MutationFunction<
  CreateFourballMutation,
  CreateFourballMutationVariables
>;

/**
 * __useCreateFourballMutation__
 *
 * To run a mutation, you first call `useCreateFourballMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFourballMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFourballMutation, { data, loading, error }] = useCreateFourballMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFourballMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFourballMutation,
    CreateFourballMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFourballMutation,
    CreateFourballMutationVariables
  >(CreateFourballDocument, options);
}
export type CreateFourballMutationHookResult = ReturnType<
  typeof useCreateFourballMutation
>;
export type CreateFourballMutationResult =
  Apollo.MutationResult<CreateFourballMutation>;
export type CreateFourballMutationOptions = Apollo.BaseMutationOptions<
  CreateFourballMutation,
  CreateFourballMutationVariables
>;
export const AddGhostPlayerDocument = gql`
  mutation addGhostPlayer($input: AddGhostPlayerInput!) {
    addGhostPlayer(input: $input)
  }
`;
export type AddGhostPlayerMutationFn = Apollo.MutationFunction<
  AddGhostPlayerMutation,
  AddGhostPlayerMutationVariables
>;

/**
 * __useAddGhostPlayerMutation__
 *
 * To run a mutation, you first call `useAddGhostPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGhostPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGhostPlayerMutation, { data, loading, error }] = useAddGhostPlayerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGhostPlayerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGhostPlayerMutation,
    AddGhostPlayerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGhostPlayerMutation,
    AddGhostPlayerMutationVariables
  >(AddGhostPlayerDocument, options);
}
export type AddGhostPlayerMutationHookResult = ReturnType<
  typeof useAddGhostPlayerMutation
>;
export type AddGhostPlayerMutationResult =
  Apollo.MutationResult<AddGhostPlayerMutation>;
export type AddGhostPlayerMutationOptions = Apollo.BaseMutationOptions<
  AddGhostPlayerMutation,
  AddGhostPlayerMutationVariables
>;
export const AddRandomGhostPlayerDocument = gql`
  mutation addRandomGhostPlayer($input: AddRandomGhostPlayerInput!) {
    addRandomGhostPlayer(input: $input)
  }
`;
export type AddRandomGhostPlayerMutationFn = Apollo.MutationFunction<
  AddRandomGhostPlayerMutation,
  AddRandomGhostPlayerMutationVariables
>;

/**
 * __useAddRandomGhostPlayerMutation__
 *
 * To run a mutation, you first call `useAddRandomGhostPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRandomGhostPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRandomGhostPlayerMutation, { data, loading, error }] = useAddRandomGhostPlayerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRandomGhostPlayerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRandomGhostPlayerMutation,
    AddRandomGhostPlayerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRandomGhostPlayerMutation,
    AddRandomGhostPlayerMutationVariables
  >(AddRandomGhostPlayerDocument, options);
}
export type AddRandomGhostPlayerMutationHookResult = ReturnType<
  typeof useAddRandomGhostPlayerMutation
>;
export type AddRandomGhostPlayerMutationResult =
  Apollo.MutationResult<AddRandomGhostPlayerMutation>;
export type AddRandomGhostPlayerMutationOptions = Apollo.BaseMutationOptions<
  AddRandomGhostPlayerMutation,
  AddRandomGhostPlayerMutationVariables
>;
export const AddSpecificPivotPlayerDocument = gql`
  mutation AddSpecificPivotPlayer($input: AddSpecificPivotPlayerInput!) {
    addSpecificPivotPlayer(input: $input) {
      id
      teams {
        id
        total
        specialUserRounds
        userRounds {
          id
        }
      }
    }
  }
`;
export type AddSpecificPivotPlayerMutationFn = Apollo.MutationFunction<
  AddSpecificPivotPlayerMutation,
  AddSpecificPivotPlayerMutationVariables
>;

/**
 * __useAddSpecificPivotPlayerMutation__
 *
 * To run a mutation, you first call `useAddSpecificPivotPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSpecificPivotPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSpecificPivotPlayerMutation, { data, loading, error }] = useAddSpecificPivotPlayerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSpecificPivotPlayerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSpecificPivotPlayerMutation,
    AddSpecificPivotPlayerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSpecificPivotPlayerMutation,
    AddSpecificPivotPlayerMutationVariables
  >(AddSpecificPivotPlayerDocument, options);
}
export type AddSpecificPivotPlayerMutationHookResult = ReturnType<
  typeof useAddSpecificPivotPlayerMutation
>;
export type AddSpecificPivotPlayerMutationResult =
  Apollo.MutationResult<AddSpecificPivotPlayerMutation>;
export type AddSpecificPivotPlayerMutationOptions = Apollo.BaseMutationOptions<
  AddSpecificPivotPlayerMutation,
  AddSpecificPivotPlayerMutationVariables
>;
export const AddPivotPlayerDocument = gql`
  mutation AddPivotPlayer($input: AddPivotPlayerInput!) {
    addPivotPlayer(input: $input) {
      id
      teams {
        id
        total
        specialUserRounds
        userRounds {
          id
        }
      }
    }
  }
`;
export type AddPivotPlayerMutationFn = Apollo.MutationFunction<
  AddPivotPlayerMutation,
  AddPivotPlayerMutationVariables
>;

/**
 * __useAddPivotPlayerMutation__
 *
 * To run a mutation, you first call `useAddPivotPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPivotPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPivotPlayerMutation, { data, loading, error }] = useAddPivotPlayerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPivotPlayerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPivotPlayerMutation,
    AddPivotPlayerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPivotPlayerMutation,
    AddPivotPlayerMutationVariables
  >(AddPivotPlayerDocument, options);
}
export type AddPivotPlayerMutationHookResult = ReturnType<
  typeof useAddPivotPlayerMutation
>;
export type AddPivotPlayerMutationResult =
  Apollo.MutationResult<AddPivotPlayerMutation>;
export type AddPivotPlayerMutationOptions = Apollo.BaseMutationOptions<
  AddPivotPlayerMutation,
  AddPivotPlayerMutationVariables
>;
export const GetSponsorAdvertBySlotDocument = gql`
  query getSponsorAdvertBySlot($slot: SponsorSlot!, $clubId: ID) {
    advert(slot: $slot, clubId: $clubId) {
      ...AdvertParts
    }
  }
  ${AdvertPartsFragmentDoc}
`;

/**
 * __useGetSponsorAdvertBySlotQuery__
 *
 * To run a query within a React component, call `useGetSponsorAdvertBySlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSponsorAdvertBySlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSponsorAdvertBySlotQuery({
 *   variables: {
 *      slot: // value for 'slot'
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useGetSponsorAdvertBySlotQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSponsorAdvertBySlotQuery,
    GetSponsorAdvertBySlotQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSponsorAdvertBySlotQuery,
    GetSponsorAdvertBySlotQueryVariables
  >(GetSponsorAdvertBySlotDocument, options);
}
export function useGetSponsorAdvertBySlotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSponsorAdvertBySlotQuery,
    GetSponsorAdvertBySlotQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSponsorAdvertBySlotQuery,
    GetSponsorAdvertBySlotQueryVariables
  >(GetSponsorAdvertBySlotDocument, options);
}
export type GetSponsorAdvertBySlotQueryHookResult = ReturnType<
  typeof useGetSponsorAdvertBySlotQuery
>;
export type GetSponsorAdvertBySlotLazyQueryHookResult = ReturnType<
  typeof useGetSponsorAdvertBySlotLazyQuery
>;
export type GetSponsorAdvertBySlotQueryResult = Apollo.QueryResult<
  GetSponsorAdvertBySlotQuery,
  GetSponsorAdvertBySlotQueryVariables
>;
export const GetTeamScorecardByTeamIdDocument = gql`
  query getTeamScorecardByTeamId($teamId: ID!, $roundId: ID!) {
    round(id: $roundId) {
      id
      dateCreated
      course {
        id
        name
        club {
          id
          name
        }
      }
      settings {
        id
        determineWinnerBy
      }
      courseScoreCard {
        id
        holes {
          id
          holeNumber
          malePar
          malePlusStroke
          maleStroke
          femalePar
          femalePlusStroke
          femaleStroke
        }
      }
      gameType {
        id
        scoringMethod {
          id
        }
      }
    }
    team(teamId: $teamId) {
      id
      total
      userRounds {
        id
        user {
          id
          firstName
          lastName
          gender {
            id
          }
        }
        userRoundHandicap {
          id
          playingHandicap
          courseHandicap
          handicapIndex
        }
        userScoreCards {
          id
          holeNumber
          score
          points
          par
          stroke
        }
      }
      specialUserRounds
    }
  }
`;

/**
 * __useGetTeamScorecardByTeamIdQuery__
 *
 * To run a query within a React component, call `useGetTeamScorecardByTeamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamScorecardByTeamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamScorecardByTeamIdQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      roundId: // value for 'roundId'
 *   },
 * });
 */
export function useGetTeamScorecardByTeamIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeamScorecardByTeamIdQuery,
    GetTeamScorecardByTeamIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTeamScorecardByTeamIdQuery,
    GetTeamScorecardByTeamIdQueryVariables
  >(GetTeamScorecardByTeamIdDocument, options);
}
export function useGetTeamScorecardByTeamIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamScorecardByTeamIdQuery,
    GetTeamScorecardByTeamIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTeamScorecardByTeamIdQuery,
    GetTeamScorecardByTeamIdQueryVariables
  >(GetTeamScorecardByTeamIdDocument, options);
}
export type GetTeamScorecardByTeamIdQueryHookResult = ReturnType<
  typeof useGetTeamScorecardByTeamIdQuery
>;
export type GetTeamScorecardByTeamIdLazyQueryHookResult = ReturnType<
  typeof useGetTeamScorecardByTeamIdLazyQuery
>;
export type GetTeamScorecardByTeamIdQueryResult = Apollo.QueryResult<
  GetTeamScorecardByTeamIdQuery,
  GetTeamScorecardByTeamIdQueryVariables
>;
export const CreateOrUpdateTournamentDocument = gql`
  mutation createOrUpdateTournament(
    $createOrUpdateTournamentInput: CreateOrUpdateTournamentInput!
  ) {
    createOrUpdateTournament(
      createOrUpdateTournamentInput: $createOrUpdateTournamentInput
    ) {
      ...TournamentParts
    }
  }
  ${TournamentPartsFragmentDoc}
`;
export type CreateOrUpdateTournamentMutationFn = Apollo.MutationFunction<
  CreateOrUpdateTournamentMutation,
  CreateOrUpdateTournamentMutationVariables
>;

/**
 * __useCreateOrUpdateTournamentMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateTournamentMutation, { data, loading, error }] = useCreateOrUpdateTournamentMutation({
 *   variables: {
 *      createOrUpdateTournamentInput: // value for 'createOrUpdateTournamentInput'
 *   },
 * });
 */
export function useCreateOrUpdateTournamentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateTournamentMutation,
    CreateOrUpdateTournamentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateTournamentMutation,
    CreateOrUpdateTournamentMutationVariables
  >(CreateOrUpdateTournamentDocument, options);
}
export type CreateOrUpdateTournamentMutationHookResult = ReturnType<
  typeof useCreateOrUpdateTournamentMutation
>;
export type CreateOrUpdateTournamentMutationResult =
  Apollo.MutationResult<CreateOrUpdateTournamentMutation>;
export type CreateOrUpdateTournamentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrUpdateTournamentMutation,
    CreateOrUpdateTournamentMutationVariables
  >;
export const GetClubTournamentsDocument = gql`
  query getClubTournaments {
    clubTournaments {
      ...TournamentParts
    }
  }
  ${TournamentPartsFragmentDoc}
`;

/**
 * __useGetClubTournamentsQuery__
 *
 * To run a query within a React component, call `useGetClubTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubTournamentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClubTournamentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClubTournamentsQuery,
    GetClubTournamentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClubTournamentsQuery,
    GetClubTournamentsQueryVariables
  >(GetClubTournamentsDocument, options);
}
export function useGetClubTournamentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubTournamentsQuery,
    GetClubTournamentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClubTournamentsQuery,
    GetClubTournamentsQueryVariables
  >(GetClubTournamentsDocument, options);
}
export type GetClubTournamentsQueryHookResult = ReturnType<
  typeof useGetClubTournamentsQuery
>;
export type GetClubTournamentsLazyQueryHookResult = ReturnType<
  typeof useGetClubTournamentsLazyQuery
>;
export type GetClubTournamentsQueryResult = Apollo.QueryResult<
  GetClubTournamentsQuery,
  GetClubTournamentsQueryVariables
>;
export const GetSimpleClubTournamentsDocument = gql`
  query getSimpleClubTournaments {
    clubTournaments {
      id
      name
    }
  }
`;

/**
 * __useGetSimpleClubTournamentsQuery__
 *
 * To run a query within a React component, call `useGetSimpleClubTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimpleClubTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimpleClubTournamentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSimpleClubTournamentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSimpleClubTournamentsQuery,
    GetSimpleClubTournamentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSimpleClubTournamentsQuery,
    GetSimpleClubTournamentsQueryVariables
  >(GetSimpleClubTournamentsDocument, options);
}
export function useGetSimpleClubTournamentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSimpleClubTournamentsQuery,
    GetSimpleClubTournamentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSimpleClubTournamentsQuery,
    GetSimpleClubTournamentsQueryVariables
  >(GetSimpleClubTournamentsDocument, options);
}
export type GetSimpleClubTournamentsQueryHookResult = ReturnType<
  typeof useGetSimpleClubTournamentsQuery
>;
export type GetSimpleClubTournamentsLazyQueryHookResult = ReturnType<
  typeof useGetSimpleClubTournamentsLazyQuery
>;
export type GetSimpleClubTournamentsQueryResult = Apollo.QueryResult<
  GetSimpleClubTournamentsQuery,
  GetSimpleClubTournamentsQueryVariables
>;
export const GetTournamentAndClubCoursesDocument = gql`
  query getTournamentAndClubCourses($tournamentId: ID!) {
    tournament(tournamentId: $tournamentId) {
      ...TournamentParts
    }
    currentClub {
      id
      name
      logoUrl
      courses {
        id
        name
      }
    }
  }
  ${TournamentPartsFragmentDoc}
`;

/**
 * __useGetTournamentAndClubCoursesQuery__
 *
 * To run a query within a React component, call `useGetTournamentAndClubCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentAndClubCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentAndClubCoursesQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useGetTournamentAndClubCoursesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTournamentAndClubCoursesQuery,
    GetTournamentAndClubCoursesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTournamentAndClubCoursesQuery,
    GetTournamentAndClubCoursesQueryVariables
  >(GetTournamentAndClubCoursesDocument, options);
}
export function useGetTournamentAndClubCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTournamentAndClubCoursesQuery,
    GetTournamentAndClubCoursesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTournamentAndClubCoursesQuery,
    GetTournamentAndClubCoursesQueryVariables
  >(GetTournamentAndClubCoursesDocument, options);
}
export type GetTournamentAndClubCoursesQueryHookResult = ReturnType<
  typeof useGetTournamentAndClubCoursesQuery
>;
export type GetTournamentAndClubCoursesLazyQueryHookResult = ReturnType<
  typeof useGetTournamentAndClubCoursesLazyQuery
>;
export type GetTournamentAndClubCoursesQueryResult = Apollo.QueryResult<
  GetTournamentAndClubCoursesQuery,
  GetTournamentAndClubCoursesQueryVariables
>;
export const CreateOfUpdateTournamentRoundDocument = gql`
  mutation createOfUpdateTournamentRound(
    $createOrUpdateTournamentRoundInput: CreateOrUpdateTournamentRoundInput!
  ) {
    createOrUpdateTournamentRound(
      createOrUpdateTournamentRoundInput: $createOrUpdateTournamentRoundInput
    ) {
      ...TournamentRoundParts
    }
  }
  ${TournamentRoundPartsFragmentDoc}
`;
export type CreateOfUpdateTournamentRoundMutationFn = Apollo.MutationFunction<
  CreateOfUpdateTournamentRoundMutation,
  CreateOfUpdateTournamentRoundMutationVariables
>;

/**
 * __useCreateOfUpdateTournamentRoundMutation__
 *
 * To run a mutation, you first call `useCreateOfUpdateTournamentRoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfUpdateTournamentRoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfUpdateTournamentRoundMutation, { data, loading, error }] = useCreateOfUpdateTournamentRoundMutation({
 *   variables: {
 *      createOrUpdateTournamentRoundInput: // value for 'createOrUpdateTournamentRoundInput'
 *   },
 * });
 */
export function useCreateOfUpdateTournamentRoundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOfUpdateTournamentRoundMutation,
    CreateOfUpdateTournamentRoundMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOfUpdateTournamentRoundMutation,
    CreateOfUpdateTournamentRoundMutationVariables
  >(CreateOfUpdateTournamentRoundDocument, options);
}
export type CreateOfUpdateTournamentRoundMutationHookResult = ReturnType<
  typeof useCreateOfUpdateTournamentRoundMutation
>;
export type CreateOfUpdateTournamentRoundMutationResult =
  Apollo.MutationResult<CreateOfUpdateTournamentRoundMutation>;
export type CreateOfUpdateTournamentRoundMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOfUpdateTournamentRoundMutation,
    CreateOfUpdateTournamentRoundMutationVariables
  >;
export const DeleteTournamentDocument = gql`
  mutation deleteTournament($tournamentId: ID!) {
    deleteTournament(tournamentId: $tournamentId) {
      id
    }
  }
`;
export type DeleteTournamentMutationFn = Apollo.MutationFunction<
  DeleteTournamentMutation,
  DeleteTournamentMutationVariables
>;

/**
 * __useDeleteTournamentMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentMutation, { data, loading, error }] = useDeleteTournamentMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useDeleteTournamentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTournamentMutation,
    DeleteTournamentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTournamentMutation,
    DeleteTournamentMutationVariables
  >(DeleteTournamentDocument, options);
}
export type DeleteTournamentMutationHookResult = ReturnType<
  typeof useDeleteTournamentMutation
>;
export type DeleteTournamentMutationResult =
  Apollo.MutationResult<DeleteTournamentMutation>;
export type DeleteTournamentMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentMutation,
  DeleteTournamentMutationVariables
>;
export const DeleteTournamentRoundDocument = gql`
  mutation deleteTournamentRound($roundId: ID!) {
    deleteTournamentRound(roundId: $roundId) {
      id
    }
  }
`;
export type DeleteTournamentRoundMutationFn = Apollo.MutationFunction<
  DeleteTournamentRoundMutation,
  DeleteTournamentRoundMutationVariables
>;

/**
 * __useDeleteTournamentRoundMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentRoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentRoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentRoundMutation, { data, loading, error }] = useDeleteTournamentRoundMutation({
 *   variables: {
 *      roundId: // value for 'roundId'
 *   },
 * });
 */
export function useDeleteTournamentRoundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTournamentRoundMutation,
    DeleteTournamentRoundMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTournamentRoundMutation,
    DeleteTournamentRoundMutationVariables
  >(DeleteTournamentRoundDocument, options);
}
export type DeleteTournamentRoundMutationHookResult = ReturnType<
  typeof useDeleteTournamentRoundMutation
>;
export type DeleteTournamentRoundMutationResult =
  Apollo.MutationResult<DeleteTournamentRoundMutation>;
export type DeleteTournamentRoundMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentRoundMutation,
  DeleteTournamentRoundMutationVariables
>;
export const GetTournamentRoundDocument = gql`
  query getTournamentRound($roundId: ID!) {
    round(id: $roundId) {
      ...TournamentRoundParts
    }
  }
  ${TournamentRoundPartsFragmentDoc}
`;

/**
 * __useGetTournamentRoundQuery__
 *
 * To run a query within a React component, call `useGetTournamentRoundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentRoundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentRoundQuery({
 *   variables: {
 *      roundId: // value for 'roundId'
 *   },
 * });
 */
export function useGetTournamentRoundQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTournamentRoundQuery,
    GetTournamentRoundQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTournamentRoundQuery,
    GetTournamentRoundQueryVariables
  >(GetTournamentRoundDocument, options);
}
export function useGetTournamentRoundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTournamentRoundQuery,
    GetTournamentRoundQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTournamentRoundQuery,
    GetTournamentRoundQueryVariables
  >(GetTournamentRoundDocument, options);
}
export type GetTournamentRoundQueryHookResult = ReturnType<
  typeof useGetTournamentRoundQuery
>;
export type GetTournamentRoundLazyQueryHookResult = ReturnType<
  typeof useGetTournamentRoundLazyQuery
>;
export type GetTournamentRoundQueryResult = Apollo.QueryResult<
  GetTournamentRoundQuery,
  GetTournamentRoundQueryVariables
>;
export const GetTournamentLeaderboardDocument = gql`
  query getTournamentLeaderboard($tournamentId: ID!) {
    tournament(tournamentId: $tournamentId) {
      ...TournamentLeaderboardParts
    }
  }
  ${TournamentLeaderboardPartsFragmentDoc}
`;

/**
 * __useGetTournamentLeaderboardQuery__
 *
 * To run a query within a React component, call `useGetTournamentLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentLeaderboardQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useGetTournamentLeaderboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTournamentLeaderboardQuery,
    GetTournamentLeaderboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTournamentLeaderboardQuery,
    GetTournamentLeaderboardQueryVariables
  >(GetTournamentLeaderboardDocument, options);
}
export function useGetTournamentLeaderboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTournamentLeaderboardQuery,
    GetTournamentLeaderboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTournamentLeaderboardQuery,
    GetTournamentLeaderboardQueryVariables
  >(GetTournamentLeaderboardDocument, options);
}
export type GetTournamentLeaderboardQueryHookResult = ReturnType<
  typeof useGetTournamentLeaderboardQuery
>;
export type GetTournamentLeaderboardLazyQueryHookResult = ReturnType<
  typeof useGetTournamentLeaderboardLazyQuery
>;
export type GetTournamentLeaderboardQueryResult = Apollo.QueryResult<
  GetTournamentLeaderboardQuery,
  GetTournamentLeaderboardQueryVariables
>;
export const GetTournamentsForLeaderboardDocument = gql`
  query getTournamentsForLeaderboard($tournamentIds: [ID!]!) {
    tournaments(tournamentIds: $tournamentIds) {
      ...TournamentLeaderboardParts
    }
  }
  ${TournamentLeaderboardPartsFragmentDoc}
`;

/**
 * __useGetTournamentsForLeaderboardQuery__
 *
 * To run a query within a React component, call `useGetTournamentsForLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentsForLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentsForLeaderboardQuery({
 *   variables: {
 *      tournamentIds: // value for 'tournamentIds'
 *   },
 * });
 */
export function useGetTournamentsForLeaderboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTournamentsForLeaderboardQuery,
    GetTournamentsForLeaderboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTournamentsForLeaderboardQuery,
    GetTournamentsForLeaderboardQueryVariables
  >(GetTournamentsForLeaderboardDocument, options);
}
export function useGetTournamentsForLeaderboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTournamentsForLeaderboardQuery,
    GetTournamentsForLeaderboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTournamentsForLeaderboardQuery,
    GetTournamentsForLeaderboardQueryVariables
  >(GetTournamentsForLeaderboardDocument, options);
}
export type GetTournamentsForLeaderboardQueryHookResult = ReturnType<
  typeof useGetTournamentsForLeaderboardQuery
>;
export type GetTournamentsForLeaderboardLazyQueryHookResult = ReturnType<
  typeof useGetTournamentsForLeaderboardLazyQuery
>;
export type GetTournamentsForLeaderboardQueryResult = Apollo.QueryResult<
  GetTournamentsForLeaderboardQuery,
  GetTournamentsForLeaderboardQueryVariables
>;
export const GetTournamentToEditDocument = gql`
  query getTournamentToEdit($tournamentId: ID!) {
    tournament(tournamentId: $tournamentId) {
      ...TournamentParts
      settings {
        id
        determineWinnerBy
        genderSeparatedDivisions
      }
    }
    allGameTypes {
      id
      description
      scoringMethod {
        id
      }
    }
  }
  ${TournamentPartsFragmentDoc}
`;

/**
 * __useGetTournamentToEditQuery__
 *
 * To run a query within a React component, call `useGetTournamentToEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentToEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentToEditQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useGetTournamentToEditQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTournamentToEditQuery,
    GetTournamentToEditQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTournamentToEditQuery,
    GetTournamentToEditQueryVariables
  >(GetTournamentToEditDocument, options);
}
export function useGetTournamentToEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTournamentToEditQuery,
    GetTournamentToEditQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTournamentToEditQuery,
    GetTournamentToEditQueryVariables
  >(GetTournamentToEditDocument, options);
}
export type GetTournamentToEditQueryHookResult = ReturnType<
  typeof useGetTournamentToEditQuery
>;
export type GetTournamentToEditLazyQueryHookResult = ReturnType<
  typeof useGetTournamentToEditLazyQuery
>;
export type GetTournamentToEditQueryResult = Apollo.QueryResult<
  GetTournamentToEditQuery,
  GetTournamentToEditQueryVariables
>;
export const GetTournamentSettingsDocument = gql`
  query getTournamentSettings($tournamentId: ID!) {
    tournament(tournamentId: $tournamentId) {
      id
      settings {
        id
        handicapIndexLow
        handicapIndexHigh
        gender {
          id
          description
        }
        genderSeparatedDivisions
      }
    }
  }
`;

/**
 * __useGetTournamentSettingsQuery__
 *
 * To run a query within a React component, call `useGetTournamentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentSettingsQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useGetTournamentSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTournamentSettingsQuery,
    GetTournamentSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTournamentSettingsQuery,
    GetTournamentSettingsQueryVariables
  >(GetTournamentSettingsDocument, options);
}
export function useGetTournamentSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTournamentSettingsQuery,
    GetTournamentSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTournamentSettingsQuery,
    GetTournamentSettingsQueryVariables
  >(GetTournamentSettingsDocument, options);
}
export type GetTournamentSettingsQueryHookResult = ReturnType<
  typeof useGetTournamentSettingsQuery
>;
export type GetTournamentSettingsLazyQueryHookResult = ReturnType<
  typeof useGetTournamentSettingsLazyQuery
>;
export type GetTournamentSettingsQueryResult = Apollo.QueryResult<
  GetTournamentSettingsQuery,
  GetTournamentSettingsQueryVariables
>;
export const CreateTournamentSettingsDocument = gql`
  mutation createTournamentSettings($input: CreateTournamentSettingsInput!) {
    createTournamentSettings(input: $input) {
      id
      handicapIndexLow
      handicapIndexHigh
      gender {
        id
      }
      determineWinnerBy
      genderSeparatedDivisions
    }
  }
`;
export type CreateTournamentSettingsMutationFn = Apollo.MutationFunction<
  CreateTournamentSettingsMutation,
  CreateTournamentSettingsMutationVariables
>;

/**
 * __useCreateTournamentSettingsMutation__
 *
 * To run a mutation, you first call `useCreateTournamentSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentSettingsMutation, { data, loading, error }] = useCreateTournamentSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTournamentSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTournamentSettingsMutation,
    CreateTournamentSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTournamentSettingsMutation,
    CreateTournamentSettingsMutationVariables
  >(CreateTournamentSettingsDocument, options);
}
export type CreateTournamentSettingsMutationHookResult = ReturnType<
  typeof useCreateTournamentSettingsMutation
>;
export type CreateTournamentSettingsMutationResult =
  Apollo.MutationResult<CreateTournamentSettingsMutation>;
export type CreateTournamentSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateTournamentSettingsMutation,
    CreateTournamentSettingsMutationVariables
  >;
export const UpdateTournamentSettingsDocument = gql`
  mutation updateTournamentSettings($input: UpdateTournamentSettingsInput!) {
    updateTournamentSettings(input: $input) {
      id
      handicapIndexLow
      handicapIndexHigh
      gender {
        id
      }
      determineWinnerBy
      genderSeparatedDivisions
    }
  }
`;
export type UpdateTournamentSettingsMutationFn = Apollo.MutationFunction<
  UpdateTournamentSettingsMutation,
  UpdateTournamentSettingsMutationVariables
>;

/**
 * __useUpdateTournamentSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentSettingsMutation, { data, loading, error }] = useUpdateTournamentSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTournamentSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTournamentSettingsMutation,
    UpdateTournamentSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTournamentSettingsMutation,
    UpdateTournamentSettingsMutationVariables
  >(UpdateTournamentSettingsDocument, options);
}
export type UpdateTournamentSettingsMutationHookResult = ReturnType<
  typeof useUpdateTournamentSettingsMutation
>;
export type UpdateTournamentSettingsMutationResult =
  Apollo.MutationResult<UpdateTournamentSettingsMutation>;
export type UpdateTournamentSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTournamentSettingsMutation,
    UpdateTournamentSettingsMutationVariables
  >;
export const GetTournamentViewByUserDocument = gql`
  query getTournamentViewByUser {
    tournamentViewByUser {
      ...TournamentViewParts
    }
  }
  ${TournamentViewPartsFragmentDoc}
`;

/**
 * __useGetTournamentViewByUserQuery__
 *
 * To run a query within a React component, call `useGetTournamentViewByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentViewByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentViewByUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTournamentViewByUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTournamentViewByUserQuery,
    GetTournamentViewByUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTournamentViewByUserQuery,
    GetTournamentViewByUserQueryVariables
  >(GetTournamentViewByUserDocument, options);
}
export function useGetTournamentViewByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTournamentViewByUserQuery,
    GetTournamentViewByUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTournamentViewByUserQuery,
    GetTournamentViewByUserQueryVariables
  >(GetTournamentViewByUserDocument, options);
}
export type GetTournamentViewByUserQueryHookResult = ReturnType<
  typeof useGetTournamentViewByUserQuery
>;
export type GetTournamentViewByUserLazyQueryHookResult = ReturnType<
  typeof useGetTournamentViewByUserLazyQuery
>;
export type GetTournamentViewByUserQueryResult = Apollo.QueryResult<
  GetTournamentViewByUserQuery,
  GetTournamentViewByUserQueryVariables
>;
export const GetTournamentViewByIdDocument = gql`
  query getTournamentViewById($id: ID!) {
    tournamentView(id: $id) {
      ...TournamentViewParts
    }
  }
  ${TournamentViewPartsFragmentDoc}
`;

/**
 * __useGetTournamentViewByIdQuery__
 *
 * To run a query within a React component, call `useGetTournamentViewByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentViewByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentViewByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTournamentViewByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTournamentViewByIdQuery,
    GetTournamentViewByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTournamentViewByIdQuery,
    GetTournamentViewByIdQueryVariables
  >(GetTournamentViewByIdDocument, options);
}
export function useGetTournamentViewByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTournamentViewByIdQuery,
    GetTournamentViewByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTournamentViewByIdQuery,
    GetTournamentViewByIdQueryVariables
  >(GetTournamentViewByIdDocument, options);
}
export type GetTournamentViewByIdQueryHookResult = ReturnType<
  typeof useGetTournamentViewByIdQuery
>;
export type GetTournamentViewByIdLazyQueryHookResult = ReturnType<
  typeof useGetTournamentViewByIdLazyQuery
>;
export type GetTournamentViewByIdQueryResult = Apollo.QueryResult<
  GetTournamentViewByIdQuery,
  GetTournamentViewByIdQueryVariables
>;
export const CreateOrUpdateTournamentViewDocument = gql`
  mutation createOrUpdateTournamentView($input: TournamentViewInput!) {
    createOrUpdateTournamentView(input: $input) {
      ...TournamentViewParts
    }
  }
  ${TournamentViewPartsFragmentDoc}
`;
export type CreateOrUpdateTournamentViewMutationFn = Apollo.MutationFunction<
  CreateOrUpdateTournamentViewMutation,
  CreateOrUpdateTournamentViewMutationVariables
>;

/**
 * __useCreateOrUpdateTournamentViewMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateTournamentViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateTournamentViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateTournamentViewMutation, { data, loading, error }] = useCreateOrUpdateTournamentViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateTournamentViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateTournamentViewMutation,
    CreateOrUpdateTournamentViewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateTournamentViewMutation,
    CreateOrUpdateTournamentViewMutationVariables
  >(CreateOrUpdateTournamentViewDocument, options);
}
export type CreateOrUpdateTournamentViewMutationHookResult = ReturnType<
  typeof useCreateOrUpdateTournamentViewMutation
>;
export type CreateOrUpdateTournamentViewMutationResult =
  Apollo.MutationResult<CreateOrUpdateTournamentViewMutation>;
export type CreateOrUpdateTournamentViewMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrUpdateTournamentViewMutation,
    CreateOrUpdateTournamentViewMutationVariables
  >;
export const CurrentUserAndGameTypesDocument = gql`
  query currentUserAndGameTypes {
    currentUser {
      ...UserParts
      club {
        id
        name
        logoUrl
        courses {
          id
          name
        }
        settings {
          ...ClubSettingsParts
        }
      }
    }
    allGameTypes {
      id
      details
      description
      scoringMethod {
        id
      }
    }
  }
  ${UserPartsFragmentDoc}
  ${ClubSettingsPartsFragmentDoc}
`;

/**
 * __useCurrentUserAndGameTypesQuery__
 *
 * To run a query within a React component, call `useCurrentUserAndGameTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserAndGameTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserAndGameTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserAndGameTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserAndGameTypesQuery,
    CurrentUserAndGameTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserAndGameTypesQuery,
    CurrentUserAndGameTypesQueryVariables
  >(CurrentUserAndGameTypesDocument, options);
}
export function useCurrentUserAndGameTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserAndGameTypesQuery,
    CurrentUserAndGameTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserAndGameTypesQuery,
    CurrentUserAndGameTypesQueryVariables
  >(CurrentUserAndGameTypesDocument, options);
}
export type CurrentUserAndGameTypesQueryHookResult = ReturnType<
  typeof useCurrentUserAndGameTypesQuery
>;
export type CurrentUserAndGameTypesLazyQueryHookResult = ReturnType<
  typeof useCurrentUserAndGameTypesLazyQuery
>;
export type CurrentUserAndGameTypesQueryResult = Apollo.QueryResult<
  CurrentUserAndGameTypesQuery,
  CurrentUserAndGameTypesQueryVariables
>;
export const GetUserByEmailDocument = gql`
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      firstName
      lastName
    }
  }
`;

/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserByEmailQuery,
    GetUserByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(
    GetUserByEmailDocument,
    options,
  );
}
export function useGetUserByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserByEmailQuery,
    GetUserByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(
    GetUserByEmailDocument,
    options,
  );
}
export type GetUserByEmailQueryHookResult = ReturnType<
  typeof useGetUserByEmailQuery
>;
export type GetUserByEmailLazyQueryHookResult = ReturnType<
  typeof useGetUserByEmailLazyQuery
>;
export type GetUserByEmailQueryResult = Apollo.QueryResult<
  GetUserByEmailQuery,
  GetUserByEmailQueryVariables
>;
export const UpdateUserRegistrationBlacklistStatusDocument = gql`
  mutation updateUserRegistrationBlacklistStatus(
    $userRegistrationId: ID!
    $isBlackListed: Boolean!
  ) {
    updateUserRegistrationBlacklistStatus(
      userRegistrationId: $userRegistrationId
      isBlackListed: $isBlackListed
    ) {
      id
      isBlackListed
    }
  }
`;
export type UpdateUserRegistrationBlacklistStatusMutationFn =
  Apollo.MutationFunction<
    UpdateUserRegistrationBlacklistStatusMutation,
    UpdateUserRegistrationBlacklistStatusMutationVariables
  >;

/**
 * __useUpdateUserRegistrationBlacklistStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserRegistrationBlacklistStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRegistrationBlacklistStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRegistrationBlacklistStatusMutation, { data, loading, error }] = useUpdateUserRegistrationBlacklistStatusMutation({
 *   variables: {
 *      userRegistrationId: // value for 'userRegistrationId'
 *      isBlackListed: // value for 'isBlackListed'
 *   },
 * });
 */
export function useUpdateUserRegistrationBlacklistStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserRegistrationBlacklistStatusMutation,
    UpdateUserRegistrationBlacklistStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserRegistrationBlacklistStatusMutation,
    UpdateUserRegistrationBlacklistStatusMutationVariables
  >(UpdateUserRegistrationBlacklistStatusDocument, options);
}
export type UpdateUserRegistrationBlacklistStatusMutationHookResult =
  ReturnType<typeof useUpdateUserRegistrationBlacklistStatusMutation>;
export type UpdateUserRegistrationBlacklistStatusMutationResult =
  Apollo.MutationResult<UpdateUserRegistrationBlacklistStatusMutation>;
export type UpdateUserRegistrationBlacklistStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserRegistrationBlacklistStatusMutation,
    UpdateUserRegistrationBlacklistStatusMutationVariables
  >;
export const GetBestUsersIpsUserRoundDocument = gql`
  query getBestUsersIPSUserRound {
    bestUsersIPSUserRound {
      userRounds {
        ...UserRoundParts
        round {
          id
          startTime
        }
      }
      totalCount
    }
  }
  ${UserRoundPartsFragmentDoc}
`;

/**
 * __useGetBestUsersIpsUserRoundQuery__
 *
 * To run a query within a React component, call `useGetBestUsersIpsUserRoundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBestUsersIpsUserRoundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBestUsersIpsUserRoundQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBestUsersIpsUserRoundQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBestUsersIpsUserRoundQuery,
    GetBestUsersIpsUserRoundQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBestUsersIpsUserRoundQuery,
    GetBestUsersIpsUserRoundQueryVariables
  >(GetBestUsersIpsUserRoundDocument, options);
}
export function useGetBestUsersIpsUserRoundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBestUsersIpsUserRoundQuery,
    GetBestUsersIpsUserRoundQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBestUsersIpsUserRoundQuery,
    GetBestUsersIpsUserRoundQueryVariables
  >(GetBestUsersIpsUserRoundDocument, options);
}
export type GetBestUsersIpsUserRoundQueryHookResult = ReturnType<
  typeof useGetBestUsersIpsUserRoundQuery
>;
export type GetBestUsersIpsUserRoundLazyQueryHookResult = ReturnType<
  typeof useGetBestUsersIpsUserRoundLazyQuery
>;
export type GetBestUsersIpsUserRoundQueryResult = Apollo.QueryResult<
  GetBestUsersIpsUserRoundQuery,
  GetBestUsersIpsUserRoundQueryVariables
>;
export const GetUserRoundByUserRoundIdDocument = gql`
  query getUserRoundByUserRoundId($userRoundId: ID!) {
    userRound(id: $userRoundId) {
      id
      courseScoreCard {
        id
        holes {
          id
          holeNumber
          malePar
          malePlusStroke
          maleStroke
          femalePar
          femalePlusStroke
          femaleStroke
        }
      }
      dateCreated
      gameType {
        id
        scoringMethod {
          id
        }
      }
      userRoundHandicap {
        id
        playingHandicap
      }
      round {
        id
        course {
          id
          name
          club {
            id
            name
          }
        }
        settings {
          id
          determineWinnerBy
        }
      }
      user {
        id
        firstName
        gender {
          id
        }
        lastName
      }
      userScoreCards {
        id
        hole {
          id
          holeNumber
        }
        points
        score
      }
    }
  }
`;

/**
 * __useGetUserRoundByUserRoundIdQuery__
 *
 * To run a query within a React component, call `useGetUserRoundByUserRoundIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoundByUserRoundIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoundByUserRoundIdQuery({
 *   variables: {
 *      userRoundId: // value for 'userRoundId'
 *   },
 * });
 */
export function useGetUserRoundByUserRoundIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserRoundByUserRoundIdQuery,
    GetUserRoundByUserRoundIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserRoundByUserRoundIdQuery,
    GetUserRoundByUserRoundIdQueryVariables
  >(GetUserRoundByUserRoundIdDocument, options);
}
export function useGetUserRoundByUserRoundIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRoundByUserRoundIdQuery,
    GetUserRoundByUserRoundIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserRoundByUserRoundIdQuery,
    GetUserRoundByUserRoundIdQueryVariables
  >(GetUserRoundByUserRoundIdDocument, options);
}
export type GetUserRoundByUserRoundIdQueryHookResult = ReturnType<
  typeof useGetUserRoundByUserRoundIdQuery
>;
export type GetUserRoundByUserRoundIdLazyQueryHookResult = ReturnType<
  typeof useGetUserRoundByUserRoundIdLazyQuery
>;
export type GetUserRoundByUserRoundIdQueryResult = Apollo.QueryResult<
  GetUserRoundByUserRoundIdQuery,
  GetUserRoundByUserRoundIdQueryVariables
>;
export const GetUserRoundToPrintDocument = gql`
  query getUserRoundToPrint($userRoundId: ID!) {
    userRound(id: $userRoundId) {
      id
      handicap
      user {
        id
        firstName
        lastName
        gender {
          id
        }
      }
      userScoreCards {
        id
        hole {
          id
          holeNumber
        }
        points
        score
        totals {
          nettToPar
          nettToHandicap
        }
      }
      courseTee {
        id
        name
      }
      userRoundHandicap {
        id
        playingHandicap
      }
      dateCreated
      courseScoreCard {
        id
        holes {
          id
          holeNumber
          malePar
          malePlusStroke
          maleStroke
          femalePar
          femalePlusStroke
          femaleStroke
        }
      }
      round {
        id
        startTime
        settings {
          id
          handicapAllowance
          determineWinnerBy
        }
        gameType {
          id
          description
          scoringMethod {
            id
          }
        }
        course {
          id
          name
          club {
            id
            name
            logoUrl
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserRoundToPrintQuery__
 *
 * To run a query within a React component, call `useGetUserRoundToPrintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoundToPrintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoundToPrintQuery({
 *   variables: {
 *      userRoundId: // value for 'userRoundId'
 *   },
 * });
 */
export function useGetUserRoundToPrintQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserRoundToPrintQuery,
    GetUserRoundToPrintQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserRoundToPrintQuery,
    GetUserRoundToPrintQueryVariables
  >(GetUserRoundToPrintDocument, options);
}
export function useGetUserRoundToPrintLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRoundToPrintQuery,
    GetUserRoundToPrintQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserRoundToPrintQuery,
    GetUserRoundToPrintQueryVariables
  >(GetUserRoundToPrintDocument, options);
}
export type GetUserRoundToPrintQueryHookResult = ReturnType<
  typeof useGetUserRoundToPrintQuery
>;
export type GetUserRoundToPrintLazyQueryHookResult = ReturnType<
  typeof useGetUserRoundToPrintLazyQuery
>;
export type GetUserRoundToPrintQueryResult = Apollo.QueryResult<
  GetUserRoundToPrintQuery,
  GetUserRoundToPrintQueryVariables
>;
export const CreateOrUpdateUserRoundScoresDocument = gql`
  mutation CreateOrUpdateUserRoundScores(
    $userRoundId: ID!
    $scores: [CreateOrUpdateUserScoreCardInput!]!
  ) {
    createOrUpdateUserRoundScores(userRoundId: $userRoundId, scores: $scores) {
      id
      userScoreCards {
        id
        hole {
          id
          holeNumber
        }
        points
        score
      }
    }
  }
`;
export type CreateOrUpdateUserRoundScoresMutationFn = Apollo.MutationFunction<
  CreateOrUpdateUserRoundScoresMutation,
  CreateOrUpdateUserRoundScoresMutationVariables
>;

/**
 * __useCreateOrUpdateUserRoundScoresMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateUserRoundScoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateUserRoundScoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateUserRoundScoresMutation, { data, loading, error }] = useCreateOrUpdateUserRoundScoresMutation({
 *   variables: {
 *      userRoundId: // value for 'userRoundId'
 *      scores: // value for 'scores'
 *   },
 * });
 */
export function useCreateOrUpdateUserRoundScoresMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateUserRoundScoresMutation,
    CreateOrUpdateUserRoundScoresMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateUserRoundScoresMutation,
    CreateOrUpdateUserRoundScoresMutationVariables
  >(CreateOrUpdateUserRoundScoresDocument, options);
}
export type CreateOrUpdateUserRoundScoresMutationHookResult = ReturnType<
  typeof useCreateOrUpdateUserRoundScoresMutation
>;
export type CreateOrUpdateUserRoundScoresMutationResult =
  Apollo.MutationResult<CreateOrUpdateUserRoundScoresMutation>;
export type CreateOrUpdateUserRoundScoresMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrUpdateUserRoundScoresMutation,
    CreateOrUpdateUserRoundScoresMutationVariables
  >;
export const CreateOrUpdateMultipleUserRoundScoresDocument = gql`
  mutation CreateOrUpdateMultipleUserRoundScores(
    $inputs: [UpdateUserRoundScoresInput!]!
  ) {
    createOrUpdateMultipleUserRoundScores(inputs: $inputs) {
      id
      userScoreCards {
        id
        hole {
          id
          holeNumber
        }
        points
        score
      }
    }
  }
`;
export type CreateOrUpdateMultipleUserRoundScoresMutationFn =
  Apollo.MutationFunction<
    CreateOrUpdateMultipleUserRoundScoresMutation,
    CreateOrUpdateMultipleUserRoundScoresMutationVariables
  >;

/**
 * __useCreateOrUpdateMultipleUserRoundScoresMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateMultipleUserRoundScoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateMultipleUserRoundScoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateMultipleUserRoundScoresMutation, { data, loading, error }] = useCreateOrUpdateMultipleUserRoundScoresMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreateOrUpdateMultipleUserRoundScoresMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateMultipleUserRoundScoresMutation,
    CreateOrUpdateMultipleUserRoundScoresMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateMultipleUserRoundScoresMutation,
    CreateOrUpdateMultipleUserRoundScoresMutationVariables
  >(CreateOrUpdateMultipleUserRoundScoresDocument, options);
}
export type CreateOrUpdateMultipleUserRoundScoresMutationHookResult =
  ReturnType<typeof useCreateOrUpdateMultipleUserRoundScoresMutation>;
export type CreateOrUpdateMultipleUserRoundScoresMutationResult =
  Apollo.MutationResult<CreateOrUpdateMultipleUserRoundScoresMutation>;
export type CreateOrUpdateMultipleUserRoundScoresMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrUpdateMultipleUserRoundScoresMutation,
    CreateOrUpdateMultipleUserRoundScoresMutationVariables
  >;
export const UpdatePlayingHandicapsDocument = gql`
  mutation UpdatePlayingHandicaps($inputs: [UpdatePlayingHandicapInput!]!) {
    updatePlayingHandicaps(inputs: $inputs) {
      id
      userRoundHandicap {
        id
        playingHandicap
      }
    }
  }
`;
export type UpdatePlayingHandicapsMutationFn = Apollo.MutationFunction<
  UpdatePlayingHandicapsMutation,
  UpdatePlayingHandicapsMutationVariables
>;

/**
 * __useUpdatePlayingHandicapsMutation__
 *
 * To run a mutation, you first call `useUpdatePlayingHandicapsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayingHandicapsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayingHandicapsMutation, { data, loading, error }] = useUpdatePlayingHandicapsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdatePlayingHandicapsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlayingHandicapsMutation,
    UpdatePlayingHandicapsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlayingHandicapsMutation,
    UpdatePlayingHandicapsMutationVariables
  >(UpdatePlayingHandicapsDocument, options);
}
export type UpdatePlayingHandicapsMutationHookResult = ReturnType<
  typeof useUpdatePlayingHandicapsMutation
>;
export type UpdatePlayingHandicapsMutationResult =
  Apollo.MutationResult<UpdatePlayingHandicapsMutation>;
export type UpdatePlayingHandicapsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlayingHandicapsMutation,
  UpdatePlayingHandicapsMutationVariables
>;
export const EditPlayingHandicapDocument = gql`
  mutation EditPlayingHandicap($input: EditPlayingHandicapInput!) {
    editPlayingHandicap(input: $input) {
      id
      userRoundHandicap {
        id
        playingHandicap
      }
      userScoreCards {
        id
        hole {
          id
          holeNumber
        }
        points
        score
      }
    }
  }
`;
export type EditPlayingHandicapMutationFn = Apollo.MutationFunction<
  EditPlayingHandicapMutation,
  EditPlayingHandicapMutationVariables
>;

/**
 * __useEditPlayingHandicapMutation__
 *
 * To run a mutation, you first call `useEditPlayingHandicapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlayingHandicapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlayingHandicapMutation, { data, loading, error }] = useEditPlayingHandicapMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPlayingHandicapMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditPlayingHandicapMutation,
    EditPlayingHandicapMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditPlayingHandicapMutation,
    EditPlayingHandicapMutationVariables
  >(EditPlayingHandicapDocument, options);
}
export type EditPlayingHandicapMutationHookResult = ReturnType<
  typeof useEditPlayingHandicapMutation
>;
export type EditPlayingHandicapMutationResult =
  Apollo.MutationResult<EditPlayingHandicapMutation>;
export type EditPlayingHandicapMutationOptions = Apollo.BaseMutationOptions<
  EditPlayingHandicapMutation,
  EditPlayingHandicapMutationVariables
>;
